import React from "react";
import {Route, Switch} from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import {withStyles} from "@material-ui/core/styles";
import Header from "../components/Header";
import LeftDrawer from "../components/LeftDrawer";
import SearchResult from "../components/SearchResult";
import RightDrawer from "../components/RightDrawer";
import RightDrawerContact from "../components/RightDrawerContact";
import Data from "../data";
import Dashboard from "./DashboardPage";
import Medias from "./Medias/App";
import Form from "./FormPage";
import LoginPage_wx from "./LoginPage_wx";
import LoginPage2 from "./LoginPage2";
import LoginPage3 from "./LoginPage3";
import Account from "./Account2";
import Logout from "./Logout";
import APP from "./Qreader/App";
import PREAPP from "./Qreader/PreApp";
import PREACCOUNT from "./Qreader/PreAccount";
import BasicTable from "./Table/BasicTables";
import DataTable from "./Table/DataTables";
import NotFound from "./NotFoundPage";
import {ThemeProvider} from "@material-ui/core/styles";
import defaultTheme, {customTheme} from "../theme";
import store from "./Ideahub/store";
import PrivateRoute from "./Ideahub/components/common/PrivateRoute";
import Idea_hub from "./Ideahub/components/ideas/Ideas";
import Variants from "./Ideahub/components/flashCards/flashCards";
import {loadUser, getVersion} from "./Ideahub/actions/auth";
import Home from "./HomePage/HomePage";
import Alerts from "../pages/Ideahub/components/layout/Alerts";
import "./Ideahub/components/flashCards/flashCards.scss";
import BookList from "./BookList";
import IdeaList from "./IdeaList";
import IdeaProjectList from "./IdeaProjectList";
import {platform} from "../platform"
import {release_version} from "../platform"

const styles = () => ({
    container: {
        margin: "80px 20px 20px 15px",
        paddingLeft: defaultTheme.drawer.width,
        [defaultTheme.breakpoints.down("sm")]: {
            paddingLeft: 0
        }
        // width: `calc(100% - ${defaultTheme.drawer.width}px)`
    },
    containerFull: {
        paddingLeft: defaultTheme.drawer.miniWidth,
        [defaultTheme.breakpoints.down("sm")]: {
            paddingLeft: 0
        }
    },
    containerOrigin: {
        paddingLeft: 0,
    },
    settingBtn: {
        top: 80,
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "white",
        width: 48,
        right: 0,
        height: 48,
        opacity: 0.9,
        padding: 0,
        zIndex: 999,
        position: "fixed",
        minWidth: 48,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);
        // nav bar default open in desktop screen, and default closed in mobile screen
        this.state = {
            drawerFlag: 2,
            theme: defaultTheme,
            rightDrawerOpen: false,
            rightDrawerContactOpen: false,
            navDrawerOpen: false,
            // window && window.innerWidth && window.innerWidth >= defaultTheme.breakpoints.values.md
            //     ? true
            //     : false
        };

        this.handleChangeRightDrawer = this.handleChangeRightDrawer.bind(this);
        this.handleChangeRightDrawerContact = this.handleChangeRightDrawerContact.bind(this);
        this.handleChangeNavDrawer = this.handleChangeNavDrawer.bind(this);
        this.handleChangeTheme = this.handleChangeTheme.bind(this);
    }

    handleChangeNavDrawer() {
        this.setState({
            // navDrawerOpen: !this.state.navDrawerOpen,
            drawerFlag: this.state.drawerFlag + 1
        });

        if (this.state.drawerFlag % 3 === 0) {

            this.setState({
                navDrawerOpen: !this.state.navDrawerOpen,

            });


        } else if (this.state.drawerFlag % 3 === 1) {

            this.setState({
                navDrawerOpen: !this.state.navDrawerOpen,

            });

        }


    }

    handleChangeRightDrawerContact() {

        this.setState({
            rightDrawerContactOpen: !this.state.rightDrawerContactOpen

        });


    }

    handleChangeRightDrawer() {
        this.setState({
            rightDrawerOpen: !this.state.rightDrawerOpen

        });
    }

    handleChangeTheme(colorOption) {
        const theme = customTheme({
            palette: colorOption
        });
        this.setState({
            theme
        });
    }


    componentWillMount() {

        localStorage.setItem("version", release_version);
    }


    componentDidMount() {
        localStorage.setItem("searchTime", 0);

        try {
            window.remote.getCurrentWindow().setSize(1000, 1000);
        } catch (e) {

            console.log("555555555555555555", e);
        }

        store.dispatch(loadUser());
        store.dispatch(getVersion());
    }

    render() {

        const {classes} = this.props;
        const {navDrawerOpen, rightDrawerOpen, rightDrawerContactOpen, theme} = this.state;

        const left_drawer = (<LeftDrawer
            navDrawerOpen={navDrawerOpen}
            handleChangeNavDrawer={this.handleChangeNavDrawer}
            menus={Data.menus}
        />);

        let left_menue = (this.state.drawerFlag % 3 === 0) ? "" : left_drawer;

        let header = (<Header handleChangeNavDrawer={this.handleChangeNavDrawer}
                              navDrawerOpen={navDrawerOpen}
                              handleChangeRightDrawer={this.handleChangeRightDrawer}
                              rightDrawerOpen={rightDrawerOpen}
                              handleChangeRightDrawerContact={this.handleChangeRightDrawerContact}
                              rightDrawerContactOpen={rightDrawerContactOpen}
        />)


        return (
            <ThemeProvider theme={theme}>

                {platform === "menubar" ? null : header}
                {platform === "menubar" ? null : left_menue}


                <editor-fold desc="固定位置按钮">
                    {/*<ButtonBase*/}
                    {/*color="inherit"*/}
                    {/*classes={{root: classes.settingBtn}}*/}
                    {/*onClick={this.handleChangeRightDrawer}*/}
                    {/*>*/}
                    {/*<i className="fa fa-cog fa-3x"/>*/}
                    {/*</ButtonBase>*/}

                </editor-fold>


                <RightDrawer
                    rightDrawerOpen={rightDrawerOpen}
                    handleChangeRightDrawer={this.handleChangeRightDrawer}
                    handleChangeTheme={this.handleChangeTheme}
                />
                <RightDrawerContact
                    rightDrawerOpen={rightDrawerContactOpen}
                    handleChangeRightDrawer={this.handleChangeRightDrawerContact}
                />
                <div
                    className={classNames(classes.container, !navDrawerOpen && classes.containerFull, (this.state.drawerFlag % 3 === 0) && classes.containerOrigin)}>

                    <SearchResult/>

                    {/*<Alerts/>*/}

                    <Switch>


                        {/*<Route exact path="/" component={Dashboard}/>*/}
                        <Route exact path="/" component={PREAPP}/>
                        {/*<PrivateRoute exact path="/" component={platform === "menubar" ? IdeaList : APP}/>*/}
                        <Route path="/apps/dashboard" component={Dashboard}/>
                        <Route path="/apps/medias" component={Medias}/>
                        <Route path="/apps/form" component={Form}/>
                        <Route path="/login" component={LoginPage2}/>
                        <Route path="/wxlogin" component={LoginPage3}/>
                        <Route path="/apps/login" component={LoginPage_wx}/>
                        <Route path="/apps/preaccount" component={PREACCOUNT}/>
                        <PrivateRoute path="/apps/account" component={Account}/>
                        <PrivateRoute path="/apps/bookList" component={BookList}/>
                        <PrivateRoute path="/apps/ideaProjects" component={IdeaProjectList}/>
                        <Route path="/apps/logout" component={Logout}/>
                        <PrivateRoute path="/apps/idea_hub" component={Idea_hub}/>
                        <PrivateRoute path="/apps/ideas" component={IdeaList}/>
                        <Route path="/apps/flashCards" component={Variants}/>
                        <PrivateRoute path="/apps/" component={APP}/>
                        <PrivateRoute path="/apps/books" component={APP}/>
                        <Route path="/apps/table/basic" component={BasicTable}/>
                        <Route path="/apps/table/data" component={DataTable}/>
                        <Route component={NotFound}/>
                    </Switch>
                </div>

            </ThemeProvider>
        );
    }
}

App.propTypes = {
    children: PropTypes.element,
    classes: PropTypes.object
};

export default withStyles(styles)(App);
