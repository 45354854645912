import {Grid, MuiThemeProvider, Button} from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import React, {Component} from "react";
import ReactDOM from "react-dom";
import {connect} from "react-redux";
import MaterialTable from "material-table";
import {getProjects, updateProject} from "../../actions/projects";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {forwardRef} from "react";
import Switch from "@material-ui/core/Switch";
import InputBase from "@material-ui/core/InputBase";


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};


class IdeaProjectsInfo extends Component {


  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRename = this.handleRename.bind(this);

  }

  state = {
    username: "",
    password: "",
    rows: [],
    projects: {}

  };


  handleClick = (item) => {

    console.log("handleClick");

    item.delete_status = item.delete_status ? 0 : 1;
    this.props.updateProject(item.id, item);


  };


  handleChange = (newName) => e => {

    console.log("handleChange", newName);

    console.log(e.target.value);

  };


  handleRename = (item) => e => {

    let newName = e.target.value;

    console.log("handleChange");
    item.name = newName ? newName : item.name;
    this.props.updateProject(item.id, item);

  };


  componentDidMount() {

    this.props.getProjects();
  }


  componentDidUpdate() {


    if (this.state.projects !== this.props.projects) {

      const rows1 = this.props.projects ?

        this.props.projects.map(item => {

          console.log("6666666666");

          return {
            // name: item.name,
            name: <InputBase
              // className={classes.margin}
              defaultValue={item.name}
              inputProps={{"aria-label": "naked"}}
              // onChange={this.handleChange}
              onBlur={this.handleRename(item)}

            />,

            counts: item.currentCounts, notes: item.totalCounts, delete:

              <Switch
                checked={!item.delete_status}
                onClick={this.handleClick.bind(this, item)}
                value={item.id}
                color="primary"
                // inputProps={{"aria-label": "primary checkbox"}}
              />


          }
            ;


        }) : [];


      this.setState({

        projects: this.props.projects,
        rows: rows1


      });
    }

  }


  render() {


    const tableRef = React.createRef();

    return (
      <div style={{maxWidth: "100%"}}>
        <MaterialTable

          tableRef={tableRef}


          icons={tableIcons}

          options={{
            search: false,
            paginationType: "normal",
            pageSize: 5,
            tableLayout: "fixed"
            // showTitle:false
          }}

          columns={[
            {title: "闪念项目", field: "name", mawidth: 60},
            {title: "闪念数量", field: "counts", type: "numeric", miniWidth: 10},
            {title: "总数量", field: "notes", type: "numeric"},
            {
              title: "隐藏/显示",
              field: "delete",
              miniWidth: 20
            },
          ]}
          data={this.state.rows}
          title="闪念项目列表"
        />
      </div>
    );
  }
}


const mapStateToProps = state => ({
  auth: state.auth,
  projects: state.projects.projects
});

export default connect(
  mapStateToProps,
  {getProjects, updateProject}
)(IdeaProjectsInfo);
