import axios from "axios";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import React, {Component} from "react";
import {loadUser, updateUser, WechatPay} from "../pages/Ideahub/actions/auth";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import {grey} from "@material-ui/core/colors";
import QRCode from "qrcode.react";
import {deepOrange} from "@material-ui/core/colors";
import {createMuiTheme} from "@material-ui/core/styles";


import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import store from "../pages/Ideahub/store";


const theme = createMuiTheme({
  palette: {
    primary: deepOrange,
    secondary: {
      main: "#ff8a65",
    },
  },
});

const styles = theme => {
  return {


    root: {

      margin: "0 auto"

    },
    formControl: {
      margin: theme.spacing(3),
    },

    large: {
      width: 60,
      height: 60,
    },
    toggleDiv: {
      marginTop: 20,
      marginBottom: 5
    },
    toggleLabel: {
      color: grey[400],
      fontWeight: 100
    },
    button: {},
    buttons: {
      marginTop: 30,
      float: "right"
    },
    saveButton: {
      marginLeft: 15
    },
    typeOptions: {

      width: 180

    },
    bookName: {

      width: 500

    }

  };
};


export class Payment extends Component {


  state = {

    months: "12",
    goldPoints: "100",
    goldPoints2: "0"


  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired
  };

  pay(fee, goldPoints) {

    this.props.WechatPay(fee, "0", goldPoints, this.state.userToken);

    // store.dispatch(WechatPay(fee, "0", goldPoints, this.state.userToken));

  };


  handleClose() {
    this.props.loadUser();
    this.props.toggleOpen();
    alert("支付完成，请重新登录")
    window.location.href = "#/apps/logout";
  }


    handleClose1() {
        this.props.loadUser();
        this.props.toggleOpen();
    }


    handlePay = goldPoints => {

    this.setState({
      goldPoints: goldPoints

    });


    let fee = "1";

    if (goldPoints === "100") {

      fee = "5000";
    } else if (goldPoints === "5") {
      fee = "500";
    } else if (goldPoints === "1") {

      fee = "200";
    }

    // this.pay(fee, goldPoints);


  };


  handlePay2() {

    console.log("567890-goldPoints");


    let goldPoints = this.state.goldPoints;


    let fee = "1";

    if (goldPoints === "100") {

      fee = "5000";
    } else if (goldPoints === "5") {
      fee = "500";
    } else if (goldPoints === "1") {

      fee = "200";
    }

    this.pay(fee, goldPoints);


  };

  componentDidMount() {


    let userToken = localStorage.getItem("token");


    this.setState({

      userToken: userToken
    });


    window.This = this;


    if (this.props.auth.isAuthenticated) {


      localStorage.setItem("code_url", "666");


    }


  }


  componentDidUpdate(prevProps, prevState, snapshot) {


    if (this.state.goldPoints !== this.state.goldPoints2) {

      this.setState({

        goldPoints2: this.state.goldPoints

      });

      this.handlePay2();

    }

  }

  render() {
    let {classes} = this.props;

    const {isAuthenticated, pay_code_url} = this.props.auth;


    return (
      <div>
        <Dialog open={this.props.open} className={classes.root}>
          <DialogTitle>微信扫码支付</DialogTitle>
          <DialogContent>
            <QRCode
              value={pay_code_url}  //value参数为生成二维码的链接
              size={200} //二维码的宽高尺寸
              fgColor="#000000"  //二维码的颜色
            />
          </DialogContent>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup aria-label="gender" name="gender2"
                        value={this.state.goldPoints}
                        onChange={this.handlePay2.bind(this)}
            >

              <FormControlLabel

                onClick={this.handlePay.bind(this, "1")}

                value="1"
                control={<Radio color="primary"/>}
                label="2元/1个金豆"
                labelPlacement="6"
              />
              <FormControlLabel
                onClick={this.handlePay.bind(this, "5")}
                value="5"
                control={<Radio color="primary"/>}
                label="5元/5个金豆"
                labelPlacement="15"
              />
              <FormControlLabel
                onClick={this.handlePay.bind(this, "100")}
                value="100"
                control={<Radio color="primary"/>}
                label="50元/100个金豆"
                labelPlacement="49"
              />
            </RadioGroup>
          </FormControl>


          <DialogActions color="primary">
            <div className={classes.buttons}>
              <Button onClick={this.handleClose1.bind(this)} variant="contained">关闭</Button>
              <Button
                className={classes.saveButton}
                variant="contained"
                type="submit"
                color="primary"
                onClick={this.handleClose1.bind(this)}
              >
                支付完成
              </Button>

            </div>


          </DialogActions>
        </Dialog>
      </div>
    );
  }
}


Payment.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
  auth: state.auth,
});

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, {
  updateUser, WechatPay, loadUser
})(Payment));
