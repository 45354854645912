import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {login} from "../../actions/auth";


const styles = theme => {
  return {

    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },

  };
};



export class SignIn extends Component {


  state = {
    username: "",
    password: ""
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
  };


  Register() {

    alert("扫码关注微信公众号：闪电读书软件助手，获取账户密码");
  }



  onSubmit = e => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  };

  onChange = e => this.setState({[e.target.name]: e.target.value});


  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/"/>;
    }
    const {username, password} = this.state;
    let {classes} = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <form onSubmit={this.onSubmit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="用户名"
            name="username"
            autoComplete="username"
            onChange={this.onChange}
            value={username}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="密码"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.onChange}
            value={password}
          />
          {/*<FormControlLabel*/}
          {/*  control={<Checkbox value="remember" color="primary"/>}*/}
          {/*  label="记住"*/}
          {/*/>*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            确定
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="second"

            onClick={this.Register.bind(this) }
          >
            注册
          </Button>
        </form>
      </Container>
    );
  }
}


SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, {login})(SignIn));
