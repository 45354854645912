import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {LeftDrawer} from "../../../../components/LeftDrawer";


const drawStyles = theme => {
  return {

    large: {
      width: 60,
      height: 60,
    },

    drawerPaper: {
      width: theme.drawer.width,
      backgroundColor: "rgb(33, 33, 33)",
      color: "white",
      borderRight: "0px",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px"
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.drawer.miniWidth
    },
    logo: {
      cursor: "pointer",
      fontSize: 22,
      color: "white",
      lineHeight: "64px",
      fontWeight: 300,
      backgroundColor: theme.palette.primary[500],
      paddingLeft: 40,
      height: 64
    },
    logomini: {
      fontSize: 0,
    },
    avatarRootMini: {
      // padding: "15px 0 10px 7.6px"
      padding: "8px 0 0 0"
    },
    avatarIcon: {
      float: "left",
      display: "block",
      boxShadow: "0px 0px 0px 1px rgba(0,0,0,0.2)",
      width: "56px",
      height: "56px"
    },
    avatarSpan: {
      paddingTop: 16,
      paddingLeft: 24,
      display: "block",
      color: "white",
      fontWeight: 300,
      textShadow: "1px 1px #444"
    },
    menuItem: {
      color: "white",
      fontSize: 14
    }
  };
};

export class Info extends Component {
  state = {
    username: "",
    password: ""
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,

  };


  render() {

    const {isAuthenticated, user} = this.props.auth;
    let {classes, theme} = this.props;

    return (
      <div className="col-md-6 m-auto">
        微信昵称： {user.nickName}
        <br/>
        金豆数量：{user.goldPoints}
        <span style={{color: "red"}}>充值</span>
        <br/>
        会员期限：{user.memberExpireDate.substring(0, 10)}
        <br/>
        会员续期：
        <span>免费</span>
        <span>付费</span>
      </div>
    );
  }
}


Info.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
};

const mapStateToProps = state => ({
  auth: state.auth,
});


export default withStyles(drawStyles, {withTheme: true})(connect(mapStateToProps, {})(Info));
