import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import SearchResult from './SearchResult';
import SearchIcon from '@material-ui/icons/Search';


const EPUBJS = window.EPUBJS

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: 500,
    },
    title: {
        marginTop: 10,
    },
    list: {
        width: 280,
        padding: 0,
        listStyle: 'none',
        flexGrow: 2,
        overflowX: 'hidden',
    },
    foot: {
        display: 'flex',
        flexShrink: 0,
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        height: 40,
        padding: '0 10px',
    },
    searchInput: {

        width: "60%",
        height: 32,
        marginLeft: 30,
        marginTop: 1,
        // marginBottom: 10


    },
    searchIcon2: {

        // marginTop: 10,
        marginLeft: 5,
        opacity: .8,
        '&:hover': {
            opacity: 1,
        },

    },
    tem: {
        // backgroundColor: "yellow",
        // textAlign: "center"


        padding: "auto auto"
    }

});

class Search extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            keyword: "",
            searchResults: []

        }

        this.handleClose = this.handleClose.bind(this);
        this.handleJump = this.handleJump.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onEnter = this.onEnter.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }


    onChange = e => this.setState({[e.target.name]: e.target.value});

    onEnter = e => {

        if (e.keyCode === 13) {

            this.onSearch()

        }

    }

    onSearch = () => {


        if (this.state.keyword.length > 0) {

            this.search()
        }

        // if (this.state.keyword !== "") {
        //     this.setState({
        //         keyword: ""
        //     })
        // }
    }


    search = () => {

        window.This = this

        if (!this.state.keyword.length) {
            alert('请输入关键词')
            return
        }
        let book = this.props.epub
        let q = this.state.keyword
        return new Promise((resolve, reject) => {
            var resultPromises = []
            for (var i = 0; i < book.spine.length; i++) {
                var spineItem = book.spine[i]
                resultPromises.push(new Promise((resolve, reject) => {
                    new Promise(function (resolve, reject) {
                        resolve(new EPUBJS.Chapter(spineItem, book.store, book.credentials))
                    }).then(function (chapter) {
                        return new Promise(function (resolve, reject) {
                            chapter.load().then(function () {
                                resolve(chapter)
                            }).catch(reject)
                        })
                    }).then(function (chapter) {
                        return Promise.resolve(chapter.find(q))
                    }).then(function (result) {
                        resolve(result)
                    })
                }))
            }
            Promise.all(resultPromises).then((results) => {
                return new Promise((resolve, reject) => {
                    resolve(results)
                    var mergedResults = [].concat.apply([], results)

                    window.This.setState(
                        {
                            searchResults: []

                        }
                    )
                    window.This.setState(
                        {
                            searchResults: mergedResults

                        }
                    )

                })
            })
        })
    }


    handleClose() {
        this.props.toggleSearch(false);
    }

    handleJump(cfi) {
        this.props.epub.gotoCfi(cfi);
    }

    handleExport() {
        this.props.toggleExport(true);
    }

    render() {
        const {notes, colors, classes} = this.props;

        return (
            <div id="notes">
                <Drawer open={this.props.open} onClose={this.handleClose} anchor="right" className={classes.root}>
                    <div className={classes.title}>
                        <Typography type="headline" align="center" gutterBottom>Search</Typography>
                    </div>

                    <div className={classes.tem}>

                        <input className={classes.searchInput}
                               onBlur={this.onSearch}
                               name="keyword"
                               onChange={this.onChange}
                               onKeyUp={this.onEnter}
                               value={this.state.keyword}
                               placeholder="搜索内容"

                        />


                        <Button component="span" aria-label="sync"
                                color="primary"
                                className={classes.button}>
                            <SearchIcon className={classes.button3}/>
                        </Button>

                    </div>


                    <ul className={classes.list}>
                        {
                            this.state.searchResults.map((item, key) => {

                                return (
                                    <SearchResult key={key}
                                                  note={item}
                                                  colors={colors}
                                                  keyword={this.state.keyword}
                                                  handleJump={this.handleJump}
                                    />
                                );
                            })
                        }
                    </ul>
                    <Divider/>
                    {/*<div className={classes.foot}>*/}
                    {/*<Button onClick={this.handleExport} color="secondary">*/}
                    {/*export*/}
                    {/*</Button>*/}
                    {/*</div>*/}
                </Drawer>
            </div>
        );
    }
}

Search.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Search);
