export const GET_LEADS = "GET_LEADS";
export const DELETE_LEAD = "DELETE_LEAD";
export const ADD_LEAD = "ADD_LEAD";
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const USER_LOADING = "USER_LOADING";
export const WECHAT_PAYIING = "WECHAT_PAYIING";
export const USER_LOADED = "USER_LOADED";
export const PAY_DONE = "PAY_DONE";
export const PAY_ERROR = "PAY_ERROR";
export const GET_VERSION = "GET_VERSION";
export const VERSION_GOT = "VERSION_GOT";
export const VERSION_ERROR = "VERSION_ERROR";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const QR_SUCCESS = "QR_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const CLEAR_LEADS = "CLEAR_LEADS";
export const GET_IDEAS = "GET_IDEAS";
export const DELETE_IDEA = "DELETE_IDEA";
export const ADD_IDEA = "ADD_IDEA";
export const UPDATE_IDEA = "UPDATE_IDEA";
export const DONE_IDEA = "DONE_IDEA";
export const CLEAR_IDEAS = "CLEAR_IDEAS";
export const GET_PROJECTS = "GET_PROJECTS";
export const ACTIVATE_IDEA = "ACTIVATE_IDEA";
export const UPDATE_WORK_TYPE = "UPDATE_WORK_TYPE";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const GET_BOOKS = "GET_BOOKS";
export const UPLOAD_BOOK = "UPLOAD_BOOK";
export const DOWNLOAD_BOOK = "DOWNLOAD_BOOK";
export const UPDATE_BOOK = "UPDATE_BOOK";
export const UPDATE_LOCAL_BOOK = "UPDATE_LOCAL_BOOK";
export const UPDATE_BOOK_TYPES = "UPDATE_BOOK_TYPES";
export const POST_PROJECTS = "POST_PROJECTS";
export const UPLOAD_NOTE = "UPLOAD_NOTE";
export const DOWNLOAD_NOTE = "DOWNLOAD_NOTE";
export const DOWNLOAD_NOTE_FAIL = "DOWNLOAD_NOTE_FAIL";
export const UPDATE_NOTES_STATUS = "UPDATE_NOTES_STATUS";
export const POST_BOOK_TYPE = "POST_BOOK_TYPE";
export const GET_BOOK_TYPES = "GET_BOOK_TYPES";
export const UPLOAD_BOOK_STATUS = "UPLOAD_BOOK_STATUS";
export const UPDATE_PROJECTS = "UPDATE_PROJECTS";


export const GET_STS = "GET_STS";


export const RECLASSIFY_BOOK = "RECLASSIFY_BOOK";
