import PropTypes from "prop-types";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Avatar from "@material-ui/core/Avatar";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import React, {Component} from "react";
import Menus from "./Menus";
import {logout} from "../pages/Ideahub/actions/auth";


const drawStyles = theme => {
    return {

        large: {
            width: 60,
            height: 60,
        },

        drawerPaper: {
            width: theme.drawer.width,
            backgroundColor: "rgb(33, 33, 33)",
            color: "white",
            borderRight: "0px",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px"
        },
        drawerPaperClose: {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            width: theme.drawer.miniWidth
        },
        logo: {
            cursor: "pointer",
            fontSize: 22,
            color: "white",
            lineHeight: "64px",
            fontWeight: 300,
            backgroundColor: theme.palette.primary[500],
            paddingLeft: 40,
            height: 64
        },
        logomini: {
            fontSize: 0,
        },
        avatarRoot: {
            // padding: "16px 0 10px 15px",
            padding: "8px 0 10px 15px",
            backgroundImage: "url(" + require("../images/material_bg.png") + ")",
            height: 70,
            display: "flex",
            margin: 0
        },
        avatarRootMini: {
            // padding: "15px 0 10px 7.6px"
            padding: "8px 0 0 0"
        },
        avatarIcon: {
            float: "left",
            display: "block",
            boxShadow: "0px 0px 0px 1px rgba(0,0,0,0.2)",
            width: "56px",
            height: "56px"
        },
        avatarSpan: {
            paddingTop: 16,
            paddingLeft: 24,
            display: "block",
            color: "white",
            fontWeight: 300,
            textShadow: "1px 1px #444"
        },
        menuItem: {
            color: "white",
            fontSize: 14
        }
    };
};


export class LeftDrawer extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired
    };

    render() {
        const {isAuthenticated, user} = this.props.auth;

        let avatar_null = require('../images/guest.jpg')
        let avatar_apple = require('../images/apple.jpg')

        // console.log("user", user)

        let {navDrawerOpen, classes, theme, handleChangeNavDrawer} = this.props;


        const drawerContent = () => (
            <div>
                <div className={classNames(classes.logo, !navDrawerOpen && classes.logomini)}>阅读 & 思考</div>
                <div className={classNames(classes.avatarRoot, !navDrawerOpen && classes.avatarRootMini)}>
                    <Avatar src={isAuthenticated ? `${user.avatar ? user.avatar : avatar_apple}` : avatar_null}
                            size={navDrawerOpen ? 48 : 32}
                            classes={{root: classes.avatarIcon}}/>

                    <span className={classes.avatarSpan}>{isAuthenticated ? `${user.nickName}` :
                        "未登录"}</span>

                </div>
                <Menus menus={this.props.menus} navDrawerOpen={navDrawerOpen}/>
            </div>
        );

        return (
            <div>
                {/* Mobile screen */}
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={navDrawerOpen}
                        onClose={handleChangeNavDrawer}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {/* should close drawer modal as well when click on menu */}
                        {drawerContent(handleChangeNavDrawer)}
                    </Drawer>
                </Hidden>

                {/* Desktop screen */}
                <Hidden smDown>
                    <Drawer
                        open={navDrawerOpen}
                        variant="permanent"
                        classes={{
                            paper: classNames(classes.drawerPaper, !navDrawerOpen && classes.drawerPaperClose)
                        }}
                    >
                        {drawerContent()}
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}


LeftDrawer.propTypes = {
    navDrawerOpen: PropTypes.bool,
    menus: PropTypes.array,
    username: PropTypes.string,
    classes: PropTypes.object,
    theme: PropTypes.object,
    handleChangeNavDrawer: PropTypes.func
};


const mapStateToProps = state => ({
    auth: state.auth,
});


// export default withStyles(drawStyles, {withTheme: true})(LeftDrawer);
export default withStyles(drawStyles, {withTheme: true})(connect(mapStateToProps, {logout})(LeftDrawer));
