var I64BIT_TABLE =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-".split("");


function hash(input) {
  var hash = 5381;
  var i = input.length - 1;

  if (typeof input == "string") {
    for (; i > -1; i--)
      hash += (hash << 5) + input.charCodeAt(i);
  } else {
    for (; i > -1; i--)
      hash += (hash << 5) + input[i];
  }
  var value = hash & 0x7FFFFFFF;

  var retValue = "";
  do {
    retValue += I64BIT_TABLE[value & 0x3F];
  }
  while (value >>= 6);

  return retValue;
}


class Media {

  constructor(name, type, folder,
              isDelete = 0,
              viewTimes = 0,
              diggTimes = 0,
              completeCounts = 0,
              tags = "",
              remark = "",
              createDate = new Date()
  ) {

    this.id = 0;

    this.key = hash(name);
    // this.key = new Date().getTime() + ""; // 数据库的键
    this.name = name; // 文件名
    this.type = type; // 类型
    this.folder = folder;
    this.isDelete = isDelete; // 是否删除
    this.viewTimes = viewTimes; // 浏览次数
    this.diggTimes = diggTimes; // 点赞次数
    this.completeCounts = completeCounts; // 完播次数
    this.tags = tags; // 标签
    this.remark = remark; // 备注
    this.createDate = createDate; // 文件夹
  }
}

export default Media;
