import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {appleAuthHelpers} from 'react-apple-signin-auth';


export class Login_wx extends Component {
    state = {
        openId: "",
    };

    static propTypes = {
        login_wx: PropTypes.func.isRequired,
        qr_wx: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
        qr_url: PropTypes.object.isRequired
    };


    singInApple = () => {

        /**
         * perform apple signIn operation
         */
        appleAuthHelpers.signIn({
            authOptions: {
                /** Client ID - eg: 'com.example.com' */
                clientId: 'com.newonet.signin',
                /** Requested scopes, seperated by spaces - eg: 'email name' */
                scope: 'email name',
                /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                redirectURI: 'https://api.newonet.com/api/auth/apple',
                /** State string that is returned with the apple response */
                state: 'state',
                /** Nonce */
                nonce: 'nonce',
                /** Uses popup auth instead of redirection */
                usePopup: false,
            },
            onSuccess: (response) => console.log(response),
            onError: (error) => console.error(error),
        });


    };

    SignIn() {
        window.open("https://appleid.apple.com/auth/authorize?client_id=com.newonet.siwa&redirect_uri=https://api.newonet.com/api/auth/apple&response_type=code id_token&state=openId-67896789&scope=email name&123=nonce&response_mode=form_post")
    }


    componentWillUnmount() {

    }


    componentDidMount() {


    }


    render() {


        return (

            <div>
                <button

                    onClick={this.SignIn.bind(this)}

                >Sign in with apple
                </button>

            </div>


        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    qr_url: state.auth.qr_url
});

export default connect(
    mapStateToProps,
    {}
)(Login_wx);
