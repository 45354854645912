export default {
  columns: {
    id: {
      width: "10%"
    },
    name: {
      width: "40%"
    },
    price: {
      width: "20%"
    },
    category: {
      width: "20%"
    },
    edit: {
      width: "10%"
    }
  }
};
