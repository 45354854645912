class Folder {

  constructor(name, author, description, content, cover, sortWay = '') {

    this.id = 0;
    this.key = name;
    this.name = name; // 文件夹名
    this.author = author; // 作者
    this.description = description; // 书籍的描述
    this.content = content; // 代表内容的二进制数据
    this.cover = cover; //封面
    this.sortWay = "v"
  }
}

export default Folder;
