import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import './home.css'

const styles = theme => ({


    root: {

        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column"

    },


});


export class Home extends React.Component {

    state = {
        work_type: "classify",
    }

    constructor(props) {

        super(props);

        this.test = function () {
            console.log("clicked")
        }
    }


    static propTypes = {};


    render() {

        const {classes} = this.props;

        return (<Fragment>


                <div className="home">

                    {/*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}

                    <div className="background loaded"></div>
                    <h1 className="homeH1">闪电读书<br/></h1>
                    <span className="sub_mark">(内测期间，免费下载)</span>
                    <h1 className="homeH11">阅读 · 思考 · 行动</h1>

                    <hr className="hrStyle"/>
                    <p className="description">
                        优雅的阅读体验<br/>
                        高效的思考工具<br/>
                        科学的行动方法<br/>
                    </p>
                    <p className="login">
                        <a href="/#/apps/login/" target="_self">即刻开启思想之旅...</a>
                    </p>


                </div>

                <div className="beianDiv"><a id="beian" target="_blank"
                                             href="http://www.beian.miit.gov.cn/">皖ICP备18009967号</a>
                </div>


            </Fragment>

        )
    }


}


Home.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Home);
