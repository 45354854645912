import React from "react";
import Navigation from "./Navigation";
import ViewArea from "./ViewArea";
import Toolbar from "./Toolbar";
import SettingsDialog from "./SettingsDialog";
import KeyBoardDialog from "./KeyBoardDialog";
import Bookmarks from "./Bookmarks";
import DBUtil from "../service/DBUtil";
import Notes from "./Notes";
import Search from "./Search";
import Config from "../service/Config";
import Message from "./Message";
import ExportPanel from "./ExportPanel";


function sortviewTimesA(a, b) {
  return parseInt(a.viewTimes) - parseInt(b.viewTimes);
}

function sortviewTimesB(a, b) {
  return parseInt(b.viewTimes) - parseInt(a.viewTimes);
}

function sortCompleteCountsA(a, b) {
  return -parseInt(a.completeCounts) + parseInt(b.completeCounts);
}

function sortCompleteCountsB(a, b) {
  return parseInt(a.completeCounts) - parseInt(b.completeCounts);
}

function sortdiggTimesA(a, b) {
  return -parseInt(a.diggTimes) + parseInt(b.diggTimes);
}


function sortdiggTimesB(a, b) {
  return parseInt(a.diggTimes) - parseInt(b.diggTimes);
}


class Viewer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sortWay: "",
      sortviewTimes: -1,
      openSettings: false, // 打开设置面板
      openBookmarks: false, // 打开书签列表
      openKeyBoard: false, // 打开键盘快捷键提示
      openNotes: false, // 打开笔记列表
      openSearch: false, // 打开搜索框
      openMessage: false, // 打开消息通知
      openExport: false, // 打开note导出面板
      bookmarks: [], // 书签列表
      notes: [], // note列表
      colors: Config.get().colors, // note的四种颜色
      background: Config.get().background, // 阅读区域的背景色
      gutter: Config.get().gutter, // 阅读区域两侧预留的间隔
      padding: Config.get().padding, // 阅读区域上下两侧预留的间隔
      fontSize: Config.get().fontSize, // 字体大小
      lineHeight: Config.get().lineHeight, // 行高
      letterSpacing: Config.get().letterSpacing, // 字间距
      wordSpacing: Config.get().wordSpacing, // 词间距（限英语）
      column: Config.get().column, // 列数
      disablePopup: Config.get().disablePopup, // 禁用弹出菜单

      mdeia_length: 1,
      current_index: 0,
      current_speed: 1,
      current_status: 1,  // 播放
      medias: [],
      // medias: [{name: "/666.mp4"}, {name: "/666.mp4"}, {name: "/666.mp4"}],
      gap_time: 2,


    };
    this.epub = null;
    this.toggleSettingsDialog = this.toggleSettingsDialog.bind(this);
    this.toggleKeyBoardDialog = this.toggleKeyBoardDialog.bind(this);
    this.toggleBookmarks = this.toggleBookmarks.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.toggleNotes = this.toggleNotes.bind(this);
    this.toggleMessage = this.toggleMessage.bind(this);
    this.toggleExport = this.toggleExport.bind(this);
    this.setConfig = this.setConfig.bind(this);
    this.goMedia = this.goMedia.bind(this);
    this.goMedia2 = this.goMedia2.bind(this);
    this.sortMedias = this.sortMedias.bind(this);
    this.prev_media = this.prev_media.bind(this);
    this.next_media = this.next_media.bind(this);
    this.toggleControl = this.toggleControl.bind(this);
    this.speedDown = this.speedDown.bind(this);
    this.nextSeconds = this.nextSeconds.bind(this);
    this.prevSeconds = this.prevSeconds.bind(this);
    this.diggUp = this.diggUp.bind(this);
  }


  // 为state的属性设置相应的值
  setConfig(key, value) {
    console.log("setConfig");
    this.setState({[key]: value});
    Config.set(key, value);
  }


  toggleSettingsDialog(open) {
    this.setState({openSettings: open});
  }


  toggleKeyBoardDialog(open) {
    this.setState({openKeyBoard: open});

  }


  toggleBookmarks(open) {
    this.setState({openBookmarks: open});
  }


  toggleSearch(open) {
    this.setState({openSearch: open});


  }


  toggleNotes(open) {
    this.setState({openNotes: open});
  }


  toggleMessage(open) {
    this.setState({openMessage: open});
  }


  toggleExport(open) {
    this.setState({openExport: open});
  }


  nextSeconds(){
    console.log("快进.........");
    let videoElement = document.getElementById("video");
    let time = 2; //单位秒，每次增减
    videoElement.currentTime += window.This.state.gap_time;
    console.log("videoElement.currentTime", videoElement.currentTime);
  }


  prevSeconds(){
    console.log("快退.....");
    let videoElement = document.getElementById("video");
    let time = 2; //单位秒，每次增减10秒
    videoElement.currentTime -= window.This.state.gap_time;
  }


  speedDown(){

    console.log("速度---");
    if (window.This.state.current_speed > 0.2) {

      window.This.setState({
        current_speed: window.This.state.current_speed - 0.1
      });
      document.getElementById("video").playbackRate = window.This.state.current_speed;

    }

  }


  speedUp(){

    console.log("速度+++");
    if (window.This.state.current_speed < 5) {

      window.This.setState({
        current_speed: window.This.state.current_speed + 0.1
      });
      document.getElementById("video").playbackRate = window.This.state.current_speed;

    }

  }


  diggUp(){
    console.log("点赞了.........");
    let media = window.This.state.medias[window.This.state.current_index];
    media.diggTimes = media.diggTimes + 1;
    window.This.updateMedia(media);
    window.This.setState({
      current_status: window.This.state.current_status
    });

  }


  // 为view界面绑定事件
  bindEvent() {

    window.This = this;
    let arrowKeys = event => {
      event.preventDefault();

      console.log("key code...................", event.keyCode);

      if (event.ctrlKey) {

        console.log("ctrllllll");
      }


      //1
      if (event.keyCode === 49) {
        window.This.setState({
          gap_time: 1
        });

      } else if (event.keyCode === 50) {
        window.This.setState({
          gap_time: 2
        });

      } else if (event.keyCode === 51) {
        window.This.setState({
          gap_time: 3
        });

      } else if (event.keyCode === 52) {
        window.This.setState({
          gap_time: 4
        });

      } else if (event.keyCode === 53) {
        window.This.setState({
          gap_time: 5
        });

      } else if (event.keyCode === 54) {
        window.This.setState({
          gap_time: 6
        });

      } else if (event.keyCode === 55) {
        window.This.setState({
          gap_time: 7
        });

      } else if (event.keyCode === 56) {
        window.This.setState({
          gap_time: 8
        });

      } else if (event.keyCode === 57) {
        window.This.setState({
          gap_time: 9
        });

      } else if (event.keyCode === 48) {
        window.This.setState({
          gap_time: 0.5
        });

      }


      // 点赞

      if (event.keyCode === 32 || event.keyCode === 13) {

        window.This.diggUp()

      }


      // 点踩

      if (event.keyCode === 8 || event.keyCode === 8) {
        console.log("点踩了.........");

        let media = window.This.state.medias[window.This.state.current_index];
        media.diggTimes = media.diggTimes - 1;
        window.This.updateMedia(media);

        window.This.setState({
          current_status: window.This.state.current_status
        });

      }


      //右箭头
      if (event.keyCode === 39 || event.keyCode === 102) {

        window.This.nextSeconds()


      }


      //左箭头
      if (event.keyCode === 37 || event.keyCode === 100) {

        window.This.prevSeconds()

      }


      //  上
      if (event.keyCode === 38 || event.keyCode === 73 || event.keyCode === 104) {
        console.log("播放/暂停");
        window.This.setState({
          current_status: !window.This.state.current_status
        });


        if (document.getElementById("video").paused) {

          document.getElementById("video").play();
        } else {

          document.getElementById("video").pause();

        }


      }


      //+ 187
      if (event.keyCode === 187 || event.keyCode === 107) {
        window.This.speedUp()
      }



      // -
      if (event.keyCode === 189 || event.keyCode === 109) {
        window.This.speedDown()
      }


      //下一个
      if (event.keyCode === 221 || event.keyCode === 190 || event.keyCode === 34 || event.keyCode === 105) {
        console.log("下一个");
        console.log(this.state.medias);
        window.This.next_media();


      }


      // 控制控件
      if (event.keyCode === 40 || event.keyCode === 40 || event.keyCode === 40) {
        window.This.toggleControl()
      }


      //上一个
      if (event.keyCode === 219 || event.keyCode === 188 || event.keyCode === 191 || event.keyCode === 33 || event.keyCode === 103) {
        console.log("上一个");
        window.This.prev_media();


      }
    };


    let video_area = document.getElementById("videoMask");

    video_area.addEventListener("click", function () {
      document.getElementById("text666").focus();
      console.log("聚焦成功");
      console.log("播放/暂停");
      window.This.setState({
        current_status: !window.This.state.current_status
      });


      if (document.getElementById("video").paused) {

        document.getElementById("video").play();
      } else {

        document.getElementById("video").pause();

      }
    });


    let doc = document.getElementById("text666");


    doc.addEventListener("keydown", arrowKeys); // 箭头按键翻页


    doc.addEventListener("click", function () {

      console.log("聚焦成功");

    });


  }


  autoSize() {


    window.This = this;
    let height = document.getElementById("video").style.height;
    let width = document.getElementById("video").style.width;


    let w_h = width / height;

    if (w_h > 1) {

      console.log("window.innerWidth", window.innerWidth);


      document.getElementById("video_div").style.width = window.innerWidth * 0.8;
      document.getElementById("video_div").style.height = window.innerWidth * 0.8 / w_h;
    } else {

      console.log("window.innerWidth", window.innerWidth);

      document.getElementById("video_div").style.height = window.innerHeight;
      document.getElementById("video_div").style.width = window.innerHeight * w_h;


    }


  }


  autoPlay() {


    window.This = this;


    let video = document.getElementById("video");
    video.addEventListener("ended", function ({}) {

      let media = window.This.state.medias[window.This.state.current_index];
      media.completeCounts = media.completeCounts + 1;

      console.log("pre_media", media);
      window.This.updateMedia(media);


      window.This.next_media();


    });

    video.addEventListener("play", function ({}) {
      document.getElementById("video").playbackRate = window.This.state.current_speed;
      console.log("window.This.state.current_speed;", window.This.state.current_speed);


    });


  }


  toggleControl(){
    let videoControls = document.getElementById("video");
    console.log("videoControls.controls", videoControls.controls);

    if (videoControls.controls) {
      videoControls.controls = false;
    } else {
      videoControls.controls = true;
    }
  }


  next_media() {

    window.This = this;
    if (window.This.state.current_index < window.This.state.medias.length - 1) {
      window.This.setState({
        current_index: window.This.state.current_index + 1
      });
    }
    let folder = window.This.props.readingBook;
    folder.description = window.This.state.current_index;
    window.This.updateProgress(folder);
    let media = window.This.state.medias[window.This.state.current_index - 1];
    media.viewTimes = media.viewTimes + 1;
    console.log("next_media", media);
    window.This.updateMedia(media);
    document.getElementById("video").playbackRate = window.This.state.current_speed;
    document.getElementById("text666").focus();
    console.log("聚焦成功");


  }


  prev_media() {

    window.This = this;
    if (this.state.current_index > 0) {

      window.This.setState({


        current_index: window.This.state.current_index - 1
      });

    }
    let folder = window.This.props.readingBook;
    let media = window.This.state.medias[window.This.state.current_index + 1];
    media.viewTimes = media.viewTimes + 1;
    window.This.updateMedia(media);
    console.log("prev_media", media);
    folder.description = window.This.state.current_index;
    window.This.updateProgress(folder);
    document.getElementById("video").playbackRate = window.This.state.current_speed;
    document.getElementById("text666").focus();
    console.log("聚焦成功");

  }


  // 更新浏览进度
  updateProgress(folder) {
    let dbAccess = new DBUtil("folders", "folder");
    dbAccess.open(
      () => {
        dbAccess.update(folder);
      }
    );
  }

  // 更新浏览数据
  updateMedia(media) {
    let dbAccess = new DBUtil("medias", "media");
    dbAccess.open(
      () => {
        dbAccess.update(media);
      }
    );
  }

  // 排序
  sortMedias(type, code) {


    let folder = this.props.readingBook;
    folder.sortWay = type;
    this.updateProgress(folder);


    if (type === "v") {
      if (code) {
        let new_medias = this.state.medias.sort(sortviewTimesB);
        this.setState({

          medias: new_medias
        });
      } else {
        let new_medias = this.state.medias.sort(sortviewTimesA);
        this.setState({

          medias: new_medias
        });
      }
    } else if (type === "c") {

      if (code) {
        let new_medias = this.state.medias.sort(sortCompleteCountsA);
        this.setState({

          medias: new_medias
        });
      } else {
        let new_medias = this.state.medias.sort(sortCompleteCountsB);
        this.setState({

          medias: new_medias
        });
      }

    } else if (type === "d") {

      if (code) {
        let new_medias = this.state.medias.sort(sortdiggTimesA);
        this.setState({

          medias: new_medias
        });
      } else {
        let new_medias = this.state.medias.sort(sortdiggTimesB);
        this.setState({

          medias: new_medias
        });
      }

    }


  }


  goMedia(current_media) {
    this.setState({current_media});
    let current_index = this.state.medias.map(item => item.name).indexOf(current_media);
    this.setState({
      current_index: current_index
    });

  }


  goMedia2(current_index) {

    console.log("current_index", current_index);

    this.setState({

      current_index: current_index


    });


  }


  componentWillMount() {


    this.setState({

      current_index: this.props.readingBook.description


    });


    window.This = this;

    async function db() {


      return new Promise(resolve => {


        setTimeout(() => {


          let dbAccess = new DBUtil("medias", "media");
          dbAccess.open(
            () => {
              dbAccess.getAll(result => {
                var bookmarksArr;
                bookmarksArr = result.filter(item => item.folder === window.This.props.readingBook.key);

                let medias = bookmarksArr.filter(item => {


                  if (item.name.endsWith("mp4") || item.name.endsWith("MP4")) {

                    return item;

                  }


                });


                window.This.setState({medias: medias});

                console.log("bookmarksArr", medias);

              });
            }
          );
          resolve(1);

        }, 100);

      });


    }


    db().then(() => {
    });


  }


  componentDidMount() {

    this.sortMedias(this.props.readingBook.sortWay, 0)

    window.rangy.init(); // 初始化

    document.getElementById("text666").focus();


    this.bindEvent();
    this.autoPlay();
    // this.autoSize();

  }


  render() {
    let epub = this.epub;

    let {readingBook} = this.props;
    let {
      openSettings,
      openKeyBoard,
      openBookmarks,
      openNotes,
      openSearch,
      openMessage,
      openExport,
      bookmarks,
      notes,
      colors,
      background,
      gutter,
      padding,
      fontSize,
      lineHeight,
      letterSpacing,
      wordSpacing,
      column,
      disablePopup
    } = this.state;

    return (
      <div id="viewer">
        <Navigation
          theme={this.props.theme}
          book={this.props.readingBook}
          goMedia={this.goMedia}
        />
        <ViewArea
          book={this.props.readingBook}
          theme={this.props.theme}
          gap_time={this.state.gap_time}
          current_speed={this.state.current_speed}
          medias={this.state.medias}
          current_index={this.state.current_index}
          goMedia2={this.goMedia2}
          sortMedias={this.sortMedias}
          prev_media = {this.prev_media}
          next_media = {this.next_media}
          toggleControl = {this.toggleControl}
          speedDown = {this.speedDown}
          speedUp = {this.speedUp}
          nextSeconds = {this.nextSeconds}
          prevSeconds = {this.prevSeconds}
          diggUp = {this.diggUp}

        />
        <Toolbar handleReading={this.props.handleReading}
                 toggleMessage={this.toggleMessage}
                 toggleSettingsDialog={this.toggleSettingsDialog}
                 toggleKeyBoardDialog={this.toggleKeyBoardDialog}
                 toggleBookmarks={this.toggleBookmarks}
                 toggleNotes={this.toggleNotes}
                 toggleSearch={this.toggleSearch}
                 handleAddBookmark={this.addBookmark}
                 readingBook={this.props.readingBook}
                 epub={epub}
        />
        <Bookmarks open={openBookmarks}
                   toggleBookmarks={this.toggleBookmarks}
                   bookmarks={bookmarks}
                   deleteBookmark={this.deleteBookmark}
                   updateBookmark={this.updateBookmark}
                   getBookmark={this.getBookmark}
                   epub={epub}
        />
        <Notes open={openNotes}
               toggleNotes={this.toggleNotes}
               toggleExport={this.toggleExport}
               notes={notes}
               colors={colors}
               deleteNote={this.deleteNote} //logicalDeleteNote deleteNote  注意逻辑删除的参数是Note，实际删除的参数是key
               updateNote={this.updateNote}
               epub={epub}
        />
        <Search open={openSearch}
                toggleSearch={this.toggleSearch}
                toggleExport={this.toggleExport}
                notes={notes}
                colors={colors}
                deleteNote={this.logicalDeleteNote} //logicalDeleteNote deleteNote  注意逻辑删除的参数是Note，实际删除的参数是key
                updateNote={this.updateNote}
                epub={epub}
        />
        <SettingsDialog open={openSettings}
                        toggleSettingsDialog={this.toggleSettingsDialog}
                        toggleTheme={this.props.toggleTheme}
                        setConfig={this.setConfig}
                        colors={colors}
                        background={background}
                        gutter={gutter}
                        padding={padding}
                        fontSize={fontSize}
                        lineHeight={lineHeight}
                        letterSpacing={letterSpacing}
                        wordSpacing={wordSpacing}
                        column={column}
                        disablePopup={disablePopup}
        />
        <KeyBoardDialog open={openKeyBoard}
                        toggleKeyBoardDialog={this.toggleKeyBoardDialog}
                        toggleTheme={this.props.toggleTheme}
                        setConfig={this.setConfig}
                        colors={colors}
                        background={background}
                        gutter={gutter}
                        padding={padding}
                        fontSize={fontSize}
                        lineHeight={lineHeight}
                        letterSpacing={letterSpacing}
                        wordSpacing={wordSpacing}
                        column={column}
                        disablePopup={disablePopup}
        />
        <Message open={openMessage} toggleMessage={this.toggleMessage}/>
        <ExportPanel open={openExport}
                     book={readingBook}
                     notes={notes}
                     theme={this.props.theme}
                     toggleExport={this.toggleExport}
        />
      </div>
    );
  }
}

export default Viewer;
