import axios from "axios";
import {returnErrors} from "./messages";
import {tokenConfig, tokenConfig_file} from "./auth";
import {ip} from "./env"

import {
    GET_BOOKS
} from "./types";


// GET PROJECTS


export const getbooks = (book_name = "", pn = 1, ps = 10) => (dispatch, getState) => {
    axios
        .get(ip + "/api/raw_books/?book_name=" + `${book_name}`, tokenConfig(getState))
        .then(res => {

            res = res.data[0].results
            res = JSON.parse(res)
            // console.log("-----------------")
            // console.log(res)

            dispatch({
                type: GET_BOOKS,
                payload: [res.results, book_name, pn, res.total]
            });
        })
        .catch(err =>

            dispatch(returnErrors(err.response, err.response))
        );
};

