import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import LeftArrow from "@material-ui/icons/NavigateBefore";
import RightArrow from "@material-ui/icons/NavigateNext";
import EditorToolbar from "./EditorToolbar";
import NoteCard from "./NoteCard";
import Util from "../service/Util";
import AutoBookmark from "../service/AutoBookmark";
import Note from "../model/Note";
import Config from "../service/Config";
import "./ViewArea.css";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PauseIcon from "@material-ui/icons/Pause";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import {returnErrors} from "../../Ideahub/actions/messages";
import MarkDialog from "./MarkDialog";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";


var toReadList = [];

var toMarkList = [];

var toReadListofChapter = [];

function newInset(Inset, width) {
    let bb = Inset.split(" ")
    if (bb.length === 2) {
        let right = bb[1]
        let left = bb[1]
        right = parseInt(right) / 2 + width
        left = parseInt(left) / 2
        left = left + "px"
        right = right + "px"
        return `${bb[0]} ${right} ${bb[0]} ${left}`
    } else {

        let right = bb[1]
        right = (parseInt(right) - width) * 2
        right = right + "px"
        return `${bb[0]} ${right}`


    }


}

function lister2(node, toReadList, content_len) {

    if (node.childElementCount) {
        // 若有子节点

        let sentenceLength = 0;

        try {
            sentenceLength = node.innerText.replace(/\s*/g, "").length;
        } catch (e) {
        }

        let all = ["b", "h1", "h2", "h3", "h4", "h5", "li"];

        let nodeName = node.nodeName.toLowerCase();


        if (sentenceLength < content_len && sentenceLength > 1) {
            if (node.tagName !== "SCRIPT") {

                if (node.innerText.search("ePUBw.COM") === -1) {
                    toReadList.push(node);

                }
            }

        } else if (all.indexOf(nodeName) !== -1) {

            if (node.innerText.search("ePUBw.COM") === -1) {
                toReadList.push(node);

            }
        } else {
            node.childNodes.forEach((node) => {
                lister2(node, toReadList, content_len);
            });
        }


    } else {
        // 若没有子节点
        if (node.tagName !== "SCRIPT") {

            if (node.nodeType && node.innerText) {

                if (node.innerText.search("ePUBw.COM") === -1) {
                    toReadList.push(node);

                }


            } else {


                if (node.data) {
                    toReadList.push(node);
                }


            }


        }
    }


}

function lister(node, toReadList) {

    let all = ["b", "h1", "h2", "h3", "h4", "h5", "li", "a", "p", "span"];
    let nodeName = node.nodeName.toLowerCase();

    if (all.indexOf(nodeName) !== -1) {
        if (node.innerText.search("ePUBw.COM") === -1) {
            toReadList.push(node);
        }
    } else {
        try {
            node.childNodes.forEach((node) => {
                lister(node, toReadList);
            });
        } catch (e) {

            console.log("6789e", e)
        }
    }
}


function new_list(list) {

    // 长节点分割

    let new_list0 = list.map(item => {
        if (item.nodeType == 3) {
            try {
                if (item.data.replace(/\s*/g, "").length > 1) {

                    let node_str = item.data.replace(/\s*/g, "")


                    return item.data.split(/。|[.]|:|：/)
                }
            } catch (e) {
            }

        } else {

            try {
                if (item.innerText.replace(/\s*/g, "").length > 1) {
                    let node_str = item.innerText.replace(/\s*/g, "")
                    return item.innerText.split(/。|[.]|:|：/)
                }
            } catch (e) {
            }
        }
    });

    let new_list1 = new_list0.flat(Infinity)

    let new_list = new_list1.filter(item => item)


    return new_list


}


function new_node_list(list) {


    let new_chapter_list0 = list.map(item => {
        if (item.nodeType == 3) {
            try {
                if (item.data.replace(/\s*/g, "").length > 1) {


                    return item;
                }
            } catch (e) {
            }

        } else {

            try {
                if (item.innerText.replace(/\s*/g, "").length > 1) {

                    return item;
                }
            } catch (e) {
            }
        }
    });

    let new_chapter_list = new_chapter_list0.filter(item => item);

    return new_chapter_list


}


function new_chapter_list_func(node_list) {


    return node_list.map(item => {
        let res = item.nodeType == 3 ? item.data.replace(/\s*/g, "") : item.innerText.replace(/\s*/g, "")
        return res

    })


}


function get_sentence_index2(node, sentence) {


    let toReadList = []
    let content_len = 2
    let node_index = 22

    lister2(node, toReadList, content_len)

    toReadList.map((item, index) => {


            let content0 = item.nodeType === 3 ? item.data : item.innerText
            let content = content0.replace(/\s*/g, "")
            if (content.indexOf(sentence) !== -1) {

                if (node_index === 22) {
                    node_index = index
                    console.log("node_index", node_index)
                }


            }


        }
    )

    return node_index

}

function get_sentence_index(node, sentence) {

    let toReadList = []
    let content_len = 2
    let node_index = 22
    let start = 0
    let end = 1

    lister2(node, toReadList, content_len)

    toReadList.map((item, index) => {
            let content = item.nodeType === 3 ? item.data : item.innerText


            if (content.replace(/[。.:：]/g, "") === sentence) {
                if (node_index === 22) {
                    node_index = index
                    console.log("node_index", node_index)
                }
                start = 0
                end = 1
                console.log("content === sentence node_index, start, end", node_index, start, end)
                return [node_index, start, end]
            } else if (content.indexOf(sentence) !== -1) {
                if (node_index === 22) {
                    node_index = index
                    console.log("node_index", node_index)
                }
                start = content.split(sentence)[0].length
                end = start + sentence.length
                console.log("content !== sentence node_index, start, end", node_index, start, end)
                return [node_index, start, end]

            }
        }
    )

    return [node_index, start, end]

}


let styles = {
    pageArea: {
        position: "absolute",
        left: 80,
        right: 80,
        top: 0,
        bottom: 0,
        paddingTop: 35,
    },
    button: {
        width: 70,
        height: 70,
        padding: 0,
    },
    arrow: {
        width: 50,
        height: 50,
    },
    prev: {
        position: "absolute",
        left: 0,
        bottom: 0,
        height: "90%",
        opacity: 0,
        cursor: "pointer",
        zIndex: 0,
        transition: ".3s",
        "&:hover": {
            opacity: 0.6,
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    mask: {
        position: "absolute",
        left: 70,
        top: 100,
        bottom: 100,
        height: window.innerHeight - 200,
        width: window.innerWidth - 140,
        opacity: 0,
        backgroundColor: "#657845",
        cursor: "pointer",
        zIndex: 0,
        transition: ".3s",
        "&:hover": {
            opacity: 0,
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    playAudio: {
        position: "absolute",
        right: 6,
        height: "100%",
        opacity: 0,
        cursor: "pointer",
        transition: ".3s",
        "&:hover": {
            opacity: .6,
        },

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        // alignItems: 'center',
    },
    next: {
        position: "absolute",
        right: 0,
        height: "100%",
        opacity: 0,
        cursor: "pointer",
        transition: ".3s",
        "&:hover": {
            opacity: 1,
        },

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
};

class ViewArea extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            side_iframe: false,
            current_range: null,
            showStatus: false,
            progress: "",
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            endTime: (new Date()).valueOf(),
            startTime: 0,
            page_text: [],
            new_chapter_list: [],
            // toReadList: [],
            playing: false,
            current_index: 0, //当前阅读&播放进度, 基于当前页面
            current_line: null, // selections[0] DOM 对象
            sentence_index: 0, //分句编号
            sentences_length: 1,// 分了多少句
            sentences: [], // 分句
            select_index: 0, //高亮内容的index， 基于chapter
            ebook_ifram: null,
            html: null,// 当前
            begin_flag: 0,  //这是啥？？？？, 貌似没用
            openMenu: false, // 是否打开菜单
            openNoteCard: false, // 是否打开附注编辑框
            mPosX: 0, // 菜单的X轴坐标
            mPosY: 0, // 菜单的Y轴坐标
            nPosX: 0, // 附注输入框的X轴坐标
            nPosY: 0, // 附注输入框的Y轴坐标
            note: {}, // 当前正在被编辑的note
            lan: Config.get().lan ? Config.get().lan : "zh",
            ctp: Config.get().ctp ? Config.get().ctp : 1,
            sleep: Config.get().sleep ? Config.get().sleep : 60,
            content_len: Config.get().content_len ? Config.get().content_len : 60,
            cuid: localStorage.getItem("user") ? localStorage.getItem("user") : "guest",
            tok: localStorage.getItem("tok") ? localStorage.getItem("tok") : "",
            vol: Config.get().vol ? Config.get().vol : 9,
            per: Config.get().per ? Config.get().per : 2,
            spd: Config.get().spd ? Config.get().spd : 6,
            pit: Config.get().pit ? Config.get().pit : 5,
            note_url: Config.get().Note ? Config.get().Note : "https://www.wolai.com/",
        };
        this.x = 0; // 计算菜单坐标时的中间结果
        this.y = 0; // 计算菜单坐标时的中间结果
        this.pen = null;
        this.notes = []; // 当前章节的note
        this.key = ""; // 当前正在渲染的note的key

        this.read_tags = ["P", "H1", "H2", "H3", "H4", "SPAN", "DIV", "TBODY"];


        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.handleMarkDialogOpen = this.handleMarkDialogOpen.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeNoteCard = this.closeNoteCard.bind(this);
        this.openNoteCard = this.openNoteCard.bind(this);
        this.renderNotes = this.renderNotes.bind(this);
        this.getPen = this.getPen.bind(this);
        this.setNote = this.setNote.bind(this);
        this.setKey = this.setKey.bind(this);
        this.addIframe = this.addIframe.bind(this);
    }


    similar(s, t, f) {
        if (!s || !t) {
            return 0;
        }
        var l = s.length > t.length ? s.length : t.length;
        var n = s.length;
        var m = t.length;
        var d = [];
        f = f || 3;
        var min = function (a, b, c) {
            return a < b ? (a < c ? a : c) : (b < c ? b : c);
        };
        var i, j, si, tj, cost;
        if (n === 0) return m;
        if (m === 0) return n;
        for (i = 0; i <= n; i++) {
            d[i] = [];
            d[i][0] = i;
        }
        for (j = 0; j <= m; j++) {
            d[0][j] = j;
        }
        for (i = 1; i <= n; i++) {
            si = s.charAt(i - 1);
            for (j = 1; j <= m; j++) {
                tj = t.charAt(j - 1);
                if (si === tj) {
                    cost = 0;
                } else {
                    cost = 1;
                }
                d[i][j] = min(d[i - 1][j] + 1, d[i][j - 1] + 1, d[i - 1][j - 1] + cost);
            }
        }
        let res = (1 - d[n][m] / l);
        return res.toFixed(f);
    }


    // 根据选取的文本创建Note对象
    createNote(color) {
        try {
            window.This = this;
            let {book, epub} = this.props;
            let iframe = document.getElementsByTagName("iframe")[0];
            let iDoc = iframe.contentDocument;


            // let range = document.createRange();
            // range.selectNode(window.This.state.current_line);

            // iDoc.getSelection().removeAllRanges();
            // iDoc.getSelection().addRange(range);


            let sel = iDoc.getSelection();
            let range = sel.getRangeAt(0);


            console.log("selselselsdfghj", sel);
            console.log("rangebbbbb", range);
            console.log("range type55555", typeof range);
            let text = sel.toString();
            text = text && text.trim();
            console.log("texttexttexttext", text);


            let cfiBase = epub.renderer.currentChapter.cfiBase;
            let cfi = new window.EPUBJS.EpubCFI().generateCfiFromRange(range, cfiBase);
            let chapter = epub.renderer.currentChapter.spinePos;
            let bookKey = book.key;
            let charRange = window.rangy.getSelection(iframe).saveCharacterRanges(iDoc.body)[0];
            let serial = JSON.stringify(charRange);
            let note = new Note(bookKey, chapter, text, cfi, serial, color, "");
            return note;


        } catch (e) {
            console.log("createNotexcvbnm,ertyu", e);
        }
    }


    // 重点自动高亮
    createNote2(color) {


        window.This = this;
        let {book, epub} = this.props;
        let iframe = document.getElementsByTagName("iframe")[0];
        let iDoc = iframe.contentDocument;
        let sel = iDoc.getSelection();

        let range = sel.getRangeAt(0);


        // let range = document.createRange();
        // range.selectNode(window.This.state.current_line);

        iDoc.getSelection().removeAllRanges();
        iDoc.getSelection().addRange(range);


        console.log("selselselsdfghj", sel);
        console.log("rangebbbbb", range);
        console.log("range type55555", typeof range);
        let text = sel.toString();
        text = text && text.trim();
        console.log("texttexttexttext", text);


        let cfiBase = epub.renderer.currentChapter.cfiBase;
        let cfi = new window.EPUBJS.EpubCFI().generateCfiFromRange(range, cfiBase);
        let chapter = epub.renderer.currentChapter.spinePos;
        let bookKey = book.key;
        let charRange = window.rangy.getSelection(iframe).saveCharacterRanges(iDoc.body)[0];
        let serial = JSON.stringify(charRange);
        let note = new Note(bookKey, chapter, text, cfi, serial, color, "");
        return note;


    }


    prevP() {
        // 上一段落
        if (window.This.state.current_index - 1 === -1) {

            window.This.prev();


            let audios = document.getElementsByTagName("audio");

            audios[0].pause();
            // audios[1].pause()

            let forward = new Promise((resolve, reject) => {

                window.This.setState({

                    current_index: window.This.state.page_text.length - 1,
                    begin_flag: 0
                });

                resolve("current_index - 1");
            });

            forward.then(() => {

                console.log("播放");


                if (this.state.current_index === 0) {

                    let audios = document.getElementsByTagName("audio");
                    this.render_player();
                    audios[0].play();

                } else {

                    let audios = document.getElementsByTagName("audio");
                    window.This.render_player();
                    audios[0].play();
                }

            });


            setTimeout(function () {

            }, 100);


            return false;


        } else {

            let audios = document.getElementsByTagName("audio");

            audios[0].pause();
            // audios[1].pause()

            let forward = new Promise((resolve, reject) => {

                window.This.setState({

                    current_index: window.This.state.current_index - 1,
                    select_index: window.This.state.select_index - 1,
                    begin_flag: 0
                });

                resolve("current_index - 1");
            });

            forward.then(() => {

                console.log("播放");


                if (this.state.current_index === 0) {


                    let audios = document.getElementsByTagName("audio");

                    this.render_player();

                    audios[0].play();

                } else {

                    let audios = document.getElementsByTagName("audio");

                    window.This.render_player();


                    audios[0].play();

                }


            });


            setTimeout(function () {

            }, 100);


            return false;


        }


    }


    prevSentence() {


        window.This = this;
        // 上一小句

        if (window.This.state.current_index - 1 === -1) {

            // 上一页


            let forwardazog = new Promise((resolve => {

                window.This.prev();
                setTimeout(function () {

                    resolve(67);

                }, 100);


            }));


            forwardazog.then(() => {

                let audios = document.getElementsByTagName("audio");
                audios[0].pause();
                let forward = new Promise((resolve, reject) => {

                    console.log("456window.This.state.page_text.length5678", window.This.state.page_text.length);

                    window.This.setState({
                        current_index: window.This.state.page_text.length - 1,
                    });

                    console.log("current_indexerfghmkjirtgbnjkoplk", window.This.state.current_index);
                    console.log("current_indexerfghmkjirt,kiolp;", window.This.state.page_text.length - 1);

                    setTimeout(function () {
                        resolve("current_index - 1");

                    }, 100);


                });
                forward.then(() => {
                    console.log("播放");
                    if (this.state.current_index === 0) {
                        let audios = document.getElementsByTagName("audio");

                        console.log("current_indexerfghmkji", window.This.state.current_index);

                        this.render_player();
                        audios[0].play();

                    } else {

                        let audios = document.getElementsByTagName("audio");
                        window.This.render_player();
                        audios[0].play();
                    }

                });


            });


            return false;
        } else {


            let audios = document.getElementsByTagName("audio");
            audios[0].pause();
            // audios[1].pause()
            let forward = new Promise((resolve, reject) => {
                console.log("上一段。。。。。。。。。。。。");
                console.log("00000000000000000000");
                window.This.setState({
                    current_index: window.This.state.current_index - 1,
                    begin_flag: 0
                });


                resolve("current_index - 1");
            });

            forward.then(() => {
                console.log("播放");
                if (this.state.current_index === 0) {


                    let audios = document.getElementsByTagName("audio");

                    this.render_player();

                    audios[0].play();

                } else {

                    let audios = document.getElementsByTagName("audio");

                    window.This.render_player();


                    audios[0].play();

                }
            });
            setTimeout(function () {

            }, 100);


            return false;


        }


    }


    nextSentence() {


        window.This = this;

        console.log("按Right键下一句");
        let audios = document.getElementsByTagName("audio");
        audios[0].pause();
        let forward = new Promise((resolve) => {


            console.log("window.This.state.sentence_index", window.This.state.sentence_index);
            console.log("window.This.state.current_index", window.This.state.current_index);
            console.log("window.This.state.sentences_length - 1", window.This.state.sentences_length - 1);


            console.log("最后一个子句了4567890-tyuiop");

            window.This.setState({
                current_index: window.This.state.current_index + 1,
                sentence_index: 0
            });

            resolve("current_index + 1");


        });


        forward.then(() => {


            console.log("播放09845678998765345");
            let audios = document.getElementsByTagName("audio");
            this.render_player();
            audios[0].play();

            console.log("window.This.state.sentence_index 098765678juyfgh", window.This.state.sentence_index);
            console.log("window.This.state.current_index dfgh9876ertykj", window.This.state.current_index);


        });


    }


    stopPlay() {


        let audios = document.getElementsByTagName("audio");
        audios[0].pause();

        this.setState({
            playing: false
        });


    }


    togglePlay() {

        window.togglePlay = this;
        if (window.togglePlay.state.playing === true) {

            window.togglePlay.setState({
                playing: !window.togglePlay.state.playing
            });


            window.togglePlay.stopPlay();


        } else {


            window.togglePlay.setState({
                playing: !window.togglePlay.state.playing
            });

            if (window.togglePlay.state.current_index === 0) {
                window.togglePlay.play_audio();
            } else {
                window.togglePlay.continuePlay();
            }


        }
    }


    inputFocus() {


        document.getElementById("note-input").focus();


    }

    // todo  快速标记重点内容


    handleHighlightImportant_b() {

        // keycode = 66

        this.setState({

            showStatus: true


        });


        let mark_list = this.state.new_chapter_node_list.filter((item, index) => {


            console.log("item.nodeName", item.nodeType, item.nodeName.toLowerCase());

            // let all = ["b", "h1", "h2", "h3", "h4", "h5", "li"];
            let all = ["b"];

            let nodeName = item.nodeName.toLowerCase();


            if (index === 0) {

                return item;

            } else if (all.indexOf(nodeName) !== -1) {

                return item;
            }


        });


        // console.log("mark_list", mark_list);

        mark_list.map((item, index) => {


            async function ww() {

                return new Promise(resolve => {
                    setTimeout(() => {
                        resolve(item);
                    }, index * 500);
                });

            }

            ww().then((v) => {
                console.log(v);
                console.log("item.innerText", item.innerText);
                let line_range = document.createRange();
                line_range.selectNode(item);
                let iframe = document.getElementsByTagName("iframe")[0];
                let iDoc = iframe.contentDocument;
                iDoc.getSelection().removeAllRanges();
                iDoc.getSelection().addRange(line_range);
                let sel = iDoc.getSelection();
                console.log("sel..567897656jhgfgh.", sel.toString());


                window.This.pen.highlightSelection("color-1");
                window.This.handleHighlight2();


                this.setState({

                    progress: item.innerText,
                    showStatus: true
                });

            });

        });


    }

    handleHighlightImportant_h() {


        console.log("72_hhhhhhhhhhh");

        // keycode = 72


        this.setState({

            showStatus: true


        });


        let mark_list = this.state.new_chapter_node_list.filter((item, index) => {


            console.log("item.nodeName", item.nodeType, item.nodeName.toLowerCase());

            // let all = ["b", "h1", "h2", "h3", "h4", "h5", "li"];
            let all = ["h1", "h2", "h3", "h4", "h5"];

            let nodeName = item.nodeName.toLowerCase();


            if (index === 0) {

                return item;

            } else if (all.indexOf(nodeName) !== -1) {

                return item;
            }


        });


        // console.log("mark_list", mark_list);

        mark_list.map((item, index) => {


            async function ww() {

                return new Promise(resolve => {


                    setTimeout(() => {

                        resolve(item);


                    }, index * 500);

                });


            }

            ww().then((v) => {

                console.log(v);


                console.log("item.innerText", item.innerText);
                let line_range = document.createRange();
                line_range.selectNode(item);
                let iframe = document.getElementsByTagName("iframe")[0];
                let iDoc = iframe.contentDocument;
                iDoc.getSelection().removeAllRanges();
                iDoc.getSelection().addRange(line_range);
                let sel = iDoc.getSelection();
                console.log("sel..567897656jhgfgh.", sel.toString());


                window.This.pen.highlightSelection("color-1");
                window.This.handleHighlight2();


                this.setState({

                    progress: item.innerText,
                    showStatus: true
                });

            });

        });


    }

    handleHighlightImportant_l() {

        // keycode = 76

        this.setState({

            showStatus: true


        });


        let mark_list = this.state.new_chapter_node_list.filter((item, index) => {


            console.log("item.nodeName", item.nodeType, item.nodeName.toLowerCase());

            // let all = ["b", "h1", "h2", "h3", "h4", "h5", "li"];
            let all = ["li"];

            let nodeName = item.nodeName.toLowerCase();


            if (index === 0) {

                return item;

            } else if (all.indexOf(nodeName) !== -1) {

                return item;
            }


        });


        // console.log("mark_list", mark_list);

        mark_list.map((item, index) => {


            async function ww() {

                return new Promise(resolve => {


                    setTimeout(() => {

                        resolve(item);


                    }, index * 500);

                });


            }

            ww().then((v) => {

                console.log(v);


                console.log("item.innerText", item.innerText);
                let line_range = document.createRange();
                line_range.selectNode(item);
                let iframe = document.getElementsByTagName("iframe")[0];
                let iDoc = iframe.contentDocument;
                iDoc.getSelection().removeAllRanges();
                iDoc.getSelection().addRange(line_range);
                let sel = iDoc.getSelection();
                console.log("sel..567897656jhgfgh.", sel.toString());


                window.This.pen.highlightSelection("color-1");
                window.This.handleHighlight2();


                this.setState({

                    progress: item.innerText,
                    showStatus: true
                });

            });

        });


    }


    // 点击添加高亮
    handleHighlight() {

        try {
            console.log("handleHighlight-9375294",);
            let iDoc = document.getElementsByTagName("iframe")[0].contentDocument;
            let color = parseInt(0);
            if (isNaN(color)) return;
            let note = this.createNote(color);
            let classes = ["color-0", "color-1", "color-2", "color-3"];
            this.setKey(note.key); // 修复新添加的高亮没有正确的key的问题
            this.pen.highlightSelection(classes[color]);
            iDoc.getSelection().empty(); // 清空文本选取
            this.props.addNote(note);
            console.log("%c Add note here. ", "background-color: green");
        } catch (e) {

            console.log("cfgtym", e);
        }


    }


    // 添加重点高亮
    handleHighlight2() {

        try {
            console.log("handleHighlight-9375294",);

            let iDoc = document.getElementsByTagName("iframe")[0].contentDocument;
            let color = parseInt(0);
            if (isNaN(color)) return;
            let note = this.createNote2(color);
            let classes = ["color-0", "color-1", "color-2", "color-3"];

            this.setKey(note.key); // 修复新添加的高亮没有正确的key的问题
            this.pen.highlightSelection(classes[color]);
            iDoc.getSelection().empty(); // 清空文本选取
            this.props.addNote(note);
            this.closeMenu();
            console.log("%c Add note here. ", "background-color: green");
        } catch (e) {

            console.log("cfgtym", e);
        }


    }

    // 点击添加高亮&记录笔记
    handleHighlightByKeyboard() {

        console.log("handleHighlightByKeyboard",);

        let iDoc = document.getElementsByTagName("iframe")[0].contentDocument;
        let color = parseInt(0);
        if (isNaN(color)) return;
        let note = this.createNote(color);
        let classes = ["color-0", "color-1", "color-2", "color-3"];

        this.setKey(note.key); // 修复新添加的高亮没有正确的key的问题
        this.pen.highlightSelection(classes[color]);
        iDoc.getSelection().empty(); // 清空文本选取

        this.props.addNote(note);
        this.closeMenu();
        console.log("%c Add note here. ", "background-color: green");

        this.setNote(note);
        let [x, y] = [window.innerWidth / 2, window.innerHeight / 2];
        this.openNoteCard(x, y);
        this.stopPlay();
        this.inputFocus();


    }


    generate_chapter_list_node_list() {

        let ebook_ifram = document.getElementsByTagName("iframe")[0].contentDocument;
        let new_chapter = ebook_ifram.getElementsByTagName("body")[0];
        toReadListofChapter = [];
        toMarkList = [];
        try {
            ebook_ifram.querySelectorAll('div').forEach((item) => {
                if (item.innerText === "本书由“ePUBw.COM”整理，ePUBw.COM 提供最新最全的优质电子书下载！！！") {
                    console.log(item.innerText)
                    item.style.display = 'none'

                }
            })
        } catch (e) {
        }
        lister2(new_chapter, toReadListofChapter, parseInt(window.This.state.content_len));

        let new_chapter_node_list = new_node_list(toReadListofChapter);
        let new_chapter_list = new_chapter_list_func(new_chapter_node_list);


        return [new_chapter_list, new_chapter_node_list]


    }


    componentWillUnmount() {


        document.removeEventListener("copy", this.copyTextHack);


    }


    addIframe() {

        if (this.state.side_iframe) {

            let window_width = window.innerWidth * 2
            let toolbar = document.getElementById("toolbar")
            toolbar.parentElement.style.left = (window_width / 2 - 350).toString() + "px"
            let play_div = document.getElementById("play")
            play_div.style.right = (window_width / 2).toString() + "px"
            let mask_div = document.getElementById("mask")
            mask_div.style.width = (window.innerWidth / 2 - 140).toString() + "px"
            let page_area = document.getElementById("page-area")
            page_area.style.inset = newInset(page_area.style.inset, (window_width / 4))
            let note_area = document.getElementById('note_area');
            note_area.remove()


        } else {

            let window_width = window.innerWidth
            let window_height = window.innerHeight
            let toolbar = document.getElementById("toolbar")
            toolbar.parentElement.style.left = (window_width / 2 - 350).toString() + "px"
            let play_div = document.getElementById("play")
            play_div.style.right = (window_width / 2).toString() + "px"
            let mask_div = document.getElementById("mask")
            mask_div.style.width = (window.innerWidth / 2 - 140).toString() + "px"
            let page_area = document.getElementById("page-area")
            page_area.style.inset = newInset(page_area.style.inset, window_width / 2)
            // page_area.style.left = "18px"
            // page_area.style.right = "18px"

            // page_area.style.inset = page_area.style.rigth + "200px"
            let note_area = document.createElement('div');
            note_area.id = "note_area"
            note_area.style.width = "50%"
            note_area.style.height = "100vh"
            // note_area.style.backgroundColor = "#2b2b2b"
            note_area.style.marginLeft = "50%"
            // note_area.style.borderLeft = "1px solid #a4a3a3"
            note_area.style.boxShadow = "0px 0px 0px 1px rgba(0,0,0,0.05)"
            let new_iframe = document.createElement('iframe');
            new_iframe.frameBorder = "0"
            new_iframe.src = Config.get().Note ? Config.get().Note : "https://www.wolai.com/"
            // new_iframe.src = "https://naotu.baidu.com";
            // new_iframe.src = "https://www.feishu.cn/"; //会跳转
            // new_iframe.src = "https://www.yinxiang.com/";
            // new_iframe.src = "https://www.wiz.cn/xapp/";
            // new_iframe.src = "https://note.youdao.com/signIn/index.html";
            // new_iframe.src = "https://mdnice.com/";
            // new_iframe.src = "https://www.yuque.com/login";
            // new_iframe.src = "https://flomoapp.com/";
            // new_iframe.src = "https://evernote.com/";
            // new_iframe.src = "https://mm.edrawsoft.cn/files";
            // new_iframe.src = "https://www.processon.com/";
            // new_iframe.src = "https://www.jianguoyun.com/static/html/mindmap-landing/index.html";
            // new_iframe.src = "https://www.jianshu.com/";
            // new_iframe.src = "https://www.baklib.com/";
            // new_iframe.src = "https://www.teambition.com/";
            // new_iframe.src = "https://laverna.cc/app/";
            // new_iframe.src = "https://shimo.im/";
            // new_iframe.src = "https://notebook.zoho.com.cn/app/index.html#/notebooks";
            // new_iframe.src = "https://mubu.com/app";
            // new_iframe.src = "https://yun.smartisan.com/#/notes";
            // new_iframe.src = "https://github.com/zmister2016/MrDoc";
            // new_iframe.src = "http://markdown.xiaoshujiang.com/";
            // new_iframe.src = "http://markdown.xiaoshujiang.com/";
            new_iframe.style.width = "100%"
            new_iframe.style.height = "100%"
            // new_iframe.style.height = (window_height - 6) + "px"   //"99.2vh"
            page_area.parentElement.appendChild(note_area);
            note_area.appendChild(new_iframe)

        }


    }


    componentDidUpdate() {


        let stopTime = localStorage.getItem("stopTime") ? localStorage.getItem("stopTime") : new Date().getTime() + 60000;


        if (new Date().getTime() > stopTime) {


            let sleepTime = parseInt(this.state.sleep);

            alert(`已阅读${sleepTime}分钟，可以休息一会哦~`);


            // var value = prompt("再听多少分钟？", "30");
            let stopTime = new Date().getTime() + sleepTime * 60000;
            localStorage.setItem("stopTime", stopTime);
        }

        window.This = this;
    }


    componentDidMount() {


        let {device} = this.props;


        if (device === "pc") {
            document.getElementById("mask").style.visibility = 'hidden';


        } else {
            document.getElementById("mask").style.visibility = 'visible';
        }


        window.SwipeControl = this;

        function nextSen(e) {
            console.log(e);
            e.preventDefault();
            window.SwipeControl.nextSentence();
        }

        function prevSen(e) {
            console.log(e);
            e.preventDefault();
            window.SwipeControl.prevSentence();
        }

        function toggleP(e) {
            console.log(e);
            e.preventDefault();
            window.SwipeControl.togglePlay();
        }


        function updateHtml(e) {
            console.log(e);
            e.preventDefault();
        }

        function HighlightSen(e) {
            console.log(e);
            e.preventDefault();
            window.SwipeControl.handleHighlight();
        }


        var $ = document.querySelector.bind(document),
            test = $('#mask'),
            preventDefault = function (e) {
                e.preventDefault();
            };
        test.addEventListener('tap', toggleP);
        test.addEventListener('dbltap', HighlightSen);
        test.addEventListener('longtap', updateHtml);
        test.addEventListener('swipeup', nextSen);
        test.addEventListener('swipedown', prevSen);
        test.addEventListener('swipeleft', window.SwipeControl.next);
        test.addEventListener('swiperight', window.SwipeControl.prev);
        test.addEventListener('touchmove', preventDefault);
        test.addEventListener('touchstart', preventDefault);
        test.addEventListener('touchend', preventDefault);


        let stopTime = new Date().getTime() + parseInt(this.state.sleep) * 60000;
        localStorage.setItem("stopTime", stopTime);
        this.prev();
        this.cycle_playing();

        let {book, epub} = this.props;

        let page = document.getElementById("page-area");
        this.rendition = epub.renderTo(page); // 渲染页面

        this.bindEvent(); // 绑定事件
        epub.gotoCfi(AutoBookmark.getCfi(book.key)); // 跳转到上一次阅读位置

        // 解决火狐下不能正常复制
        this.copyTextHack = (event) => {
            let iDoc = document.getElementsByTagName("iframe")[0].contentDocument;
            let copyText = iDoc.getSelection().toString() || document.getSelection().toString();
            event.clipboardData.setData("text/plain", copyText);
            event.preventDefault();
        };
        document.addEventListener("copy", this.copyTextHack);


    }


    current_node2(i) {
        let this_line = this.state.page_text[i];
        window.This = this;
        console.log("this_line", this_line);
        if (this_line) {


            let select_index = window.This.state.new_chapter_list.indexOf(this_line);
            if (select_index === -1) {
                //无法精确匹配时，暂且使用相似度匹配
                window.This.state.new_chapter_list.map((item, index) => {
                    if (this.similar(item, this_line) > 0.8) {
                        select_index = index;


                        return index;
                    } else if (item.indexOf(this_line) !== -1) {

                        select_index = index;
                        return index;
                    }

                });
            }
            console.log("select_index", select_index);

            let select_object = window.This.state.new_chapter_node_list[select_index];

            return select_object;


        } else {
            return false;
        }
    }


    current_node(i) {
        let this_line = this.state.page_text[i];
        window.This = this;
        console.log("this_line", this_line);
        if (this_line) {

            let ebook_ifram = document.getElementsByTagName("iframe")[0].contentDocument;
            let new_chapter = ebook_ifram.getElementsByTagName("body")[0];
            let select_index = 0
            let p_node_list = []
            lister(new_chapter, p_node_list)
            p_node_list.map((item, index) => {

                let P_text = item.innerText.replace(/\s*/g, "")
                if (P_text.indexOf(this_line) !== -1) {
                    select_index = index

                    if (select_index === 0) {
                        select_index = index
                        console.log("select_index", select_index)
                    }


                }
            })

            return p_node_list[select_index]


        } else {
            return false;
        }
    }

    get_current_node_by_sentence(sentence) {
        let ebook_ifram = document.getElementsByTagName("iframe")[0].contentDocument;
        let new_chapter = ebook_ifram.getElementsByTagName("body")[0];
        let select_index = 0
        let p_node_list = []
        lister(new_chapter, p_node_list)

        async function get_p_nodes() {
            p_node_list.map((item, index) => {
                let P_text = item.innerText
                if (P_text.indexOf(sentence) !== -1) {
                    if (select_index === 0) {
                        select_index = index
                        console.log("select_index", select_index)
                    }
                }
            })
            return p_node_list
        }

        get_p_nodes().then((p_node_list) => {
                return p_node_list[select_index]
            }
        )

        // console.log("p_node_list", p_node_list)

        return p_node_list[select_index]

    }

    continuePlay() {

        try {
            let audios = document.getElementsByTagName("audio");
            audios[0].play();
        } catch (e) {
        }

    }


    play_audio() {
        try {
            this.render_player();
            let audios = document.getElementsByTagName("audio");
            audios[0].play();
        } catch (e) {
        }

    }


    // 为阅读界面绑定事件
    bindEvent() {

        window.This = this;


        let epub = this.props.epub;
        let isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;
        let lock = false; // 暂时锁住翻页快捷键，避免快速点击产生的Bug

        let arrowKeys = event => {
            event.preventDefault();

            console.log("key code...................", event.keyCode);

            if (event.ctrlKey && event.keyCode == 87) {

                window.close();
            }

            if (event.metaKey && event.keyCode == 87) {

                window.close();
            }

            if (event.ctrlKey && event.keyCode == 65) {

                //全选章节

                console.log("全选章节")
                let iDoc = document.getElementsByTagName("iframe")[0].contentDocument;
                let range = document.createRange();
                range.selectNode(iDoc.body);
                iDoc.getSelection().removeAllRanges();
                iDoc.getSelection().addRange(range);


            }


            if (lock) return;

            if (event.keyCode === 33 || event.keyCode === 219) {
                epub.prevPage();
                lock = true;
                setTimeout(function () {
                    lock = false;
                }, 100);
                return false;
            }

            if (event.keyCode === 34 || event.keyCode === 221) {
                epub.nextPage();
                lock = true;
                setTimeout(function () {
                    lock = false;
                }, 100);
                return false;
            }


            //切换分栏，添加笔记页
            if (event.keyCode === 78 || event.keyCode === 78) {


                window.This.addIframe()
                window.This.setState(
                    {
                        side_iframe: !window.This.state.side_iframe
                    }
                )


                // if (window.This.state.side_iframe ){
                // } else {
                //     window.This.addIframe()
                // }
                //

            }


            //按上键，开始、暂停
            if (event.keyCode === 38 || event.keyCode === 38) {

                console.log("按上键，暂停/恢复播放");
                if (window.This.state.playing === true) {

                    window.This.setState({
                        playing: !window.This.state.playing
                    });


                    window.This.stopPlay();
                    lock = true;
                    setTimeout(function () {
                        lock = false;
                    }, 100);
                    return false;


                } else {


                    window.This.setState({
                        playing: !window.This.state.playing
                    });


                    if (window.This.state.current_index === 0) {
                        window.This.play_audio();
                    } else {
                        window.This.continuePlay();
                    }


                    lock = true;
                    setTimeout(function () {
                        lock = false;
                    }, 100);
                    return false;


                }


            }

            //按right键

            if (event.keyCode === 39 || event.keyCode === 177) {

                this.nextSentence();
                lock = true;
                setTimeout(function () {
                    lock = false;
                }, 100);
                return false;
            }

            //按left键

            if (event.keyCode === 37 || event.keyCode === 176) {


                this.prevSentence();


            }


            //按标记键键，或者空格键（32），回车键（13），Down（40）

            if (event.keyCode === 40) {
                console.log("敲重点当前句子");
                this.handleHighlightByKeyboard();


            } else if (event.keyCode === 32 || event.keyCode === 13) {

                console.log("空格 或者 回车 敲重点当前句子");

                this.handleHighlight();


            }


            if (event.keyCode === 66) {

                this.handleHighlightImportant_b();
            } else if (event.keyCode === 76) {

                this.handleHighlightImportant_l();
            } else if (event.keyCode === 72) {

                this.handleHighlightImportant_h();
            }


            // if (event.keyCode === 13) {
            //
            //     event.preventDefault();
            //
            //
            //     document.getElementById("root").focus()
            //
            //
            // }
            //

        };

        let mouseFirefox = event => {
            event.preventDefault();

            if (lock) return;

            if (event.detail < 0) {
                epub.prevPage();
                lock = true;
                setTimeout(function () {
                    lock = false;
                }, 100);
                return false;
            }

            if (event.detail > 0) {
                epub.nextPage();
                lock = true;
                setTimeout(function () {
                    lock = false;
                }, 100);
                return false;
            }
        };

        let mouseChrome = event => {
            try {
                event.preventDefault();
            } catch (e) {
            }

            if (lock) return;

            if (event.wheelDelta > 0) {
                epub.prevPage();
                lock = true;
                setTimeout(function () {
                    lock = false;
                }, 100);
                return false;
            }

            if (event.wheelDelta < 0) {
                epub.nextPage();
                lock = true;
                setTimeout(function () {
                    lock = false;
                }, 100);
                return false;
            }
        };

        let copyText = event => {
            let key = event.keyCode || event.which;
            if (key === 67 && event.ctrlKey) {
                let iDoc = document.getElementsByTagName("iframe")[0].contentDocument;
                let text = iDoc.execCommand("copy", false, null);
                !text ? console.log("failed to copy text to clipboard") : console.log(`copied!`);
            }
        };
        let onEnterUp = event => {
            let key = event.keyCode;
            if (key === 13) {

                this.continuePlay();
            }
        };


        epub.on("renderer:chapterDisplayed", () => {


            window.This = this;


            console.log("切换了章节..............................................");

            this.getPen(); // 切换章节后获取 pen

            console.log("%c renderer:chapterDisplayed has been triggered! ", "color: cyan; background: #333333");

            let doc = epub.renderer.doc;
            doc.addEventListener("click", this.openMenu); // 为每一章节内容绑定弹出菜单触发程序
            doc.addEventListener("keydown", arrowKeys, false); // 箭头按键翻页
            doc.addEventListener("keydown", copyText); // 解决 Ctrl + C 复制的bug
            doc.addEventListener("keyup", onEnterUp); // 编辑note完之后，按下Enter键松开后继续播放

            // 鼠标滚轮翻页
            if (isFirefox)
                doc.addEventListener("DOMMouseScroll", mouseFirefox, false);
            else
                doc.addEventListener("mousewheel", mouseChrome, false);


            Util.addDefaultCss(); // 切换章节后为当前文档设置默认的样式
            Util.applyCss(); // 切换章节后应当为当前文档设置样式
            this.renderNotes(); // 切换章节后需要重新渲染注释

            // doc.addEventListener("touchstart", function (event) {
            //     // event.preventDefault();
            //
            //
            //     let startTime = (new Date()).valueOf();
            //
            //
            //     console.log("start.............");
            //
            //
            //     let touch = event.targetTouches[0];
            //     //滑动起点的坐标
            //     console.log("startX:" + touch.pageX + "," + "startY:" + touch.pageY);
            //     window.This.setState({
            //
            //         startX: touch.pageX,
            //         startY: touch.pageY,
            //         endX: touch.pageX,
            //         endY: touch.pageY,
            //         startTime: startTime
            //     });
            //
            //
            // });
            // doc.addEventListener("touchmove", function (event) {
            //     // event.preventDefault();
            //     let touch1 = event.targetTouches[0];
            //     //手势滑动时，手势坐标不断变化，取最后一点的坐标为最终的终点坐标
            //     window.This.setState({
            //
            //         endX: touch1.pageX,
            //         endY: touch1.pageY
            //     });
            //
            //     // console.log("moving........", "endX:" + touch1.pageX + "," + "endY:" + touch1.pageX);
            // });

            // 滑动控制
            // doc.addEventListener("touchend", function (event) {
            //
            //     let endTime = (new Date()).valueOf();
            //     console.log("endTime", endTime);
            //     console.log("window.This.endTime", window.This.state.endTime);
            //
            //
            //     // event.preventDefault();
            //     console.log("end.......................");
            //     let distanceX = window.This.state.endX - window.This.state.startX;
            //     let distanceY = window.This.state.endY - window.This.state.startY;
            //     console.log("distanceX:" + distanceX + "," + "distanceY:" + distanceY);
            //
            //
            //     if ((endTime - window.This.state.endTime < 2000) && (endTime - window.This.state.endTime > 100)) {
            //
            //         console.log("敲重点当前句子88888888");
            //         window.This.handleHighlight();
            //         window.This.setState({
            //
            //             endTime: endTime
            //
            //         });
            //         return false;
            //     } else {
            //         window.This.setState({
            //
            //             endTime: endTime
            //
            //         });
            //
            //     }
            //
            //
            //     console.log("endTime - window.This.state.startTime", endTime - window.This.state.startTime);
            //
            //     if (endTime - window.This.state.startTime > 200) {
            //
            //         // 点按 播放
            //         if (Math.abs(distanceY) + Math.abs(distanceX) < 10) {
            //             console.log("暂停/恢复播放");
            //             if (window.This.state.playing === true) {
            //                 window.This.setState({
            //                     playing: !window.This.state.playing
            //                 });
            //                 window.This.stopPlay();
            //                 lock = true;
            //                 setTimeout(function () {
            //                     lock = false;
            //                 }, 100);
            //
            //
            //                 try {
            //                     let iframe = document.getElementsByTagName("iframe")[0];
            //                     let iDoc = iframe.contentDocument;
            //
            //                     // let range = sel.getRangeAt(0);
            //
            //
            //                     let range = document.createRange();
            //                     range.selectNode(window.This.state.current_line);
            //
            //                     iDoc.getSelection().removeAllRanges();
            //                     iDoc.getSelection().addRange(range);
            //
            //
            //                     window.This.pen.highlightSelection("color-1");
            //                     iDoc.getSelection().empty(); // 清空文本选取
            //                 } catch (e) {
            //                     console.log("qas5thjol9o", e);
            //                 }
            //
            //
            //                 return false;
            //
            //
            //             } else {
            //
            //
            //                 window.This.setState({
            //                     playing: !window.This.state.playing
            //                 });
            //
            //
            //                 if (window.This.state.current_index === 0) {
            //                     window.This.play_audio();
            //                 } else {
            //                     window.This.continuePlay();
            //                 }
            //
            //
            //                 lock = true;
            //                 setTimeout(function () {
            //                     lock = false;
            //                 }, 100);
            //
            //                 try {
            //                     let iframe = document.getElementsByTagName("iframe")[0];
            //                     let iDoc = iframe.contentDocument;
            //
            //                     // let range = sel.getRangeAt(0);
            //
            //
            //                     let range = document.createRange();
            //                     range.selectNode(window.This.state.current_line);
            //
            //                     iDoc.getSelection().removeAllRanges();
            //                     iDoc.getSelection().addRange(range);
            //
            //
            //                     window.This.pen.highlightSelection("color-1");
            //                     iDoc.getSelection().empty(); // 清空文本选取
            //                 } catch (e) {
            //                     console.log("sxcvbpoiuj", e);
            //                 }
            //
            //
            //                 return false;
            //
            //
            //             }
            //
            //
            //             return false;
            //         }
            //
            //
            //         // 上下滑动
            //         if (Math.abs(distanceY) > Math.abs(distanceX)) {
            //
            //
            //             console.log("上下滑动了", distanceY);
            //
            //
            //             // 短滑翻句
            //
            //             if ((distanceY > 10) && (distanceY < 500)) {
            //                 window.This.prevSentence();
            //                 return false;
            //             }
            //
            //             if ((distanceY > -500) && (distanceY < -10)) {
            //                 window.This.nextSentence();
            //                 return false;
            //
            //
            //             }
            //
            //
            //         } else {
            //
            //             // 长滑翻页
            //
            //             if (50 < distanceX) {
            //                 window.This.prev();
            //                 return false;
            //             }
            //
            //             if (distanceX < -50) {
            //                 window.This.next();
            //                 return false;
            //
            //
            //             }
            //
            //
            //             // 左右滑动
            //         }
            //
            //
            //     } else {
            //
            //         try {
            //             let iframe = document.getElementsByTagName("iframe")[0];
            //             let iDoc = iframe.contentDocument;
            //
            //             // let range = sel.getRangeAt(0);
            //
            //
            //             let range = document.createRange();
            //             range.selectNode(window.This.state.current_line);
            //
            //             iDoc.getSelection().removeAllRanges();
            //             iDoc.getSelection().addRange(range);
            //
            //
            //             window.This.pen.highlightSelection("color-1");
            //             iDoc.getSelection().empty(); // 清空文本选取
            //         } catch (e) {
            //             console.log("dxcvbnerf", e);
            //         }
            //
            //     }
            //
            //
            // });


        });
        epub.on("renderer:visibleRangeChanged", () => {
            let {book, epub} = this.props;


            // console.log("book.navigation", book.navigation);

            let bookKey = book.key;
            let cfi = epub.getCurrentLocationCfi();

            console.log("cficficficficficfi", cfi);


            // epub.ready.then(() => {
            //   this.navigation = this.book.navigation;
            //
            //   console.log("this.book.locations.generate();", this.book.locations.generate());
            //
            //   // 生成Locations对象
            //   return this.book.locations.generate();
            // });


            AutoBookmark.recordCfi(bookKey, cfi);
            // console.log('auto bookmark: ', cfi);
            // console.log("bookKey", bookKey);
        });

        epub.on("renderer:visibleRangeChanged", function (cfirange) {


            console.log("检测到翻页了.................................");
            var text = "";
            var cfi = new window.EPUBJS.EpubCFI();

            // console.log("cfirange.start", cfirange.start)
            // console.log("cfirange.end", cfirange.end)
            // console.log("cfirange", cfirange)

            var startRange = cfi.generateRangeFromCfi(cfirange.start, epub.renderer.render.document);
            var endRange = cfi.generateRangeFromCfi(cfirange.end, epub.renderer.render.document);
            // Create a new range to handle full cfi range (this should be fixed in v0.3)
            var fullRange = document.createRange();
            if (startRange) {
                fullRange.setStart(startRange.startContainer, startRange.startOffset);
            }
            if (endRange) {
                fullRange.setEnd(endRange.startContainer, endRange.startOffset + 1);
            }
            text = fullRange.toString();
            // console.log("fullRange", fullRange)
            var html = fullRange.cloneContents();
            // console.log("page_html................................", html);
            toReadList = [];
            lister2(html, toReadList, parseInt(window.This.state.content_len));
            let new_current_page_text = new_list(toReadList)

            console.log("new_current_page_text", new_current_page_text);

            let new_chapter_list = window.This.generate_chapter_list_node_list()[0]

            let new_chapter_node_list = window.This.generate_chapter_list_node_list()[1]


            // 更新页面数据

            if (window.This) {

                console.log("更新页面数据");


                let forward_efghj = new Promise((resolve => {


                    // console.log("this_line....................", this_line);


                    resolve(9);


                }));

                forward_efghj.then(() => {


                    console.log("new_chapter_node_list", new_chapter_node_list);
                    console.log("new_chapter_list", new_chapter_list)


                    window.This.setState({
                        page_text: new_current_page_text,
                        current_index: 0,
                        sentences: [],
                        sentences_length: 1,
                        html: html,
                        sentence_index: 0,
                        new_chapter_list: new_chapter_list,
                        new_chapter_node_list: new_chapter_node_list


                    });

                });


            } else {

                console.log("更新页面数据失败.............................");
                console.log("window.This0987645678", window.This);


            }


        });


    }

    // 关闭弹出菜单
    closeMenu() {

        // console.log("关闭弹出菜单, this", this)


        this.setState({openMenu: false});
    }

    // 打开弹出菜单
    openMenu(event) {

        // console.log("打开菜单..............................................................")

        let iframe = document.getElementsByTagName("iframe")[0];
        let iDoc = iframe.contentDocument;
        let sel = iDoc.getSelection();


        // console.log("sel...", sel)

        // 如果 note card 正在被展示，则隐藏
        if (this.state.openNoteCard) {
            this.setState({openNoteCard: false});
        }
        // 使弹出菜单更加灵活可控
        if (sel.isCollapsed) {
            this.state.openMenu && this.closeMenu();
            return;
        }

        if (this.props.disablePopup) return;


        // console.log("sel.getRangeAt(0)", sel.getRangeAt(0))

        let rect = this.props.epub.renderer.rangePosition(sel.getRangeAt(0));
        console.log(rect);

        let menu = document.getElementById("editor-toolbar");
        let x = event.clientX;
        let y = event.clientY;
        let width = parseInt(Util.getStyle(menu, "width"));
        let height = parseInt(Util.getStyle(menu, "height"));

        // TODO: 坐标计算
        let posX = rect.x + rect.width / 2 + width / 2;
        let posY = rect.y - height;
        posX = posX < 0 ? 0 : posX;
        posY = posY < 0 ? 0 : posY;

        [this.x, this.y] = [x, y];
        this.setState({openMenu: true, mPosX: posX, mPosY: posY});
    }

    // 关闭附注编辑框
    closeNoteCard() {
        this.setState({openNoteCard: false});
    }

    // 打开附注编辑框
    openNoteCard(x, y) {
        let noteCard = document.getElementById("note-card");
        let width = parseInt(Util.getStyle(noteCard, "width"));
        let height = parseInt(Util.getStyle(noteCard, "height"));

        console.log(width, height);

        let posX = x - (width / 2);
        let posY = y - height - 20;

        posX = posX < 0 ? 0 : posX;
        posY = posY < 0 ? 0 : posY;

        this.setState({openNoteCard: true, nPosX: posX, nPosY: posY});
    }

    // 设置当前正在被编辑的note
    setNote(note) {
        this.setState({note});
    }

    // 设置当前正在被渲染的note的key
    setKey(key) {
        this.key = key;
    }

    // 获取与本章节相关的 pen
    getPen() {
        // 注意点一
        // 为了每次切换章节时都有与当前文档相关联的 pen
        let iDoc = document.getElementsByTagName("iframe")[0].contentDocument;
        this.pen = window.rangy.createHighlighter(iDoc);
        let classes = ["color-0", "color-1", "color-2", "color-3"];

        classes.forEach((item) => {
            let config = {
                ignoreWhiteSpace: true,
                elementTagName: "span",
                elementProperties: {
                    onclick: (event) => {
                        let iDoc = document.getElementsByTagName("iframe")[0].contentDocument;
                        let sel = iDoc.getSelection();
                        if (!sel.isCollapsed) return;

                        let {gutter, padding} = this.props;
                        let key = event.currentTarget.dataset.key;
                        let note = this.props.getNote(key);
                        note || console.log("no note with key: " + key);
                        try {

                            console.log("noteeeeeee", note);

                            if (note.text) {
                                this.setNote(note);
                                let [x, y] = [event.clientX + gutter, event.clientY + padding];
                                this.openNoteCard(x, y);
                                event.stopPropagation();
                                if (this.state.openMenu) this.setState({openMenu: false}); // 修复bug
                            } else {

                                console.log("敲重点当前句子dfghj");
                                this.handleHighlight();


                            }
                        } catch (e) {
                            console.log("敲重点当前句子wsdcikj");
                            this.handleHighlight();

                        }
                    },
                },
                onElementCreate: (element) => {
                    element.dataset.key = this.key;
                },
            };
            let applier = window.rangy.createClassApplier(item, config);
            this.pen.addClassApplier(applier);
        });
    }

    // 渲染本章节的note
    renderNotes() {

        try { // TODO: 注意点二
            console.log("%c renderNotes has been called! ", "color: cyan; background: #333333");

            let {epub, getNotesByChapter} = this.props;
            let chapter = epub.renderer.currentChapter;

            if (!chapter) return; // 初次打开书籍，页面尚未渲染

            this.notes = getNotesByChapter(chapter.spinePos);
            let notes = this.notes;
            let iframe = document.getElementsByTagName("iframe")[0];
            let iWin = iframe.contentWindow || iframe.contentDocument.defaultView;

            let sel = window.rangy.getSelection(iframe);
            let serial = window.rangy.serializeSelection(sel, true);

            this.pen && this.pen.removeAllHighlights(); // 为了避免下次反序列化失败，必须先清除已有的高亮

            let classes = ["color-0", "color-1", "color-2", "color-3"];

            notes.forEach((note) => {
                this.key = note.key;
                try {
                    let temp = JSON.parse(note.range);
                    temp = [temp];
                    window.rangy.getSelection(iframe).restoreCharacterRanges(iframe.contentDocument, temp);
                } catch (e) {
                    console.warn("Exception has been caught when restore character ranges.");
                    return;
                }

                try {
                    this.pen.highlightSelection(classes[note.color]);
                } catch (e) {
                }
            });

            iWin.getSelection().empty(); // 清除文本选取
            this.state.openMenu && window.rangy.deserializeSelection(serial, null, iWin); // （为了选取文本后不被上一行代码清除掉）恢复原本的文本选取
        } catch (e) {

            console.log("渲染笔记错误", e)

        }


    }

    //渲染播放控件
    render_player() {


        this.setState({
            lan: Config.get().lan ? Config.get().lan : "zh",
            ctp: Config.get().ctp ? Config.get().ctp : 1,
            sleep: Config.get().sleep ? Config.get().sleep : 60,
            cuid: localStorage.getItem("user") ? localStorage.getItem("user") : "guest",
            tok: localStorage.getItem("tok") ? localStorage.getItem("tok") : "",
            vol: Config.get().vol ? Config.get().vol : 9,
            per: Config.get().per ? Config.get().per : 2,
            spd: Config.get().spd ? Config.get().spd : 6,
            pit: Config.get().pit ? Config.get().pit : 5,
        });


        console.log("渲染播放控件,渲染播放控件");
        if (this.state.tok) {
            let expireDate = localStorage.getItem("memberExpireDate", 0);
            expireDate = expireDate.substring(0, 10);
            expireDate = expireDate.replace(/\-/g, "/");
            expireDate = Date.parse(expireDate);
            if (expireDate >= Date.parse(new Date())) {
                console.log("render_player");
                let tts_pro = `http://tsn.baidu.com/text2audio?lan=${this.state.lan}&ctp=${this.state.ctp}&cuid=${this.state.cuid}&tok=${this.state.tok}&vol=${this.state.vol}&per=${this.state.per}&spd=${this.state.spd}&pit=${this.state.pit}&aue=3&tex=`;
                var src_config = this.state.tok ? tts_pro : "https://tts.baidu.com/text2audio?cuid=baike&lan=ZH&ctp=1&pdt=301&vol=9&rate=32&per=2&tex=";
                let no_space_text = "---";
                try {


                    console.log("current_index3274", this.state.current_index);

                    no_space_text = this.state.page_text[this.state.current_index];

                    // console.log("no_space_text5678oijhgdfghj", no_space_text);
                    if (no_space_text) {


                    } else {
                        no_space_text = "---";
                    }

                } catch (e) {

                    console.log("error", e);

                    no_space_text = "---";


                }


                // 两次urlencode

                console.log("---正在朗读：", no_space_text)

                let read_text = encodeURIComponent(encodeURIComponent(no_space_text));

                if (read_text == "undefined") {
                    console.log("UUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU");
                    read_text = "----------------";
                    let src = src_config + read_text;
                    let render_audio = document.getElementsByTagName("audio")[0];
                    render_audio.setAttribute("src", src);
                    // console.log("text_next", read_text)
                    console.log("src", src);
                    // console.log("render_audio", render_audio)

                } else {
                    console.log("DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD");
                    if (read_text === "") {
                        read_text = "啊啊啊";
                    }
                    let src = src_config + read_text;
                    let render_audio = document.getElementsByTagName("audio")[0];
                    render_audio.setAttribute("src", src);
                    // console.log("src", src)
                    // console.log("text_next", no_space_text)
                    // console.log("render_audio", render_audio)

                }

            } else {
                window.location.href = "#/apps/preaccount";
                alert("有声朗读为高级会员功能，请在个人中心续费会员，包月6元。");

            }


        } else {
            alert("请先登录");
            window.location.href = "#/apps/login";  // bug 待解决

        }


    }


    handleMarkDialogOpen() {


        this.setState({

            showStatus: !this.state.showStatus

        });

    }

    //启动连续播放，核心部件，开发ing
    cycle_playing = () => {
        window.This = this;
        let This = this;
        let audios = document.getElementsByTagName("audio");
        if (audios.length > 0) {
            audios[0].addEventListener("ended", function () {
                console.log("This.state.page_text.length", This.state.page_text.length);
                if (This.state.current_index >= This.state.page_text.length - 1) {
                    console.log("自动翻页");
                    let next_page = new Promise((resolve, reject) => {
                        window.This.next();

                        resolve("next_page");
                    });
                    next_page.then(() => {

                        setTimeout(() => {
                            console.log("翻页了");
                            // console.log(window.This.state.page_text)

                            window.This.play_audio();


                        }, 500);


                    });
                } else {
                    console.log("下一段0987456");
                    console.log("window.This.state.current_index 5678908765434567", window.This.state.current_index);
                    This.setState({
                        current_index: This.state.current_index + 1,
                        select_index: window.This.state.select_index + 1,
                        begin_flag: 1,
                        sentence_index: 0,
                        sentences: [],
                        sentences_length: 1
                    });
                    This.render_player();
                    var nextAudio = audios[0];
                    // tagName 属性返回元素的标签名。(大写)
                    if (nextAudio && nextAudio.tagName == "AUDIO") {
                        nextAudio.play();

                    }

                }


            }, false);
            audios[0].addEventListener("error", function () {

                console.log("播放错误00000000000000000000", This.state.current_index + 1, This.state.page_text.length);


                if (This.state.current_index + 1 >= This.state.page_text.length) {

                    let next_page = new Promise((resolve, reject) => {

                        window.This.next();

                        resolve("next_page");
                    });


                    let read_page = next_page.then(msg => {
                        console.log(`收到翻页消息: ${msg}`);
                        return {
                            then(resolve) {
                                setTimeout(() => {


                                    // console.log("window.This.page_text", window.This.page_text);

                                    window.This.play_audio();


                                    resolve("200毫秒后开始朗读");
                                }, 200);
                            }
                        };
                    });


                } else {
                    This.setState({
                        current_index: This.state.current_index + 1,
                        select_index: window.This.state.select_index + 1,
                        begin_flag: 1
                    });
                    This.render_player();
                    var nextAudio = audios[0];
                    // tagName 属性返回元素的标签名。(大写)
                    if (nextAudio && nextAudio.tagName == "AUDIO") {
                        nextAudio.play();

                    }
                }

            }, false);
            audios[0].addEventListener("play", function () {
                try {
                    try {
                        audios[0].playbackRate = 1.5;
                    } catch (e) {
                        console.log("error0912默认倍速失败：", e)
                    }
                    window.This.setState({
                        playing: true
                    });

                    //<editor-fold desc="分句开发ing">
                    try {
                        let current_sentence = window.This.state.page_text[window.This.state.current_index];
                        console.log("current_sentence", current_sentence);
                        let current_line = window.This.get_current_node_by_sentence(current_sentence)
                        console.log("current_line", current_line)
                        let current_line_text = current_line.nodeType !== 3 ? current_line.innerText : current_line.data;
                        console.log("current_line_text", current_line_text);
                        var current_line_range = document.createRange();
                        let start_offset = current_line_text.split(current_sentence)[0].length;
                        let end_offset = start_offset + current_sentence.length + 1;
                        console.log("start_offset", start_offset)
                        console.log("end_offset", end_offset)
                        try {
                            if (current_line_text.replace(/\s*/g, "") === current_sentence) {
                                console.log("直接选取节点")
                                current_line_range.selectNode(current_line);
                            } else {
                                if (current_line.childElementCount) {
                                    console.log("有------childElementCount")
                                    let node_index = get_sentence_index(current_line, current_sentence)
                                    let node_type = current_line.childNodes[node_index[0]].nodeType
                                    if (node_type === 3) {
                                        current_line_range.setStart(current_line.childNodes[node_index[0]], node_index[1]); // 设置range的“起点”
                                        try {
                                            current_line_range.setEnd(current_line.childNodes[node_index[0]], node_index[2] + 1);    // 设置range的“结束点”
                                        } catch (e) {
                                            console.log("qewerrwerewefa486", e)
                                            current_line_range.setEnd(current_line.childNodes[node_index[0]], node_index[2]);
                                        }

                                    } else {
                                        current_line_range.setStart(current_line.childNodes[node_index[0]], 0); // 设置range的“起点”
                                        current_line_range.setEnd(current_line.childNodes[node_index[0]], 1);    // 设置range的“结束点”
                                    }

                                } else {
                                    console.log("没有------childElementCount")
                                    try {
                                        console.log("current_line", current_line)
                                        console.log("current_line.innerHTML", current_line.innerHTML)
                                        if (current_line.innerHTML.includes("span")) {
                                            console.log("有span")
                                            let node_index = get_sentence_index(current_line, current_sentence)
                                            current_line_range.setStart(current_line.childNodes[node_index[0]], node_index[1]); // 设置range的“起点”
                                            try {
                                                current_line_range.setEnd(current_line.childNodes[node_index[0]], node_index[2] + 1);
                                            } catch (e) {
                                                console.log("dfghrwerewefa486", e)

                                                current_line_range.setEnd(current_line.childNodes[node_index[0]], node_index[2]);
                                            }
                                        } else {
                                            console.log("mei有span")
                                            let node_index = get_sentence_index(current_line, current_sentence)
                                            current_line_range.setStart(current_line, node_index[1]); // 设置range的“起点”
                                            try {
                                                current_line_range.setEnd(current_line, node_index[2] + 1);    // 设置range的“结束点”
                                            } catch (e) {

                                                console.log("dfghj67,q2dewefa486", e)

                                                current_line_range.setEnd(current_line, node_index[2]);

                                            }

                                        }
                                    } catch (e) {
                                        console.log("没有span")
                                        try {
                                            current_line_range.setStart(current_line.firstChild, start_offset); // 设置range的“起点”
                                            try {
                                                current_line_range.setEnd(current_line.firstChild, end_offset);    // 设置range的“结束点”
                                            } catch (e) {
                                                console.log("f6gew78", e)
                                                current_line_range.setEnd(current_line.firstChild, end_offset - 1);


                                            }
                                        } catch (e) {

                                            console.log("r2e23e", e)


                                        }


                                    }


                                }
                            }


                        } catch (e) {
                            console.log("select range error", e)
                        }


                        try {
                            let iframe = document.getElementsByTagName("iframe")[0];
                            let iDoc = iframe.contentDocument;
                            iDoc.getSelection().removeAllRanges();
                            iDoc.getSelection().addRange(current_line_range);
                            // let sel = iDoc.getSelection();
                            // // console.log("sel...", sel);
                            // window.This.pen.highlightSelection("color-1");
                            // iDoc.getSelection().empty(); // 清空文本选取
                            // // let rect = This.props.epub.renderer.rangePosition(sel.getRangeAt(0));
                            // // console.log("rectrectrectrect", rect);

                        } catch (err) {
                            console.log("错误详情：", err);
                        }
                    } catch
                        (e) {
                        console.log("select range失败", e);
                    }
                    //</editor-fold>
                } catch (e) {
                    console.log("error68823:播放失败", e)
                }


            }, false);


        }


    };

// 翻页：上一页
    prev() {
        this.props.epub.prevPage();
        this.closeMenu();
        this.closeNoteCard();
    }

// 翻页：下一页
    next() {
        this.props.epub.nextPage();
        this.closeMenu();
        this.closeNoteCard();
    }


    render() {


        const {
            epub,
            book,
            theme,
            colors,
            column,
            gutter,
            padding,
            addNote,
            updateNote,
            deleteNote,
            classes
        } = this.props;


        let playIcon = this.state.playing ? <PauseIcon className={classes.arrow}/> :
            <PlayArrowIcon className={classes.arrow}/>;


        let style = {
            left: gutter,
            right: gutter,
            top: padding,
            bottom: padding,
        };

        epub.renderer.forceSingle(column === 1); // TODO: 在合适的地方触发重新渲染书籍
        console.log("%c render view-area. ", "color: orange; background: #333333");
        this.renderNotes();

        return (
            <div id="view-area">
                <div id="audios">
                    <audio id="first_audio" className="add_audio"
                           src="https://tts.baidu.com/text2audio?cuid=baike&lan=ZH&ctp=1&pdt=301&vol=9&rate=32&per=3&tex=_____"></audio>
                </div>
                <div id="page-area" className={`${classes.pageArea} screenPageArea`} style={style}/>
                {/*<iframe src="https://naotu.baidu.com/home" frameborder="0"  width="50%"  height="100%"  ></iframe>*/}
                <div id="prev" onClick={this.prev} className={classes.prev}>
                    <IconButton className={classes.button}>
                        <LeftArrow className={classes.arrow}/>
                    </IconButton>
                </div>
                <div id="mask" className={classes.mask}>
                </div>
                <div id="play" className={classes.playAudio}>

                    <IconButton onClick={this.prevSentence.bind(this)} className={classes.button}>
                        <SkipPreviousIcon className={classes.arrow}/>
                    </IconButton>


                    <IconButton onClick={this.togglePlay.bind(this)} className={classes.button}>
                        {playIcon}
                    </IconButton>
                    <IconButton onClick={this.handleHighlight.bind(this)} className={classes.button}>
                        <BorderColorIcon className={classes.arrow}/>
                    </IconButton>
                    <IconButton onClick={this.nextSentence.bind(this)} className={classes.button}>
                        <SkipNextIcon className={classes.arrow}/>
                    </IconButton>

                    <IconButton onClick={this.next} className={classes.button}>
                        <RightArrow className={classes.arrow}/>
                    </IconButton>

                    {/*<IconButton onClick={this.handleHighlightImportant.bind(this)} className={classes.button}>*/}
                    {/*<ViewHeadlineIcon className={classes.arrow}/>*/}
                    {/*</IconButton>*/}


                </div>
                {/*<div id="next" onClick={this.next} className={classes.next}>*/}
                {/*<IconButton className={classes.button}>*/}
                {/*<RightArrow className={classes.arrow}/>*/}
                {/*</IconButton>*/}
                {/*</div>*/}
                {/*选择颜色的菜单*/}
                <EditorToolbar open={this.state.openMenu}
                               posX={this.state.mPosX}
                               posY={this.state.mPosY}
                               closeMenu={this.closeMenu}
                               openNoteCard={this.openNoteCard}
                               addNote={addNote}
                               setKey={this.setKey}
                               book={book}
                               epub={epub}
                               theme={theme}
                               colors={colors}
                               pen={this.pen}
                />
                <NoteCard open={this.state.openNoteCard}
                          posX={this.state.nPosX}
                          posY={this.state.nPosY}
                          note={this.state.note}
                          theme={theme}
                          deleteNote={deleteNote}
                          updateNote={updateNote}
                          closeNoteCard={this.closeNoteCard}
                />
                <MarkDialog open={this.state.showStatus}
                            progress={this.state.progress}
                            handleMarkDialogOpen={this.handleMarkDialogOpen.bind(this)}
                />
            </div>
        );
    }
}

ViewArea.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewArea);
