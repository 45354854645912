import React from "react";
import {grey} from "@material-ui/core/colors";
import IdeaListInfo from "./Ideahub/components/accounts/IdeaListInfo";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {WechatPay} from "../pages/Ideahub/actions/auth";
import PageBase from "../components/PageBase"
import BookListInfo from "./Ideahub/components/accounts/BookListInfo"

const styles = theme => ({
    toggleDiv: {
        marginTop: 20,
        marginBottom: 5
    },
    toggleLabel: {
        color: grey[400],
        fontWeight: 100
    },
    buttons: {
        marginTop: 30,
        float: "right"
    },
    saveButton: {
        marginLeft: 5
    },

    button_pay: {
        marginRight: 5
    },

    button: {
        paddingTop: 6,
        paddingBottom: 6,
    },

});

class IdeaList extends React.Component {

    state = {
        open: false
    };

    componentDidMount() {
    }

    render() {

        const classes = this.props.classes;


        return (


            <IdeaListInfo/>

            // <PageBase>
            //     <IdeaListInfo/>
            // </PageBase>

        );
    }


}


IdeaList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,

});


export default withStyles(styles)(connect(mapStateToProps, {
    WechatPay
})(IdeaList));
