import React, {Component} from "react";
import {connect} from "react-redux";
import MaterialTable from "material-table";
import {getProjects} from "../../actions/projects";
import {getIdeas, addIdea, updateIdea, doneIdea, deleteIdea} from "../../actions/ideas";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {forwardRef} from "react";
import Switch from "@material-ui/core/Switch";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from '@material-ui/core/Checkbox';

var type_dic = {1: "重要&紧急", 2: "紧急&不重要", 4: "不重要不紧急", 5: "重要&不紧急"}; // 定义一个字典


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};


class App extends Component {


    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleRename = this.handleRename.bind(this);
        this.getProjectName = this.getProjectName.bind(this);

    }

    state = {
        username: "",
        password: "",
        rows: [],
        ideas: {},
        projects: []

    };


    handleClick = (item) => {

        console.log("handleClick");
        item.is_done = item.is_done ? 0 : 1;
        this.props.updateIdea(item, item.id, item.color);
    };


    getProjectName = (id) => {


        console.log("this.props.projects", this.props.projects)
        console.log("id", id)

        let project = this.props.projects.filter(item => item.id !== id)
        console.log("project", project)

        let res = ""


        try {

            res = project[0].name
        } catch (e) {

            res = "789"
        }


        return res


    };


    handleChange = (newName) => e => {

        console.log("handleChange", newName);
        console.log(e.target.value);

    };


    handleRename = (item) => e => {
        let newName = e.target.value;
        console.log("handleChange");
        item.text = newName ? newName : item.text;
        this.props.updateIdea(item.id, item);

    };

    componentWillUnmount() {


    };


    componentDidMount() {


        this.props.getProjects();
        this.props.getIdeas("", "", "", 1, 0, 0, "")


    }


    componentDidUpdate() {


        if (this.state.ideas !== this.props.ideas) {

            const rows1 = this.props.ideas ?

                this.props.ideas.map(item => {

                    console.log("777888");

                    return {
                        // name: item.name,
                        name: <InputBase
                            // className={classes.margin}
                            defaultValue={item.text}
                            inputProps={{"aria-label": "naked"}}
                            // onChange={this.handleChange}
                            onBlur={this.handleRename(item)}

                        />,

                        projects: this.getProjectName(item.project),
                        types: item.color ? type_dic[item.color] : "未分级",
                        done:


                            <Checkbox
                                onClick={this.handleClick.bind(this, item)}
                                value={item.id}
                                checked={!!item.is_done}
                                color="primary"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                            />


                    }
                        ;


                }) : [];


            this.setState({

                ideas: this.props.ideas,
                rows: rows1


            });
        }
        if (this.state.projects !== this.props.projects) {
            this.setState({
                projects: this.props.projects,
            });
        }

    }


    render() {


        const tableRef = React.createRef();

        return (
            <div style={{maxWidth: "100%"}}>
                <MaterialTable

                    tableRef={tableRef}
                    icons={tableIcons}
                    options={{
                        search: false,
                        paginationType: "normal",
                        pageSize: 5,
                        tableLayout: "fixed"
                        // showTitle:false
                    }}

                    columns={[
                        {title: "Items", field: "name", miniWidth: 1, maxWidth: 20},
                        {title: "Projects", field: "projects", type: "numeric", miniWidth: 1, maxWidth: 20},
                        {title: "Types", field: "types", miniWidth: 1, maxWidth: 20},
                        {
                            title: "Done",
                            field: "done",
                            miniWidth: 1, maxWidth: 20
                        },
                    ]}
                    data={this.state.rows}
                    title="Ideas"
                />
            </div>
        );
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    ideas: state.ideas.ideas,
    projects: state.projects.projects
});

export default connect(
    mapStateToProps,
    {getIdeas, updateIdea, getProjects}
)(App);
