import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import React, {Component} from "react";
import {logout} from "../pages/Ideahub/actions/auth";
import SearchIcon from "@material-ui/icons/Search";
import Search from "./Search";
import {fade} from "@material-ui/core/styles/colorManipulator";

const SearchStyles = theme => {
  return {

    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25)
      },
      marginRight: theme.spacing(30),
      marginLeft: 0,
      width: "50%",
      minWidth: 50,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto"
      }
    },
    searchIcon: {
      width: theme.spacing(9),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: {
      color: "inherit",
      width: "100%"
    },
  };
};


export class SearchHiden extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired
  };


  open(e) {

    e.preventDefault();


    try {
      const shell = window.electron.shell;
      let href = this.props.auth.link;
      shell.openExternal(href);
    } catch (e) {
    }
  }


  render() {
    const {isAuthenticated, user, version, link} = this.props.auth;
    const current_version = localStorage.getItem("version", "0");

    let level = 0;
    try {
      level = user.memberLevel;
    } catch (e) {

    }

    let {classes} = this.props;

    const searchBar = (

      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon style={{color: "white", opacity: .6}}/>
        </div>
        <Search/>
      </div>);

    const appUpgrade = (<a  href={link} onClick={this.open.bind(this)} target="_blank"><p id="app_version">更新版本</p></a>);


    return (
      <div>
        {parseInt(level) % 2 === 1 ? searchBar : ""}

        {/*{parseFloat(version) > parseFloat(current_version) ? appUpgrade : ""}*/}
      </div>

    );
  }
}


SearchHiden.propTypes = {
  navDrawerOpen: PropTypes.bool,
  menus: PropTypes.array,
  username: PropTypes.string,
  classes: PropTypes.object,
  theme: PropTypes.object,
  handleChangeNavDrawer: PropTypes.func
};


const mapStateToProps = state => ({
  auth: state.auth,
});


export default withStyles(SearchStyles, {withTheme: true})(connect(mapStateToProps, {logout})(SearchHiden));
