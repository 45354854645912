import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  QR_SUCCESS,
  UPDATE_SUCCESS,
  GET_STS,
  GET_VERSION,
  VERSION_GOT,
  VERSION_ERROR,
  PAY_DONE,
  PAY_ERROR

} from "../actions/types";


const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  qr_url: "",
  pay_code_url: "",
  sts: null,
  version: 0.9,
  link: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case USER_LOADED:

      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload
      };
    case GET_STS:


      console.log("action.payload", action.payload);

      return {
        ...state,
        sts: action.payload
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("tok", action.payload.tok);
      localStorage.setItem("user", action.payload.user.nickName);
      localStorage.setItem("memberExpireDate", action.payload.user.memberExpireDate);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false
      };

    case AUTH_ERROR:
    case LOGIN_FAIL:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false
      };
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      localStorage.removeItem("token");
      localStorage.removeItem("tok");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false
      };
    case QR_SUCCESS:


      return {
        ...state,
        qr_url: action.payload["imageurl"],


      };

    case PAY_DONE:


      return {
        ...state,
        pay_code_url: action.payload["code_url"],


      };
    case PAY_ERROR:
      return {
        ...state,
        pay_code_url: ""

      };
    case VERSION_GOT:

      localStorage.setItem("version2", action.payload.version);
      localStorage.setItem("mac_link", action.payload.mac_link);
      localStorage.setItem("win_link", action.payload.win_link);

      return {
        ...state,
        version: action.payload.version,
        link: action.payload.link

      };
    default:
      return state;
  }
}
