import React from "react";
import PropTypes from "prop-types";

import Drawer from "@material-ui/core/Drawer";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import {availableThemes} from "../theme";

const style = () => ({
    BackdropRoot: {
        backgroundColor: "transparent"
    },
    wechat: {

        width: "100%"

    },

    title: {

        margin: "0 auto"

        // marginLeft: 20

    },
    selectThemeBtn: {
        backgroundColor: "rgb(250, 250, 250)",
        color: "rgba(0, 0, 0, 0.87)",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        height: "40px",
        margin: "16px 0",
        cursor: "pointer",
        "&.hover": {
            boxShadow: "inset 0 0 0 20px rgba(0,0,0,.24)"
        }
    }
});


let wechat = require('../images/wechat.jpg')

const RightDrawer = ({rightDrawerOpen, handleChangeRightDrawer, classes}) => (
    <Drawer
        variant="temporary"
        anchor={"right"}
        open={rightDrawerOpen}
        onClose={handleChangeRightDrawer}
        // use transparent background
        ModalProps={{
            BackdropProps: {
                classes: {root: classes.BackdropRoot}
            }
        }}
    >
        <div style={{width: 240, padding: 16}}>
            <h6 style={{paddingBottom: "10px"}}>联系我们：</h6>
            <p style={{paddingBottom: "10px"}}>欢迎对闪电读书这款产品有问题反馈、优化建议、功能需求、合作意向的朋友联系我们。</p>

            <label> <b>电话：</b> </label><a href="tel:15395051972">15395051972</a>
            <br/>
            <label> <b>微信：</b></label> <img className={classes.wechat} src={wechat} alt="微信号"/>
            <br/>
            <label> <b>邮箱：</b> </label><a href="mailto:cs01@newonet.com">cs01@newonet.com</a>
            <br/>

            <br/>
            <h6 className={classes.title} style={{padding: "0 auto "}}>志愿者招募：</h6>
            <p>只要你对产品、运营、APP开发等任意一方向感兴趣、有想法，请立即联系我们，一起共创未来。</p>


        </div>
    </Drawer>
);

RightDrawer.propTypes = {
    rightDrawerOpen: PropTypes.bool,
    handleChangeRightDrawer: PropTypes.func,
    handleChangeTheme: PropTypes.func,
    classes: PropTypes.object
};

export default withStyles(style)(RightDrawer);
