import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {grey, orange} from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import ArrowIcon from '@material-ui/icons/ArrowForward';

const styles = theme => ({
    item: {
        width: '90%',
        // height: 80,
        padding: 10,
        margin: '15px auto',
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        boxShadow: theme.shadows[1],
        transition: '.3s',
        '&:hover': {
            boxShadow: theme.shadows[6],
        },

        cursor: "pointer"


    },
    noteContent: {
        width: '70%',
        flexGrow: '1',
    },
    input: {
        border: 'none',
        outline: 'none',
        color: grey[600],
        width: '100%',
        backgroundColor: 'transparent',
        borderBottom: '1px dotted black',
    },
    icon: {
        width: 18,
        height: 18,
        color: grey[700],
        display: 'block',
        transition: '.3s',
        '&:hover': {
            cursor: 'pointer',
            color: orange[700],
        },
    },
});

class SearchResult extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            note: this.props.note.excerpt, // note的内容
        };

        this.handleJump = this.handleJump.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleJump() {
        this.props.handleJump(this.props.note.cfi);
    }

    handleBlur(event) {
        let note = this.props.note;
        note.note = event.target.value;
        this.props.updateNote(note);
        this.setState({editable: false});
        console.log('note has been updated!');
    }


    highLightKeyword(text) {

        console.log("this.state.keyword", this.props.keyword)

        let kwhtml = text.replace(this.props.keyword, `<b>${this.props.keyword}</b>`)

        return <p dangerouslySetInnerHTML={{__html: kwhtml}}/>

    }

    render() {
        const {note, colors, classes} = this.props;
        let style = {
            backgroundColor: colors[1],
        };

        let text = (
            <div color={'primary'}
                 onClick={this.handleJump}
            >
                {this.highLightKeyword(this.state.note)}
            </div>
        );


        return (
            <li>
                <div style={style} className={classes.item}>
                    <div className={classes.noteContent}>
                        <div>
                            {text}
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

SearchResult.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchResult);
