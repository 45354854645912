import axios from "axios";
import {createMessage, returnErrors} from "./messages";
import {tokenConfig} from "./auth";
import {ip} from "./env";
import {
  GET_PROJECTS,
  POST_PROJECTS,
  POST_BOOK_TYPE,
  GET_BOOK_TYPES,
  UPDATE_BOOK_TYPES,
  UPDATE_PROJECTS
} from "./types";


// GET PROJECTS


export const getProjects = () => (dispatch, getState) => {
  axios
    .get(ip + "/api/projects/", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_PROJECTS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err, err))
    );
};

export const getBookTypes = () => (dispatch, getState) => {
  axios
    .get(ip + "/api/booktypes/", tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_BOOK_TYPES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err, err))
    );
};


// UPDATE BOOKType delete status
export const updateBookTypes = (id, bookTye) => (dispatch, getState) => {

  // let bookTye = {};
  // bookTye.delete_status = del;
  // bookTye.name = "01_56789065678";


  axios
    .put(ip + `/api/booktypes/${id}/`, bookTye, tokenConfig(getState))
    .then(res => {
      dispatch(createMessage({bookListUpdate: "bookListUpdate"}));
      dispatch({
        type: UPDATE_BOOK_TYPES,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};



// UPDATE BOOKType delete status
export const updateProject = (id, project) => (dispatch, getState) => {



  axios
    .put(ip + `/api/projects/${id}/`, project, tokenConfig(getState))
    .then(res => {
      dispatch(createMessage({UPDATE_PROJECTS: "UPDATE_PROJECTS"}));
      dispatch({
        type: UPDATE_PROJECTS,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};


export const addProject = (project) => (dispatch, getState) => {


  let data = new FormData();

  data.append("name", project.name);
  data.append("description", project.description);
  data.append("isParent", project.isParent);
  data.append("Parent", project.Parent);


  axios
    .post(ip + "/api/projects/", data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: POST_PROJECTS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err, err))
    );
};


export const addBookType = (type) => (dispatch, getState) => {


  let data = new FormData();

  data.append("name", type.name);
  data.append("description", "0");
  // data.append('isParent', type.isParent)
  // data.append('Parent', type.Parent)


  axios
    .post(ip + "/api/booktypes/", data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: POST_BOOK_TYPE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err, err))
    );
};
