import React from "react";
import {grey} from "@material-ui/core/colors";
import PageBase from "../components/PageBase";
import Login from "./Ideahub/components/accounts/Login";
// import Login from './Ideahub/components/App'

const LoginPage2 = () => {
    const styles = {
        toggleDiv: {
            marginTop: 20,
            marginBottom: 5
        },
        toggleLabel: {
            color: grey[400],
            fontWeight: 100
        },
        buttons: {
            marginTop: 30,
            float: "right"
        },
        saveButton: {
            marginLeft: 5
        }
    };

    return (


        <PageBase title="Form Page" navigation="Application / Login Page">
            <Login/>
        </PageBase>
    );
};

export default LoginPage2;
