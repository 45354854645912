import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import "./ViewArea.css";
import DBUtil from "../service/DBUtil";


let styles = {


  touch: {
    position: "fixed",
    left: 10,
    right: 10,
    top: 10,
    background: "#777",
    height: 600
  },

  index: {

    padding: "2",

    marginRight: "10px",
    background: "#666",
    borderRadius: "6px"
  },


  viewArea: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: 0,
    // background: "#000"
    background: "#888666"
  },

  videoPlay: {

    // marginBottom: -7
    flex: 1,
    margin: "0 auto",


  },
  pageArea: {
    marginTop: 0
  },
  media: {
    // padding: "50px 100px",
    display: "flex",
    width: "100%",
    // background: "#777",
    position: "relative"
  },
  input: {
    // display:"none",
    color: "#d2dae2",
    // display: "inline-block",
    // background: "#000",
    width: "100%",
    marginBottom: 0,
    opacity: 0
  },
  video: {
    flex: 1,
    // display: "inline-block",
    // marginLeft: "300px",
    // background: "#383838",
    width: "100%",
    // height: "500px",
    marginTop: 0,
    paddingBottom: 0,
    display: "flex"


  }

};


function zfill(num, size) {
  var s = "000000000" + num;
  return s.substr(s.length - size);
}

class ViewArea extends React.Component {

  constructor(props) {
    super(props);
    this.handleJump = this.handleJump.bind(this);
    this.sortView = this.sortView.bind(this);
    this.sortComplete = this.sortComplete.bind(this);
    this.sortDigg = this.sortDigg.bind(this);





    this.state = {
      sortView: 0,
      sortComp: 0,
      sortDigg: 0
    };
    this.key = ""; // 当前正在渲染的key
  }


  handleJump(index) {
    this.props.goMedia2(index);
  }


  sortView() {

    this.props.sortMedias("v", this.state.sortView);
    this.setState({
      sortView: !this.state.sortView
    });

  }





  sortComplete() {

    this.props.sortMedias("c", this.state.sortComp);
    this.setState({
      sortComp: !this.state.sortComp
    });

  }


  sortDigg() {

    this.props.sortMedias("d", this.state.sortDigg);
    this.setState({
      sortDigg: !this.state.sortDigg
    });

  }


  render() {

    function urlencode(str) {
      str = (str + "").toString();

      return encodeURIComponent(str).replace(/!/g, "%21").replace(/'/g, "%27").replace(/\(/g, "%28").replace(/\)/g, "%29").replace(/\*/g, "%2A").replace(/%20/g, "+");
    }


    console.log("window_width", window.innerWidth - 236 - 236);
    console.log("window_height", window.innerHeight - 80);
    let gap_time = this.props.gap_time;
    let speed = this.props.current_speed.toFixed(1);
    let viewTimes = 0;
    let completeCounts = 0;
    let diggTimes = 0;

    let window_width = window.innerWidth - 236 - 236;
    let window_height = window.innerHeight - 80;

    let isV = 0.1;

    try {

      viewTimes = this.props.medias[this.props.current_index].viewTimes;
      completeCounts = this.props.medias[this.props.current_index].completeCounts;
      diggTimes = this.props.medias[this.props.current_index].diggTimes;
      let video22 = document.querySelector("video");
      video22.addEventListener("canplay", function () {
        this.width22 = this.videoWidth;
        this.height22 = this.videoHeight;
        console.log("视频宽高：", this.width22, this.height22);

        isV = this.height22 / this.width22;


      });
    } catch (e) {
    }


    const {classes, book, medias} = this.props;

    let media_src = "https://media-ideahub.oss-cn-beijing.aliyuncs.com/static/intro.mp4";
    let name = "";
    let current_index = 0;
    let total_counts = 0;


    try {
      current_index = this.props.current_index;
      total_counts = this.props.medias.length;
      media_src = book.author + book.name + "/" + urlencode(this.props.medias[this.props.current_index].name);


      name = this.props.medias[this.props.current_index].name;
      // name = name.split("/")[1];
    } catch (e) {

      console.log("etg09847800jhbvghn", e);
    }

    return (

      <div className="main">
        <div className="navLeft">
          <div className="topButton">
            <button
              onClick={this.sortView}
              id={"viewTimes"}>{`浏览${this.state.sortView ? "↑" : "↓"}`}</button>
            <button

              onClick={this.sortComplete}

              id={"completeTimes"}>{`完播${this.state.sortComp ? "↑" : "↓"}`}</button>
            <button
              onClick={this.sortDigg}

              id={"diggTimes"}>{`点赞${this.state.sortDigg ? "↑" : "↓"}`}</button>
          </div>
          <div className="folderList">
            <div className="aa aaa">播放列表</div>
            {medias.map((title, index) => <div
              onClick={() => this.handleJump(index)}
              className="aa"><span
              className={classes.index}>{zfill(index, (medias.length).toString().length)}</span>{title.name}</div>)}

          </div>
        </div>
        <div className="main_show_area">
          <div className="main_top">{`${current_index + 1}/${total_counts}`}</div>
          <div className="videoPlayer">

            <div id="video_div" className={classes.video}>
              <video
                className={classes.videoPlay}

                width={isV > window_height / window_width ? "auto" : window_width}
                height={isV > window_height / window_width ? window_height : "auto"}
                id='video'
                src={media_src}
                autoPlay="autoplay"
                // controls
              >

              </video>


            </div>


          </div>
          <div className="videoMask" id="videoMask"></div>
          <div className="videoControl">
            <button
              className={"control_button"}
              onClick={this.props.speedDown}
              id={"viewTimes"}>{`减速-`}
            </button>
            <button
              className={"control_button"}
              onClick={this.props.prev_media}
              id={"viewTimes"}>{`上一个`}
            </button>
            <button
              className={"control_button"}
              onClick={this.props.prevSeconds}
              id={"viewTimes"}>{`快退`}
            </button>
            <button
              className={"control_button"}
              onClick={this.props.nextSeconds}
              id={"viewTimes"}>{`快进`}
            </button>
            <button
              className={"control_button"}
              onClick={this.props.next_media}
              id={"viewTimes"}>{`下一个`}
            </button>
            <button
              className={"control_button"}
              onClick={this.props.speedUp}
              id={"viewTimes"}>{`加速+`}
            </button>
            <button
              className={"control_button"}
              onClick={this.props.diggUp}
              id={"viewTimes"}>{`点赞`}
            </button>
            <button
              className={"control_button"}
              onClick={this.props.toggleControl}
              id={"viewTimes"}>{`进度`}
            </button>

          </div>
        </div>
        <div className="navRight">
          <div className={`${classes.info} infoText`}>
            <div className={`text_title`}>{name}</div>
            <div>间隔时间: <span>{gap_time}</span>秒</div>
            <div>播放速度：<span>{speed}</span></div>
            <div>点赞数量：<span>{diggTimes}</span></div>
            <div>浏览次数：<span>{viewTimes}</span></div>
            <div>完播次数：<span>{completeCounts}</span></div>
            <div>笔记备注：<span>{speed}</span></div>
            <div>标签：<span>{speed}</span></div>

          </div>
          <div className={classes.input}>
            <label htmlFor="text666">控制区：</label>
            <input id="text666" type="text" placeholder="键盘控制区域"/>

          </div>
        </div>
      </div>


    );
  }
}

ViewArea.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewArea);
