import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import InputBase from "@material-ui/core/InputBase";
import {withStyles} from "@material-ui/core/styles";
import {getbooks} from "../pages/Ideahub/actions/books";

const styles = theme => ({

    inputRoot: {
        color: "inherit",
        width: "100%",

    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(10),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 200
        }
    },

});

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            keyword: ""
        };
    }

    onChange = e => this.setState({[e.target.name]: e.target.value});

    onEnter = e => {

        if (e.keyCode === 13) {

            this.onSearch();

        }

    };

    onSearch = () => {


        localStorage.setItem("searchTime", new Date().getTime());


        if (this.state.keyword.length > 0) {

            this.props.getbooks(this.state.keyword);
        }

        if (this.state.keyword !== "") {
            this.setState({
                keyword: ""
            });
        }
    };


    render() {
        const {classes} = this.props;


        return (
            <InputBase
                onBlur={this.onSearch}
                name="keyword"
                onChange={this.onChange}
                onKeyUp={this.onEnter}
                value={this.state.keyword}
                placeholder="图书资源"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                }}
            />
        );
    }
}

Search.propTypes = {
    styles: PropTypes.object,
    classes: PropTypes.object,
};


const mapStateToProps = state => ({
    books: state.books.books,
});


Search.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, {getbooks})(Search));

