import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

class EditBookDialog extends React.Component {

  constructor(props) {
    super(props);

    this.handleDeleteBookConfirm = this.handleDeleteBookConfirm.bind(this);
  }

  handleDeleteBookConfirm() {

    let book = this.props.book;
    this.props.handleDeleteBookConfirm(book);
    this.props.handleCloseDeleteDialog();


  }


  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>Delete Book</DialogTitle>
        <DialogContent>
          <p>⚠️注意备份书籍和笔记，是否确定删除？</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.handleCloseDeleteDialog()} color="primary">
            取消
          </Button>
          <Button onClick={this.handleDeleteBookConfirm} color="secondary">
            确认删除
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditBookDialog;
