import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class StatusDialog extends React.Component {

  constructor(props) {
    super(props);

  }


  render() {

    let open = this.props.open == undefined ? false : this.props.open;
    let progress = this.props.progress;

    //
    // alert(open)


    return (
      <Dialog open={this.props.open}>
        <DialogContent>
          <p>正在飞鸽传书... <span>{progress}</span></p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.handleStatusDialogOpen()} color="primary">
            中止
          </Button>
        </DialogActions>

      </Dialog>
    );
  }
}

export default StatusDialog;
