import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getProjects} from "../../actions/projects";
import {updateIdea, getIdeas} from "../../actions/ideas";
import {withStyles} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import AddProject from "../../../../components/AddProject";

function sortNumber(a, b) {
  return parseInt(a.name) - parseInt(b.name);
}


const styles = theme => ({

  work_area: {
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
    minHeight: 100,
    backgroundColor: "#bdc3c7",
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20
  },

  button: {
    marginLeft: 20,
    marginTop: 6,
    marginBottom: 6,
    // borderRadius: 5

  },

  addProject: {


    margin: 0,
    marginLeft: 20,
    padding: 0,
    marginBottom: -5,
    fontSize: 21,
    transition: "color .3s",
    "&:hover": {
      // color: "#e67e22",
      border: " solid 2px #e67e22",
      borderRadius: 4
    },

  }

});

export class Projects extends React.Component {

  constructor() {

    super();

    this.state = {
      activate_idea: 0,
      fatherProject: [],
      open: false,
      work_type: "",
      // project: {
      //     name: "",
      //     id: 1,
      //     isParent: ""
      // }
    };

    this.test = function () {
      console.log("clicked");
    };


    this.update_projct = (project_idea, p_id, project_name) => {

      console.log("project_name", project_name);

      if (this.state.work_type !== "项目任务") {
        project_idea.project = p_id;
        console.log(project_idea.id);
        this.props.updateIdea(project_idea, project_idea.id, "c", p_id);
      } else {


        this.props.getIdeas(project_name, p_id);

        // 获取项目任务idea


      }


    };
  }


  static propTypes = {
    ideas: PropTypes.object.isRequired,
    projects: PropTypes.object.isRequired,
    getProjects: PropTypes.func.isRequired,
    activate_idea: PropTypes.object.isRequired,
  };


  toggleAddProject() {

    this.setState({

      open: !this.state.open

    });


  };


  componentWillUnmount() {


  }

  componentDidMount() {

    this.props.getProjects();


  }

  componentDidUpdate() {

    if (this.state.activate_idea != this.props.activate_idea) {
      this.setState({
        activate_idea: this.props.activate_idea
      });
    }
    if (this.state.work_type != this.props.work_type) {


      this.setState({
        work_type: this.props.work_type
      });
    }
  }


  render() {
    const {classes} = this.props;


    let ideas = this.props.ideas;
    let texts = [];
    let colors = [];
    let ids = [];
    let projects = [];

    ideas.map(
      item => {
        texts.push(item.text);
        colors.push(item.color);
        ids.push(item.id);
        projects.push(item.project);

      }
    );


    let project_idea = {};
    project_idea.id = ids[this.state.activate_idea];
    project_idea.color = colors[this.state.activate_idea];


    return (
      <div className={classes.work_area}>

        <div>
          {
            this.props.projects.sort(sortNumber).map((project, index) => {

              if (project.delete_status) {

              } else {

                return <button key={index} className={classes.button}
                               onClick={this.update_projct.bind(this, project_idea, project.id, project.name)}>{project.name}</button>;


              }
            })
          }

          <span title="创建新的项目">
                        <AddIcon onClick={this.toggleAddProject.bind(this)} className={classes.addProject}/>
                    </span>

        </div>


        <AddProject open={this.state.open}

                    options={this.options}

                    toggleAddProject={this.toggleAddProject.bind(this)}/>

      </div>

    );
  }


}


// export default projects;


const mapStateToProps = state => ({
  projects: state.projects.projects,
  ideas: state.ideas.ideas,
  activate_idea: state.ideas.activate_idea,
  work_type: state.ideas.work_type,
  project: state.ideas.project
});

// export default connect(
//     mapStateToProps,
//     {getProjects, updateIdea, getIdeas}
// )(Projects);


Projects.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, {getProjects, updateIdea, getIdeas})(Projects));
