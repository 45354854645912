import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import React, {Component} from "react";
import {addBookType} from "../pages/Ideahub/actions/projects";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {grey} from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => {
    return {

        large: {
            width: 60,
            height: 60,
        },
        toggleDiv: {
            marginTop: 20,
            marginBottom: 5
        },
        toggleLabel: {
            color: grey[400],
            fontWeight: 100
        },
        buttons: {
            marginTop: 30,
            float: "right"
        },
        saveButton: {
            marginLeft: 15
        }

    };
};


export class AddProject extends Component {


    state = {
        name: "",
        description: "",
        isParent: false,
        Parent: ""


    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired
    };


    handleClose() {
        this.props.toggleAddTypeOpen();
    }

    onChange = e => {


        console.log(e.target.name, e.target.value)
        this.setState({[e.target.name]: e.target.value})

    };
    onChangeIsParent = e => {


        console.log(e.target.name, e.target.value)
        this.setState({

            isParent: !this.state.isParent


        })

    };


    onSubmit() {
        const {name, description, isParent, Parent} = this.state;
        const project = {name, description, isParent, Parent};

        console.log("add project....................")
        console.log(project)

        this.props.addBookType(project);
        this.setState({
            name: "",
            description: "",
            isParent: false,
            Parent: ""
        });
        this.props.toggleAddTypeOpen();

    };

    render() {
        const {isAuthenticated, user} = this.props.auth;
        let {classes} = this.props;

        const {name, description, isParent, Parent} = this.state;


        let options = this.props.projects ? this.props.projects.map(item => {

            if (item.isParent) {

                let option = {}
                option[item.name] = item.id

                return [item.name, item.id]
            }

        }) : []


        return (
            <div>


                <Dialog open={this.props.open} onClose={this.handleClose} className={classes.root}>


                    <DialogTitle>创建新的图书类别
                    </DialogTitle>
                    <DialogContent>
                        <form>
                            <TextField
                                label="名称(必填)"
                                name="name"
                                fullWidth={true}
                                value={name}
                                onChange={this.onChange}

                            />
                            <Divider/>


                        </form>

                    </DialogContent>
                    <DialogActions color="primary">
                        <div className={classes.buttons}>
                            <Button onClick={this.handleClose.bind(this)} variant="contained">取消</Button>

                            <Button
                                className={classes.saveButton}
                                variant="contained"
                                type="submit"
                                color="primary"
                                onClick={this.onSubmit.bind(this)}


                            >
                                保存
                            </Button>

                        </div>


                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}


AddProject.propTypes = {
    navDrawerOpen: PropTypes.bool,
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    auth: state.auth,
    projects: state.projects.projects,
});

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, {addBookType})(AddProject));
