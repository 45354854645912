import React, {Fragment} from "react";
import {BrowserRouter as BRouter, Route, Switch} from "react-router-dom";
import {HashRouter as Router} from "react-router-dom";
import App from "./pages/App";
import Reading from './pages/Qreader/Reading'
import Viewing from './pages/Medias/Viewing'
import store from "./pages/Ideahub/store";
import {Provider} from "react-redux";
import Home from './pages/HomePage/HomePage'
import {Provider as AlertProvider} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Alerts from "./pages/Ideahub/components/layout/Alerts";


// Alert Options
const alertOptions = {
    timeout: 3000,
    position: "top center"
};


export default (
    <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>

            <Router>
                <Fragment>
                    <Switch>
                        <Route exact path="/book/:id" component={Reading}/>
                        <Route exact path="/folder/:id" component={Viewing}/>
                        <Route path="/apps/" component={App}/>
                        <Route path="/" component={App}/>
                    </Switch>
                </Fragment>
            </Router>
        </AlertProvider>
    </Provider>
);
