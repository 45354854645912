import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Book from '../model/Book';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Search from '@material-ui/icons/Search';
import EditCloudBook from "../../../components/EditCloudBook"
import {getCoverURL} from '../service/bookUtil'
import {connect} from "react-redux";
import {platform} from "../../../platform"

const styles = theme => ({
    navbar: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 1px 1px rgba(0, 0, 0, .15)',
    },
    logo: {
        fontFamily: '\'Titillium Web\', sans-serif',
        fontSize: '1.5rem',
        color: theme.palette.text.primary,
    },
    button: {
        paddingTop: 6,
        paddingBottom: 6,
    },
    button2: {
        marginRight: 5
    },
    button3: {
        marginRight: 5,
        marginLeft: -7
        // fontSize: 20
    }
});

class Navbar extends React.Component {


    state = {

        open: false

    }


    toggleCloudOpen() {


        if (!this.props.auth.isAuthenticated) {

            alert("请先登录")
            window.location.href = "#/apps/login";
        } else {


            window.Thisk = this


            new Promise(function (resolve, reject) {


                window.Thisk.props.getCloudBooks()

                setTimeout(() => {
                    resolve(1);
                }, 100); // (*)


            }).then(function (result) { // (**)

                window.Thisk.setState(
                    {
                        open: !window.Thisk.state.open
                    }
                )
            });


        }


    }

    pandaSearch() {


        try {
            const shell = window.electron.shell;
            shell.openExternal("https://xmsoushu.com/");
        } catch (e) {
            // window.location.href = "http://newonet.com/";
            window.open("https://xmsoushu.com/")
        }


    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        try {
            let file = event.target.files[0];

            let reader = new FileReader();
            reader.readAsArrayBuffer(file);

            reader.onload = e => {


                const epub = window.ePub({bookPath: e.target.result});
                epub.getMetadata().then((metadata) => {
                    let name, author, content, description, book, cover;
                    [name, author, content, description] = [metadata.bookTitle, metadata.creator, metadata.description, e.target.result];

                    getCoverURL(epub, cover => {
                        cover = cover
                        book = new Book(name, author, content, description, cover);
                        this.props.handleAddBook(book);


                    })

                });
            };

            reader.onerror = () => {
                alert('Σ(っ °Д °;)っ Some error occurred, please try again!');
            };
        } catch (e) {
        }
    }


    getAllBooks() {

        if (this.props.auth.isAuthenticated) {
            this.props.getCloudBooks()

        } else {

            alert("请先登录")

            window.location.href = '#/apps/login';

        }


    }

    render() {
        const classes = this.props.classes;
        let {auth} = this.props


        let searchBook = platform === "mac" ? "" : (
            <Button onClick={this.pandaSearch.bind(this)} component="span" aria-label="sync"
                    color="primary"
                    className={classes.button}>
                <Search className={classes.button3}/>
                熊猫搜书
            </Button>)


        return (
            <div id="navbar">
                <Grid container align="center">
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container justify="flex-start" align="center">
                            <Grid item>
                                <Typography type="title" className={classes.logo}>
                                    我的书架
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container justify="flex-end" align="center">
                            <Grid item>
                                <input accept="application/epub+zip" id="add-book" type="file"
                                       onChange={this.handleChange}
                                       hidden/>
                                <label htmlFor="add-book">
                                    <Button component="span" aria-label="add" color="primary"
                                            className={classes.button}>
                                        <FolderOpenIcon className={classes.button2}/>
                                        导入epub
                                    </Button>

                                </label>

                                {/*<Button onClick={this.getAllBooks.bind(this)} component="span" aria-label="sync"*/}
                                {/*color="primary"*/}
                                {/*className={classes.button}>*/}
                                {/*<CloudDownloadIcon className={classes.button2}/>*/}

                                {/*</Button>*/}
                                <Button onClick={this.toggleCloudOpen.bind(this)} component="span" aria-label="sync"
                                        color="primary"
                                        className={classes.button}>
                                    <CloudDownloadIcon className={classes.button3}/>
                                    同步云端
                                </Button>

                                {/*{searchBook}*/}


                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>

                <EditCloudBook open={this.state.open}
                               toggleOpen={this.toggleCloudOpen.bind(this)}/>
            </div>
        );
    }
}

Navbar.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    auth: state.auth,
    bookTypes: state.projects.bookTypes,
    cloudBooks: state.books.cloudBooks,
    sts: state.auth.sts
});


export default withStyles(styles)(connect(mapStateToProps, {})(Navbar));



