import axios from "axios";
import {returnErrors} from "./messages";
import {ip} from "./env";

import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    QR_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    UPDATE_SUCCESS,
    GET_STS,
    GET_VERSION,
    VERSION_GOT,
    VERSION_ERROR,
    PAY_DONE,
    WECHAT_PAYIING,
    PAY_ERROR
} from "./types";


// const ip = ""


// pay
export const WechatPay = (fee, months, goldPoints, userToken) => (dispatch, getState) => {
    // User Loading
    dispatch({type: WECHAT_PAYIING});

    const body = JSON.stringify({fee, months, goldPoints, userToken});

    axios
        .post(ip + "/api/auth/wepay", body, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: PAY_DONE,
                payload: res.data
            });
        })
        .catch(err => {
            // dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: PAY_ERROR
            });
        });
};


// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
    // User Loading
    dispatch({type: USER_LOADING});

    axios
        .get(ip + "/api/auth/user", tokenConfig(getState))
        .then(res => {
            dispatch({
                type: USER_LOADED,
                payload: res.data.user
            });
        })
        .catch(err => {
            // dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: AUTH_ERROR
            });
        });
};


// get version
export const getVersion = () => (dispatch, getState) => {
    dispatch({type: GET_VERSION});

    axios
        .get(ip + "/api/auth/version")
        .then(res => {
            dispatch({
                type: VERSION_GOT,
                payload: res.data
            });
        })
        .catch(err => {
            // dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: VERSION_ERROR
            });
        });
};


// getCredential
export const getCredential = () => (dispatch, getState) => {
    axios
        .get(ip + "/api/auth/credential", tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_STS,
                payload: res.data.cred
            });
        })
        .catch(err => {
            // dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: AUTH_ERROR
            });
        });
};


// LOGIN USER
export const login = (username, password) => dispatch => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    // Request Body
    const body = JSON.stringify({username, password});

    axios
        .post(ip + "/api/auth/login", body, config)
        .then(res => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            });
        })
        .catch(err => {
            alert("用户名或密码错误，登录失败")
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: LOGIN_FAIL
            });
        });
};


// LOGIN USER via wechat
export const login_wx = (openId) => dispatch => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    // Request Body
    const body = JSON.stringify({openId});

    axios
        .post(ip + "/api/auth/wxlogin", body, config)
        .then(res => {


            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            });
        })
        .catch(err => {
            // dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: LOGIN_FAIL
            });
        });
};


// update goldPoints
export const updateUser = (goldPoints) => (dispatch, getState) => {
    // Headers
    // const config = {
    //     headers: {
    //         "Content-Type": "application/json"
    //     }
    // };

    // Request Body
    const body = JSON.stringify({goldPoints});

    axios
        .post(ip + "/api/auth/user", body, tokenConfig(getState))
        .then(res => {

            console.log("更新了点数.............................");

            dispatch({
                type: UPDATE_SUCCESS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: LOGIN_FAIL
            });
        });
};


// get QRcode url
export const qr_wx = (openId) => dispatch => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    // Request Body
    const body = JSON.stringify({openId});

    axios
        .post(ip + "/api/auth/wxqr", body, config)
        .then(res => {
            dispatch({
                type: QR_SUCCESS,
                payload: res.data
            });
        })
        .catch(err => {
            // dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: LOGIN_FAIL
            });
        });
};


// REGISTER USER
export const register = ({username, password, email}) => dispatch => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    // Request Body
    const body = JSON.stringify({username, email, password});

    axios
        .post(ip + "/api/auth/register", body, config)
        .then(res => {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: REGISTER_FAIL
            });
        });
};

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
    axios
        .post(ip + "/api/auth/logout/", null, tokenConfig(getState))
        .then(res => {
            dispatch({type: "CLEAR_LEADS"});
            dispatch({
                type: LOGOUT_SUCCESS
            });
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
};

// Setup config with token - helper function
export const tokenConfig = getState => {
    // Get token from state
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    // If token, add to headers config
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }

    return config;
};


export const tokenConfig_file = getState => {
    // Get token from state
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };

    // If token, add to headers config
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }

    return config;
};
