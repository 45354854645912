import {
  GET_PROJECTS,
  POST_PROJECTS,
  POST_BOOK_TYPE,
  GET_BOOK_TYPES,
  UPDATE_BOOK_TYPES,
  UPDATE_PROJECTS
} from "../actions/types.js";

const initialState = {
  projects: [],
  project: {},
  bookTypes: [],
  bookType: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload
      };
    case GET_BOOK_TYPES:
      return {
        ...state,
        bookTypes: action.payload
      };

    case UPDATE_BOOK_TYPES:
      return {
        ...state,
        bookType: action.payload,
        bookTypes: state.bookTypes.map(bookType => {
          if (bookType.id === action.payload.id) {
            return action.payload;
          } else {
            return bookType;
          }

        })
      };
    case UPDATE_PROJECTS:
      return {
        ...state,
        project: action.payload,
        projects: state.projects.map(project => {
          if (project.id === action.payload.id) {
            return action.payload;
          } else {
            return project;
          }

        })
      };
    case POST_PROJECTS:
      return {

        ...state,
        projects: [...state.projects, action.payload]


      };
    case POST_BOOK_TYPE:
      return {

        ...state,
        bookTypes: [...state.bookTypes, action.payload]


      };
    default:
      return state;
  }
}
