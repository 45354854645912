import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Note from "./Note";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginTop: 10,
  },
  list: {
    width: 280,
    padding: 0,
    listStyle: "none",
    flexGrow: 2,
    overflowX: "hidden",
  },
  foot: {
    display: "flex",
    flexShrink: 0,
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    height: 40,
    padding: "0 10px",
  },
});

class Notes extends React.Component {

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleJump = this.handleJump.bind(this);
    this.handleExport = this.handleExport.bind(this);
  }

  handleClose() {
    this.props.toggleNotes(false);
  }

  handleJump(cfi) {
    let res = this.props.epub.gotoCfi(cfi);
    console.log("handleJump", res);
  }

  handleExport() {
    this.props.toggleExport(true);
  }

  render() {
    const {notes, colors, classes} = this.props;

    return (
      <div id="notes">
        <Drawer open={this.props.open} onClose={this.handleClose} anchor="right" className={classes.root}>
          <div className={classes.title}>
            <Typography type="headline" align="center" gutterBottom>Notes</Typography>
            {/*展开按钮*/}
          </div>
          <ul className={classes.list}>
            {
              notes.map((item) => {
                return (
                  <Note key={item.key}
                        note={item}
                        colors={colors}
                        deleteNote={this.props.deleteNote}
                        updateNote={this.props.updateNote}
                        handleJump={this.handleJump}
                  />
                );
              })
            }
          </ul>
          <Divider/>
          <div className={classes.foot}>
            <Button onClick={this.handleExport} color="secondary">
              export
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}

Notes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notes);
