import React from "react";
import ChapterList from "./ChapterList";

class ChapterItem extends React.Component {

  constructor(props) {
    super(props);

    this.handleJump = this.handleJump.bind(this);
  }

  handleJump(event) {
    let href = event.target.getAttribute("href");
    this.props.goMedia(href);
    // alert(href);
    event.preventDefault();
  }

  render() {
    const {chapter} = this.props;

    return (
      <li className={"chapter-list-item"}>
        <div className="item-content">
          <i className="item-mark"/>
          <a className="chapter-url" href={chapter.name} onClick={this.handleJump}>{chapter.name}</a>
        </div>
      </li>
    );
  }
}

export default ChapterItem;
