import React, {Component, Fragment} from "react";
import Left_items from "./Left_items"
import Right_classification from "./Right_classification"
import PropTypes from "prop-types";
import Work_area from "./Work_area"
import Projects from "../projects/projects"
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Config from "../../../Qreader/service/Config"
import Alerts from "../layout/Alerts";

const styles = theme => ({

    middle: {
        marginLeft: 30,
        marginRight: 30,

    },

});

class Ideas extends Component {


    render() {

        const {classes} = this.props;
        document.title = "闪念胶囊";


        return (
            <Fragment>

                <Container fixed>
                    <br/>

                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Left_items/>
                        </Grid>
                        <Grid item xs={6} className={classes.middle}>
                            <Work_area/>
                            <Projects/>
                        </Grid>
                        <Grid item xs>
                            <Right_classification/>
                        </Grid>
                    </Grid>


                </Container>
            </Fragment>
        );
    }
}


// export default Ideas;

Ideas.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Ideas);
