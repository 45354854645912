import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {login_wx, qr_wx} from "../../actions/auth";
import {platform} from "../../../../platform"
import {siwa_flag} from "../../../../platform"
import IconButton from "@material-ui/core/IconButton";
import AppleIcon from '@material-ui/icons/Apple';
import AccountBoxIcon from '@material-ui/icons/AccountBox';


export class Login_wx extends Component {
    state = {
        openId: "",
    };

    static propTypes = {
        login_wx: PropTypes.func.isRequired,
        qr_wx: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
        qr_url: PropTypes.object.isRequired
    };


    componentWillUnmount() {


    }

    SignIn() {

        let current_href = window.location.href

        window.open(`${"https://appleid.apple.com/auth/authorize?client_id=com.newonet.siwa&redirect_uri=https://api.newonet.com/api/auth/apple&response_type=code id_token&state="} + ${this.state.openId}  + ${"&scope=email name&nonce=123&response_mode=form_post"} `, siwa_flag)

        setInterval(() => {
            console.log(window.location.href)
            console.log(current_href)
        }, 1000)


    }


    componentDidMount() {


        window.This = this


        let urlBack = window.location.toString().split("?url=")[1]
        let openid = window.location.toString().split("openid=")[1]
        window.urlBack = urlBack

        if (openid) {

            console.log("openid:", openid)


            // alert("location........................." + )

            const timeId = openid

            this.setState({

                openId: timeId,

            })

            this.props.qr_wx(timeId)


        } else {

            const target = platform === "mac" ? siwa_flag : platform

            const timeId = "login-" + new Date().getTime() + "login-" + target

            this.setState({

                openId: timeId,

            })

            this.props.qr_wx(timeId)


        }


        for (let i = 0; i < 200; i++) {


        }

        setInterval(function () {

            if (!window.This.props.isAuthenticated) {
                try {
                    window.This.props.login_wx(window.This.state.openId);
                } catch (e) {
                }

            }

        }, 3000)


    }


    render() {

        let buff = require('../../../../images/buffering.gif')
        let appleid = require('../../../../images/apple-id.png')


        if (this.props.isAuthenticated) {
            return <Redirect to={window.urlBack ? window.urlBack : "/apps/books"}/>;
        }

        let QR_iamge = (

            <img className="QRCode_Place" src={this.props.qr_url} alt="请用微信扫码登录"/>
        )

        let buffering = (

            <img className="QRCode_Place" src={buff} alt="缓冲"/>
        )

        let image = this.props.qr_url ? QR_iamge : buffering

        let siwa = (<div
            >
                    <span

                        onClick={this.SignIn.bind(this)}
                        className="appleSignIn"
                    >


                    <img
                        src={appleid}
                        alt="Sign in with Apple"
                        title="" className="center scale" width="212" height="38"/>


                    </span>


            </div>
        )


        return (
            <div className="qr_code">


                {image}
                {platform === "mac" ? siwa : null}


                {/*<IconButton title="Sign in with apple"*/}
                {/*            aria-haspopup="true"*/}
                {/*            onClick={this.SignIn.bind(this)}*/}
                {/*            color="black"*/}
                {/*> <span>Sign in with&nbsp;</span>*/}
                {/*    <AppleIcon style={{color: "black", opacity: 1}}/>*/}
                {/*</IconButton>*/}

                {/*<button*/}

                {/*    onClick={this.SignIn.bind(this)}*/}

                {/*>Sign in with apple*/}
                {/*</button>*/}


            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    qr_url: state.auth.qr_url
});

export default connect(
    mapStateToProps,
    {login_wx, qr_wx}
)(Login_wx);
