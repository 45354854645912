import axios from "axios";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import React, {Component} from "react";
import {loadUser, updateUser, WechatPay} from "../pages/Ideahub/actions/auth";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import {grey} from "@material-ui/core/colors";
import QRCode from "qrcode.react";
import {deepOrange} from "@material-ui/core/colors";
import {createMuiTheme} from "@material-ui/core/styles";


import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import store from "../pages/Ideahub/store";


const theme = createMuiTheme({
    palette: {
        primary: deepOrange,
        secondary: {
            main: "#ff8a65",
        },
    },
});

const styles = theme => {
    return {


        root: {

            margin: "0 auto"

        },
        formControl: {
            margin: theme.spacing(3),
        },

        large: {
            width: 60,
            height: 60,
        },
        toggleDiv: {
            marginTop: 20,
            marginBottom: 5
        },
        toggleLabel: {
            color: grey[400],
            fontWeight: 100
        },
        button: {},
        buttons: {
            marginTop: 30,
            float: "right"
        },
        saveButton: {
            marginLeft: 15
        },
        typeOptions: {

            width: 180

        },
        bookName: {

            width: 500

        }

    };
};


export class Payment extends Component {


    state = {

        months: "12",
        months2: "0",
        goldPoints: "100"


    };

    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired
    };

    pay(fee, months) {


        store.dispatch(WechatPay(fee, months, "0", this.state.userToken));


    };


    handleClose() {
        this.props.toggleOpen();
        this.props.loadUser();
        alert("支付完成，请重新登录")
        window.location.href = "#/apps/logout";
    }

    handleClose1() {
        this.props.toggleOpen();
        this.props.loadUser();
    }


    handlePay() {

        let m = this.state.months;


        let fee = "1";

        if (m === "1") {

            fee = "600";

        } else if (m === "3") {

            fee = "1500";
        } else if (m === "12") {

            fee = "4900";
        }


        this.pay(fee, m, "0");


    };


    handleChange = m => {

        this.setState({
            months: m

        });

    };


    componentDidMount() {


        let userToken = localStorage.getItem("token");


        this.setState({

            userToken: userToken
        });


        window.This = this;


        if (this.props.auth.isAuthenticated) {


            localStorage.setItem("code_url", "666");


        }


    }


    componentDidUpdate(prevProps, prevState, snapshot) {


        if (this.state.months !== this.state.months2) {


            this.setState({

                months2: this.state.months

            });

            this.handlePay()

        }


    }

    render() {
        let {classes} = this.props;

        const {isAuthenticated, pay_code_url} = this.props.auth;


        return (
            <div>
                <Dialog open={this.props.open} className={classes.root}>
                    <DialogTitle>微信扫码支付</DialogTitle>
                    <DialogContent>
                        <QRCode
                            value={pay_code_url}  //value参数为生成二维码的链接
                            size={200} //二维码的宽高尺寸
                            fgColor="#000000"  //二维码的颜色
                        />
                    </DialogContent>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <RadioGroup aria-label="gender" name="gender2" value={this.state.months}>
                            <FormControlLabel

                                onClick={this.handleChange.bind(this, "1")}

                                value="1"
                                control={<Radio color="primary"/>}
                                label="6元/包月"
                                labelPlacement="6"
                            />
                            <FormControlLabel
                                onClick={this.handleChange.bind(this, "3")}
                                value="3"
                                control={<Radio color="primary"/>}
                                label="15元/包季"
                                labelPlacement="15"
                            />
                            <FormControlLabel
                                onClick={this.handleChange.bind(this, "12")}
                                value="12"
                                control={<Radio color="primary"/>}
                                label="49元/包年"
                                labelPlacement="49"
                            />
                        </RadioGroup>
                    </FormControl>


                    <DialogActions color="primary">
                        <div className={classes.buttons}>
                            <Button onClick={this.handleClose1.bind(this)} variant="contained">关闭</Button>
                            <Button
                                className={classes.saveButton}
                                variant="contained"
                                type="submit"
                                color="primary"
                                onClick={this.handleClose1.bind(this)}
                            >
                                支付完成
                            </Button>

                        </div>


                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


Payment.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    auth: state.auth,
});

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, {
    updateUser, WechatPay, loadUser
})(Payment));
