import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import Folder from "../model/Folder";
import Book from "../model/Book";
import Media from "../model/Media";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EditCloudBook from "../../../components/EditCloudBook";
import {getCoverURL} from "../service/bookUtil";
import {connect} from "react-redux";


const cover = "http://media-ideahub.oss-cn-beijing.aliyuncs.com/image/cover.gif";

const author = "http://127.0.0.1:8080/";



const styles = theme => ({
  navbar: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 1px 1px rgba(0, 0, 0, .15)",
  },
  logo: {
    fontFamily: "'Titillium Web', sans-serif",
    fontSize: "1.5rem",
    color: theme.palette.text.primary,
  },
  button: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  button2: {
    marginRight: 5
  },
  button3: {
    marginRight: 5,
    marginLeft: -7
    // fontSize: 20
  }
});

class Navbar extends React.Component {


  state = {

    open: false

  };


  toggleCloudOpen() {


    if (!this.props.auth.isAuthenticated) {

      alert("请先登录");
    } else {


      this.setState(
        {
          open: !this.state.open

        }
      );

    }


  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handleChange(event) {


    let path = document.getElementById("add-book").value;

    console.log("path", path);

    let files = event.target.files;

    let name = files[3].webkitRelativePath.split("/")[0];



    let description = 0;

    let content = "111";

    let book = new Folder(name, author, description, content, cover);
    this.props.handleAddFolder(book);


    console.log("files", files);


    var file;
    for (var i = 0; i < files.length; i++) {
      file = files[i];                 /* 获取单个File对象 */
      console.log("file", file.webkitRelativePath);

      let file_name = file.webkitRelativePath.split(name + "/")[1];

      console.log("file_name", file_name);


      let type = "mp4";


      let media = new Media(file_name, type, name);

      console.log("media", media);


      this.props.handleAddMedia(media);


    }


  }

  handleInput(event) {

    window.This_m = this;


    let path = document.getElementById("add-file").value;

    console.log("path", path);


    let files = event.target.files;

    let name1 = files[0].name;


    let name = name1.split(".txt")[0];
    console.log("namenamenamename", name);

    //
    // let cover = "http://media-ideahub.oss-cn-beijing.aliyuncs.com/image/cover.gif";
    //
    // let author = "http://192.168.31.102:1010/mp4/";

    let description = 0;

    let content = "111";

    let book = new Folder(name, author, description, content, cover);

    console.log("bookbookbook", book);


    this.props.handleAddFolder(book);


    console.log("files", files);


    let file1 = event.target.files[0];


    let reader = new FileReader();

    reader.readAsText(file1, "utf-8");
    reader.onload = function () {
      console.log("this.result", this.result);


      let media_files = this.result.split("\n");


      for (var i = 0; i < media_files.length; i++) {
        let file_name = media_files[i];
        let type = "mp4";

        let media = new Media( file_name, type, name);

        console.log("media", media);


        window.This_m.props.handleAddMedia(media);

      }


    };


  }


  getAllBooks() {

    if (this.props.auth.isAuthenticated) {
      this.props.getCloudBooks();

    } else {

      alert("请先登录");

      window.location.href = "#/apps/login";

    }


  }

  render() {
    const classes = this.props.classes;
    let {auth} = this.props;

    return (
      <div id="navbar">


        <Grid container align="center">
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={5}>
            <Grid container justify="flex-start" align="center">
              <Grid item>
                <Typography type="title" className={classes.logo}>
                  我的资料
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container justify="flex-end" align="center">
              <Grid item>
                <input accept="text/plain" id="add-file" type="file"
                       onChange={this.handleInput}
                       hidden/>
                <label htmlFor="add-file">
                  <Button component="span" aria-label="add" color="primary"
                          className={classes.button}>
                    <FolderOpenIcon className={classes.button2}/>
                    本地txt
                  </Button>

                </label>

                <input id="add-book" type="file" name="img" webkitdirectory="true" directory="true"
                       onChange={this.handleChange}
                       hidden/>

                <label htmlFor="add-book">
                  <Button component="span" aria-label="add" color="primary"
                          className={classes.button}>
                    <FolderOpenIcon className={classes.button2}/>
                    本地文件夹
                  </Button>

                </label>


                {/*<Button onClick={this.toggleCloudOpen.bind(this)} component="span" aria-label="sync"*/}
                {/*        color="primary"*/}
                {/*        className={classes.button}>*/}
                {/*  <CloudDownloadIcon className={classes.button3}/>*/}
                {/*  同步云端*/}
                {/*</Button>*/}

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
          </Grid>
        </Grid>

        <EditCloudBook open={this.state.open}
                       toggleOpen={this.toggleCloudOpen.bind(this)}/>
      </div>
    );
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,

});


export default withStyles(styles)(connect(mapStateToProps, {})(Navbar));
