import {combineReducers} from "redux";
import leads from "./leads";
import ideas from "./ideas";
import books from "./books";
import errors from "./errors";
import messages from "./messages";
import auth from "./auth";
import projects from './projects'

export default combineReducers({
    leads,
    ideas,
    books,
    projects,
    errors,
    messages,
    auth
});
