import React from "react";
import ChapterItem from "./ChapterItem";

class ChapterList extends React.Component {

  render() {
    const {chapters, collapse} = this.props;


    return (
      <ul className={collapse ? "chapter-list collapse" : "chapter-list expand"}>
        {
          chapters.map((chapter, index) => <ChapterItem

            goMedia={this.props.goMedia}

            key={index} chapter={chapter}/>)
        }
      </ul>
    );
  }
}

export default ChapterList;
