import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import axios from "axios";
import hljs from 'highlight.js'
import marked from 'marked'
import 'highlight.js/styles/monokai-sublime.css';

const styles = theme => ({

    root: {
        width: "100%",
        backgroundColor: "#f5c28a"

    },

    flashCard_unit: {}

});

class FlashCards extends Component {
    constructor(props) {
        super(props);

        this.state = {
            flashCard_projects: [],
            flashCards: [],
            current_project_id: 0,
            last_project_id: 0,
            current_card_index: 0,
            markdown_rendered: 1,
            current_text: "nothing"

        }


        this.getSoemthing = this.getSoemthing.bind(this)
        this.onChange = this.onChange.bind(this)
        this.showProjects = this.showProjects.bind(this)
        this.getFlashCardsByproject = this.getFlashCardsByproject.bind(this)
        this.markdown_toggle = this.markdown_toggle.bind(this)

    }


    onKeyDown = (e) => {

        console.log("keycode", e.keyCode)

        switch (e.keyCode) {
            case 13://回车事件
                // alert("回车")
                break

            case 39://right arrow


                if (this.state.current_card_index < this.state.flashCards.length) {
                    this.setState({
                        current_card_index: this.state.current_card_index + 1
                    })
                }
                console.log("this.state.current_card_index", this.state.current_card_index)
                break

            case 37://left arrow


                if (this.state.current_card_index > -1) {
                    this.setState({
                        current_card_index: this.state.current_card_index - 1
                    })
                }

                console.log("this.state.current_card_index", this.state.current_card_index)
                break
        }
    }


    activate_card = (index) => {

        this.setState({
            current_card_index: index

        })

    }


    markdown_toggle = () => {


        if (!this.state.markdown_rendered) {

            document.getElementById("raw_text").style.display = "none"
            document.getElementById("markdown_show").style.display = ""

            this.setState({

                markdown_rendered: !this.state.markdown_rendered

            })


        } else {

            document.getElementById("raw_text").style.display = ""
            document.getElementById("markdown_show").style.display = "none"

            this.setState({

                markdown_rendered: !this.state.markdown_rendered

            })


        }


    }


    getFlashCardsByproject = (id) => {

        axios
            .get("/mock/flashcards.json")
            .then(res => {
                let cards = res.data.results.filter(item => item.project === parseInt(id))
                this.setState({
                    flashCards: cards
                })

            })


    }


    getSoemthing = () => {

        let title = this.state.flashCards[this.state.current_card_index]["main_title"]
        //

        console.log(title)

    }


    showProjects = () => {
        this.state.flashCard_projects.map((item, key) => {
            console.log(item.name)

        })
    }


    update_text = e => {


        this.setState({
            current_text: e.target.value,
        })


    }


    onChange = e => {

        this.setState({
            current_project_id: e.target.value,
            current_card_index: 0
        })

    }


    componentDidMount() {

        document.getElementById("raw_text").style.display = "none"
        document.addEventListener("keydown", this.onKeyDown)
        axios
            .get("/mock/projects.json")
            .then(res => {
                let testData = res.data
                console.log(testData)

                this.setState({
                    flashCard_projects: res.data
                })


            })
            .catch((e) => {
                console.log("error", e)
            })


    }


    componentWillMount() {


        // marked相关配置
        marked.setOptions({
            renderer: new marked.Renderer(),
            gfm: true,
            tables: true,
            breaks: true,
            pedantic: false,
            sanitize: true,
            smartLists: true,
            smartypants: false,
            highlight: function (code) {
                return hljs.highlightAuto(code).value;
            },
        });

        document.removeEventListener("keydown", this.onKeyDown)
    }


    componentWillUpdate(nextProps, nextState, nextContext) {
        hljs.initHighlightingOnLoad()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        // hljs.initHighlightingOnLoad();

        if (this.state.last_project_id !== this.state.current_project_id) {

            this.setState({
                last_project_id: this.state.current_project_id
            })

            this.getFlashCardsByproject(this.state.current_project_id)

        }
    }


    render() {


        let main_title = ""
        let text = ""
        try {
            main_title = this.state.flashCards[this.state.current_card_index]["main_title"]
            text = this.state.flashCards[this.state.current_card_index]["text"]

            if (this.state.current_text === "nothing") {
                this.setState({

                    current_text: text
                })
            }

        } catch (e) {


        }


        const {classes} = this.props;
        return (
            <div className={classes.root} id="flashCard">

                <div className={classes.flashCard_unit} id="flashCard_header">
                    <span>正在学习：</span>
                    <span>
                        <select

                            onChange={this.onChange}

                            name="项目名称" id="projectName">
                        <option onChange={this.onChange}
                                value="0">所有未分类</option>
                            {this.state.flashCard_projects.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>
                            ))}

                    </select>
                    </span>


                    <button onClick={this.getSoemthing}>getSomething</button>
                    <button onClick={this.showProjects}>showProjects</button>
                    <button onClick={this.markdown_toggle}>切换</button>

                </div>
                <div id="flashCard_body">
                    <div id="flashCard_body_left">

                        <h2>List of FlashCards</h2>


                        {
                            this.state.flashCards.map((item, index) => (


                                <p onClick={this.activate_card.bind(this, index)}
                                   className="card_title_list"
                                   key={item.id}>{item.main_title}


                                </p>

                            ))
                        }


                    </div>
                    <div id="flashCard_body_middle">

                        <h2>  {main_title}  </h2>

                        <textarea

                            onChange={this.update_text}

                            id="raw_text" name="raw_text" value={this.state.current_text}>11</textarea>


                        <div id="markdown_show"
                             dangerouslySetInnerHTML={{
                                 __html: text ? marked(text) : null,
                             }}
                        />


                    </div>
                    <div id="flashCard_body_right">right</div>
                </div>


            </div>
        );
    }
}

FlashCards.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FlashCards);
