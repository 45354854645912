import React from "react";
import {grey} from "@material-ui/core/colors";
import PageBase from "../components/PageBase";
import Info from "./Ideahub/components/accounts/Info";
// import Login from './Ideahub/components/App';
import Payment from "../components/Paymen";
import Payment2 from "../components/Payment2";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import PaymentIcon from "@material-ui/icons/Payment";
import FolderOpenIcon from "./Qreader/components/Navbar";
import store from "./Ideahub/store";
import {getVersion} from "./Ideahub/actions/auth";
import {WechatPay} from "../pages/Ideahub/actions/auth";
import {platform} from "../platform"

const styles = theme => ({
    toggleDiv: {
        marginTop: 20,
        marginBottom: 5
    },
    toggleLabel: {
        color: grey[400],
        fontWeight: 100
    },
    buttons: {
        marginTop: 30,
        float: "right"
    },
    saveButton: {
        marginLeft: 5
    },

    button_pay: {
        marginRight: 5,
        backgroundColor: "#FFFFFF",
        color: "#3498db",
        borderRadius: "20%",
        "&:hover": {
            // backgroundColor: fade(theme.palette.common.white, 0.5),


            cursor: "pointer",
            color: "#f39c12",
        }
    },

    button: {
        paddingTop: 6,
        paddingBottom: 6,
    },


});

class Account extends React.Component {


    state = {

        open: false,
        open2: false

    };

    freeCharge() {

        alert("在微信公众号中领取活动二维码，邀请好友扫码关注，可以和好友一起获得闪电读书会员权限😋");
    }

    Charge() {

        alert("在《闪电读书软件助手》微信公众号中充值或者分享海报免费领会员。");
    }

    pay() {


        store.dispatch(WechatPay("4900", "12", "0", this.state.userToken));

        this.setState(
            {
                open: !this.state.open

            }
        );

    };

    pay2() {


        store.dispatch(WechatPay("5000", "0", "100", this.state.userToken));

        this.setState(
            {
                open2: !this.state.open2

            }
        );

    };


    togglePayOpen() {


        this.setState(
            {
                open: !this.state.open

            }
        );

    }

    togglePayOpen2() {


        this.setState(
            {
                open2: !this.state.open2

            }
        );

    }


    componentDidMount() {


        let userToken = localStorage.getItem("token");


        this.setState({

            userToken: userToken
        });


    }


    render() {

        const classes = this.props.classes;
        const {isAuthenticated, user} = this.props.auth;

        let renew_win = (<span>                    <span
            onClick={this.freeCharge.bind(this)}
            className={classes.button_pay}>免费</span>
                    <span
                        onClick={this.pay.bind(this)}
                        className={classes.button_pay}
                    >付费</span></span>)
        let renew_mac = (<span
            onClick={this.Charge.bind(this)}
            className={classes.button_pay}>续期</span>)

        let recharge = (<span
            onClick={this.pay2.bind(this)}
            className={classes.button_pay}
        >&nbsp;&nbsp;充值</span>)


        let level = 0;
        try {
            level = user.memberLevel;
        } catch (e) {

        }

        return (
            <PageBase title="个人中心" navigation="Application / Account">
                <div className="col-md-6 m-auto">
                    用户昵称： {user.nickName}
                    <br/>
                    金豆数量：{user.goldPoints}

                    {level <= 1 && platform === "mac" ? "" : recharge}

                    <br/>
                    会员期限：{user.memberExpireDate.substring(0, 10)}
                    <br/>
                    会员续期：
                    {level <= 1 && platform === "mac" ? renew_mac : renew_win}
                    {/*//renew_mac*/}

                </div>
                <Payment open={this.state.open}
                         toggleOpen={this.togglePayOpen.bind(this)}/>
                <Payment2 open={this.state.open2}
                          toggleOpen={this.togglePayOpen2.bind(this)}/>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>


            </PageBase>
        );
    }


}


Account.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,

});


export default withStyles(styles)(connect(mapStateToProps, {
    WechatPay
})(Account));
