import {
    GET_IDEAS,
    DELETE_IDEA,
    ADD_IDEA,
    UPDATE_IDEA,
    DONE_IDEA,
    CLEAR_IDEAS,
    UPDATE_WORK_TYPE
} from "../actions/types.js";
import {ACTIVATE_IDEA} from "../actions/types"

const initialState = {
    ideas: [],
    activate_idea: 0,
    work_type: '任务待分类',
    project: {},
    current_page: 1,
    max_page: 1,
    current_color: "",
    is_delete: 0,
    is_done: 0,

};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_IDEAS:
            return {
                ...state,
                ideas: action.payload[0],
                project: {
                    name: action.payload[1],
                    id: action.payload[2]
                },
                current_page: action.payload[4],
                current_color: action.payload[3]


            };

        case DELETE_IDEA:
            return {
                ...state,
                ideas: state.ideas.filter(idea => idea.id !== action.payload)
            };
        case ADD_IDEA:
            return {
                ...state,
                ideas: [...state.ideas]
            };
        case DONE_IDEA:
            return {
                ...state,
                ideas: state.ideas.filter(idea => idea.id !== action.payload),
                activate_idea: 0
            };

        case UPDATE_IDEA:
            return {
                ...state,
                ideas: state.ideas.map(idea => {


                    if (idea.id === action.payload[0]) {
                        idea.color = action.payload[1]
                        return idea
                    } else {


                        return idea
                    }


                }),


            };

        case ACTIVATE_IDEA:
            return {
                ...state,
                activate_idea: action.payload,

            };

        case UPDATE_WORK_TYPE:

            return {
                ...state,
                work_type: action.payload,

            };


        case CLEAR_IDEAS:
            return {
                ...state,
                ideas: []
            };
        default:
            return state;
    }
}
