import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {grey, orange, green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SunIcon from "@material-ui/icons/Brightness5";
import MoonIcon from "@material-ui/icons/Brightness4";
import StyleIcon from "@material-ui/icons/Stars";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import MenuBook from "@material-ui/icons/MenuBook";

import ResetIcon from "@material-ui/icons/Restore";
import Tooltip from "@material-ui/core/Tooltip";
import Config from "../service/Config";
import Util from "../service/Util";

const styles = theme => ({
    root: {},
    card: {
        minWidth: 400,
        minHeight: 60,
        padding: 10,
        margin: "15px auto",
        boxShadow: theme.shadows[1],
        transition: "box-shadow .3s",
        "&:hover": {
            boxShadow: theme.shadows[6],
        },
    },
    icon: {
        color: grey[700],
        "&:hover": {
            color: orange[700],
            cursor: "pointer",
        },
    },
    box: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20 0 10",
    },
    colorBox: {
        width: 360,
        padding: "20px 0 10px",
        margin: "auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
    },
    colorItem: {
        width: "45%",
        margin: "5px 0",
        display: "flex",
        alignItems: "center",
    },
    dot: {
        width: 14,
        height: 14,
        display: "inline-block",
        borderRadius: "50%",
        border: "1px solid black",
        marginRight: 8,
    },

    selection: {

        backgroundColor: "transparent",


    },

    input: {
        outline: "none",
        border: "none",
        width: "80%",
        borderBottom: "1px dotted black",
        backgroundColor: "transparent",
        transition: ".3s",
        "&:hover": {
            borderColor: orange[700],
        },
        "&:focus": {
            borderColor: orange[700],
        },
    },
    controlBox: {
        width: 360,
        display: "flex",
        alignItems: "center",
        padding: "5px 10px",
    },
    caption: {
        width: "15%",
        marginRight: 20,
        fontSize: "small",
        textAlign: "right",
        color: grey[700],
    },
    checked: {
        color: green[500],
    },
    hide: {
        display: "none",
    }
});

class SettingsDialog extends React.Component {

    constructor(props) {
        super(props);

        let {
            colors,
            background,
            gutter,
            padding,
            fontSize,
            lineHeight,
            letterSpacing,
            wordSpacing,
            column,
            disablePopup
        } = this.props;
        this.state = {
            colors: colors,
            background: background,
            gutter: gutter,
            padding: padding,
            fontSize: fontSize,
            lineHeight: lineHeight,
            letterSpacing: letterSpacing,
            wordSpacing: wordSpacing,
            column: column,
            disablePopup: disablePopup,
            lan: Config.get().lan ? Config.get().lan : "zh",
            ctp: Config.get().ctp ? Config.get().ctp : 1,
            sleep: Config.get().sleep ? Config.get().sleep : "60",
            content_len: Config.get().content_len ? Config.get().content_len : "60",
            parser: Config.get().parser ? Config.get().parser : "normal",
            vol: Config.get().vol ? Config.get().vol : 9,
            per: Config.get().per ? Config.get().per : 111,
            spd: Config.get().spd ? Config.get().spd : 4,
            pit: Config.get().pit ? Config.get().pit : 5,
            Note:Config.get().Note ? Config.get().Note : "https://www.wolai.com/",
        };

        this.handleClose = this.handleClose.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.changeColors = this.changeColors.bind(this);
        this.changeColumn = this.changeColumn.bind(this);
        this.changeGutter = this.changeGutter.bind(this);
        this.changePadding = this.changePadding.bind(this);
        this.changeFontConfig = this.changeFontConfig.bind(this);
        this.changeVoiceConfig = this.changeVoiceConfig.bind(this);
        this.changeNoteConfig = this.changeNoteConfig.bind(this);
        this.changeParserConfig = this.changeParserConfig.bind(this);
        this.changeSleepConfig = this.changeSleepConfig.bind(this);
        this.changeSentenceConfig = this.changeSentenceConfig.bind(this);
        this.changeUserStyle = this.changeUserStyle.bind(this);
        this.resetUserStyle = this.resetUserStyle.bind(this);
        this.disablePopup = this.disablePopup.bind(this);
        this.handleColorsChange = this.handleColorsChange.bind(this);
        this.handleOtherChange = this.handleOtherChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let {
            colors,
            background,
            gutter,
            padding,
            fontSize,
            lineHeight,
            letterSpacing,
            wordSpacing,
            column,
            disablePopup
        } = nextProps;
        this.setState({
            colors: colors,
            background: background,
            gutter: gutter,
            padding: padding,
            fontSize: fontSize,
            lineHeight: lineHeight,
            letterSpacing: letterSpacing,
            wordSpacing: wordSpacing,
            column: column,
            disablePopup: disablePopup,
        });
    }

    handleClose() {
        this.props.toggleSettingsDialog(false);
    }

    handleColorsChange(event) {
        let index = parseInt(event.target.dataset.color);
        let colors = this.state.colors;
        colors[index] = event.target.value;
        this.setState({colors});
    }

    handleOtherChange(event) {
        let name = event.target.name;
        this.setState({
            [name]: event.target.value
        });
    }

    // 改变主题
    changeTheme(theme) {
        if ((theme === true) || (theme === false)) {
            this.props.toggleTheme(theme);
            let colors1 = Config.getDefaultConfigObj().colors;
            let colors2 = ["#F44336", "#F57F17", "#8BC34A", "#2196F3"];
            let colors = theme ? colors1 : colors2;
            this.props.setConfig("background", "#FFF");
            this.props.setConfig("colors", colors);
            Util.addDefaultCss(); // 改变了设置，需要为当前页重新应用样式
        } else if (theme === 1) {
            this.props.toggleTheme(theme);
            let colors1 = Config.getDefaultConfigObj().colors;
            let colors2 = ["#F44336", "#F57F17", "#8BC34A", "#2196F3"];
            let colors = theme ? colors1 : colors2;
            this.props.setConfig("colors", colors);
            this.props.setConfig("background", "#f8f4ea");

            Util.addDefaultCss();


        } else if (theme === 2) {
            this.props.toggleTheme(theme);
            let colors1 = Config.getDefaultConfigObj().colors;
            let colors2 = ["#F44336", "#F57F17", "#8BC34A", "#2196F3"];
            let colors = theme ? colors1 : colors2;
            this.props.setConfig("colors", colors);
            this.props.setConfig("background", "#ceeccf");
            Util.addDefaultCss();


        }
    }

    // 改变高亮的颜色
    changeColors(event) {
        let colors = Config.get().colors;
        let value = event.target.value;
        let i = parseInt(event.target.dataset.color);
        colors[i] = value;
        this.props.setConfig("colors", colors);
        console.log(`change highlight color ${i} to ${value}`);
        Util.addDefaultCss(); // 改变了颜色，需要为当前页重新应用样式
    }

    // 改变可阅读区域列数
    changeColumn(event, value) {
        value = value ? 1 : 2;
        this.props.setConfig("column", value);
        console.log(`change column to ${value}`);
        // TODO: 为单双列模式设置合适的gutter值
    }

    // 改变gutter的值
    changeGutter(event) {
        let value = parseInt(event.target.value);
        this.props.setConfig("gutter", value);
        console.log(`change gutter to ${value}`);
        // TODO: 添加数值验证（不小于80）
    }

    // 改变padding的值
    changePadding(event) {
        let value = parseInt(event.target.value);
        this.props.setConfig("padding", value);
        console.log(`change padding to ${value}`);
        // TODO: 添加数值验证（不小于10）
    }

    // 改变与字体相关的配置
    changeFontConfig(event) {
        let name = event.target.name;
        let value = event.target.value;
        let num;

        if (value === "default") {
            num = 0;
        } else if (value !== "default" && isNaN(value)) {
            alert("Invalid value!");
            return;
        } else {
            num = parseInt(value);
        }

        this.props.setConfig(name, num);
        console.log(`change ${name} to ${num}`);
        // TODO: 添加数值校验
        Util.addDefaultCss(); // 改变了设置，需要为当前页重新应用样式
    }

    // 改变与声音相关的配置
    changeVoiceConfig(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.props.setConfig(name, value);
        console.log(`change ${name} to ${value}`);
        // TODO: 添加数值校验
    }

    // 改变与笔记本有关的配置
    changeNoteConfig(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.props.setConfig(name, value);
        console.log(`change ${name} to ${value}`);
        // TODO: 添加数值校验
    }

    // 改变睡眠相关的配置
    changeSleepConfig(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.props.setConfig(name, value);
        console.log(`change ${name} to ${value}`);
        // TODO: 添加数值校验
    }


    // 改变朗读解析相关的配置
    changeParserConfig(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.props.setConfig(name, value);
        console.log(`change ${name} to ${value}`);
        // TODO: 添加数值校验
    }


    // changeSentenceConfig
    changeSentenceConfig(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.props.setConfig(name, value);
        console.log(`change ${name} to ${value}`);
        // TODO: 添加数值校验
    }


    // 导入用户自定义样式
    changeUserStyle(event) {
        let file = event.target.files[0];
        let reader = new FileReader();

        if (file.type.indexOf("text") === -1) {
            alert("Invalid stylesheet!");
            return;
        }
        reader.readAsText(file);

        reader.onerror = () => alert("Error! Please refresh the page and try again.");
        reader.onload = () => {
            alert("The stylesheet has been imported.");
            let style = Util.parseStyle(reader.result);
            console.log(style);
            localStorage.setItem("style", JSON.stringify(style));
            Util.applyCss();
        };
    }

    // 重置为默认样式
    resetUserStyle() {
        let config = Config.getDefaultConfigObj();
        this.props.setConfig("colors", config.colors);
        this.props.setConfig("padding", config.padding);
        this.props.setConfig("gutter", config.gutter);
        this.props.setConfig("fontSize", config.fontSize);
        this.props.setConfig("lineHeight", config.lineHeight);
        this.props.setConfig("letterSpacing", config.letterSpacing);
        this.props.setConfig("wordSpacing", config.wordSpacing);
        Util.resetStyle();
    }

    // 禁用 popup menu
    disablePopup(event, value) {
        this.props.setConfig("disablePopup", value);
        console.log(`disable popup menu: ${value}`);
    }

    render() {
        let {classes} = this.props;
        let {
            colors,
            gutter,
            padding,
            fontSize,
            lineHeight,
            letterSpacing,
            wordSpacing,
            column,
            disablePopup
        } = this.state;

        let {lan, ctp, vol, per, spd, pit, sleep, content_len, parser, Note} = this.state;

        let style = {
            color0: {
                backgroundColor: colors[0],
            },
            color1: {
                backgroundColor: colors[1],
            },
            color2: {
                backgroundColor: colors[2],
            },
            color3: {
                backgroundColor: colors[3],
            },
        };

        return (
            <div id="settings">
                <Dialog open={this.props.open} onClose={this.handleClose} className={classes.root}>
                    <DialogTitle>Settings</DialogTitle>
                    <DialogContent>
                        <div className={classes.card} style={style.color0}>
                            <div>
                                <Typography align="left" type="subheading">theme</Typography>
                                <Divider/>
                            </div>
                            <div className={classes.box}>
                                <Tooltip placement="bottom" title="light theme">
                                    <SunIcon className={classes.icon} onClick={() => this.changeTheme(true)}/>
                                </Tooltip>
                                <Tooltip placement="bottom" title="dark theme">
                                    <MoonIcon className={classes.icon} style={{marginLeft: 60}}
                                              onClick={() => this.changeTheme(false)}/>
                                </Tooltip>
                                <Tooltip placement="bottom" title="old newspaper">
                                    <MenuBook className={classes.icon} style={{marginLeft: 60}}
                                              onClick={() => this.changeTheme(1)}/>
                                </Tooltip>
                                <Tooltip placement="bottom" title="Pea green">
                                    <RemoveRedEyeIcon className={classes.icon} style={{marginLeft: 60}}
                                                      onClick={() => this.changeTheme(2)}/>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={classes.card} style={style.color0}>
                            <div>
                                <Typography align="left" type="subheading">voice</Typography>
                                <Divider/>
                            </div>
                            <div onBlur={this.changeVoiceConfig} onChange={this.handleOtherChange}>
                                {/*<div className={classes.controlBox}>*/}
                                {/*<span className={classes.caption}>language</span>*/}
                                {/*<div title="选择语言，目前仅支持中英文混合">*/}
                                {/*<select className={classes.selection} name="lan" value={lan}>*/}
                                {/*<option value="zh">中文</option>*/}
                                {/*</select>*/}


                                {/*</div>*/}
                                {/*</div>*/}
                                {/*<div className={classes.controlBox}>*/}
                                {/*<span className={classes.caption}>ctp</span>*/}
                                {/*<div title="选择设备端">*/}

                                {/*<select className={classes.selection} name="ctp" value={ctp}>*/}
                                {/*<option value="1">WEB</option>*/}
                                {/*</select>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>vol</span>
                                    <div title="设置音量">
                                        <select className={classes.selection} name="vol" value={vol}>
                                            <option value="1">1</option>
                                            <option value="3">3</option>
                                            <option value="5">5</option>
                                            <option value="7">7</option>
                                            <option value="9">默认</option>
                                            <option value="11">11</option>
                                            <option value="13">13</option>
                                            <option value="15">15</option>
                                        </select>

                                    </div>
                                </div>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>per</span>
                                    <div title="设置播音">
                                        <select className={classes.selection} name="per" value={per}>
                                            <option value="1">小宇</option>
                                            <option value="4">丫丫</option>
                                            <option value="3">逍遥</option>
                                            <option value="0">小美</option>
                                            {/*<option value="5118">小鹿</option>*/}
                                            {/*<option value="106">博文</option>*/}
                                            {/*<option value="110">小童</option>*/}
                                            {/*<option value="111">小萌</option>*/}
                                            {/*<option value="103">米朵</option>*/}
                                            {/*<option value="5">小娇</option>*/}
                                        </select>


                                    </div>


                                </div>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>spd</span>
                                    <div title="设置音速">
                                        <select className={classes.selection} name="spd" value={spd}>
                                            <option value="2">慢速</option>
                                            <option value="4">常速</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="13">13</option>
                                            <option value="15">15</option>
                                        </select>

                                    </div>
                                </div>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>pit</span>
                                    <div title="设置音调">
                                        <select className={classes.selection} name="pit" value={pit}>
                                            <option value="2">2</option>
                                            <option value="4">4</option>
                                            <option value="5">默认</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card} style={style.color0}>
                            <div>
                                <Typography align="left" type="subheading">Note</Typography>
                                <Divider/>
                            </div>
                            <div onBlur={this.changeNoteConfig} onChange={this.handleOtherChange}>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>Note</span>
                                    <div title="设置右侧Note">
                                        <select className={classes.selection} name="Note" value={Note}>
                                            <option value="https://www.wolai.com/">我来笔记</option>
                                            <option value="https://xiezuocat.com/">写作猫</option>
                                            <option value="https://doc.run/p/">DocRun</option>
                                            <option value="https://markdown.xiaoshujiang.com/">小书匠</option>
                                            <option value="https://dida365.com/webapp/">滴答清单</option>
                                            <option value="https://gitmind.cn/">GitMInd</option>
                                            <option value="https://d.wps.cn/">WPS写得</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card} style={style.color0}>
                            <div>
                                <Typography align="left" type="subheading">sleep</Typography>
                                <Divider/>
                            </div>
                            <div onBlur={this.changeSleepConfig} onChange={this.handleOtherChange}>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>时间</span>
                                    <div title="设定睡眠时间">
                                        <select className={classes.selection} name="sleep" value={sleep}>
                                            <option value="5">5分钟</option>
                                            <option value="10">10分钟</option>
                                            <option value="15">15分钟</option>
                                            <option value="30">30分钟</option>
                                            <option value="45">45分钟</option>
                                            <option value="60">60分钟</option>
                                        </select>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card} style={style.color0}>
                            <div>
                                <Typography align="left" type="subheading">sentence</Typography>
                                <Divider/>
                            </div>
                            <div onBlur={this.changeSentenceConfig} onChange={this.handleOtherChange}>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>句长</span>
                                    <div title="设定句长">
                                        <select className={classes.selection} name="content_len" value={content_len}>
                                            <option value="2">2字符</option>
                                            <option value="5">5字符</option>
                                            <option value="10">10字符</option>
                                            <option value="15">15字符</option>
                                            <option value="30">30字符</option>
                                            <option value="60">60字符</option>
                                            <option value="80">80字符</option>
                                            <option value="100">100字符</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card} style={style.color0}>
                            <div>
                                <Typography align="left" type="subheading">Parser</Typography>
                                <Divider/>
                            </div>
                            <div onBlur={this.changeSentenceConfig} onChange={this.handleOtherChange}>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>解析器</span>
                                    <div title="设定解析器">
                                        <select className={classes.selection} name="parser" value={parser}>
                                            <option value="normal">normal</option>
                                            <option value="zhihu">zhihu</option>
                                            <option value="other">other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card} style={style.color0}>
                            <div>
                                <Typography align="left" type="subheading">color</Typography>
                                <Divider/>
                            </div>
                            <div>
                                <div className={classes.colorBox} onBlur={this.changeColors}
                                     onChange={this.handleColorsChange}>
                                    <div className={classes.colorItem}>
                                        <span className={classes.dot} style={{backgroundColor: colors[0]}}/>
                                        <input className={classes.input} data-color="0" value={colors[0]}/>
                                    </div>
                                    <div className={classes.colorItem}>
                                        <span className={classes.dot} style={{backgroundColor: colors[1]}}/>
                                        <input className={classes.input} data-color="1" value={colors[1]}/>
                                    </div>
                                    <div className={classes.colorItem}>
                                        <span className={classes.dot} style={{backgroundColor: colors[2]}}/>
                                        <input className={classes.input} data-color="2" value={colors[2]}/>
                                    </div>
                                    <div className={classes.colorItem}>
                                        <span className={classes.dot} style={{backgroundColor: colors[3]}}/>
                                        <input className={classes.input} data-color="3" value={colors[3]}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card} style={style.color0}>
                            <div>
                                <Typography align="left" type="subheading">page</Typography>
                                <Divider/>
                            </div>
                            <div>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>column</span>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={column === 1}
                                                    onChange={this.changeColumn}
                                                    classes={{
                                                        checked: classes.checked,
                                                    }}
                                                />
                                            }
                                            label="Use one column"
                                            title="You need to refresh the page."
                                        />
                                    </div>
                                </div>
                                <div className={classes.controlBox} onChange={this.handleOtherChange}>
                                    <span className={classes.caption}>gutter</span>
                                    <div>
                                        <input className={classes.input} name="gutter" value={gutter}
                                               onBlur={this.changeGutter}/>
                                    </div>
                                </div>
                                <div className={classes.controlBox} onChange={this.handleOtherChange}>
                                    <span className={classes.caption}>padding</span>
                                    <div>
                                        <input className={classes.input} name="padding" value={padding}
                                               onBlur={this.changePadding}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card} style={style.color0}>
                            <div>
                                <Typography align="left" type="subheading">font</Typography>
                                <Divider/>
                            </div>
                            <div onBlur={this.changeFontConfig} onChange={this.handleOtherChange}>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>font size</span>
                                    <div title="Valid range: 10-100">


                                        <select className={classes.selection} name="fontSize" value={fontSize}>
                                            <option value="10">10</option>
                                            <option value="0">default</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                            <option value="50">50</option>
                                        </select>


                                        {/*<input className={classes.input} name="fontSize"*/}
                                        {/*value={fontSize === 0 ? "default" : fontSize}/>*/}


                                    </div>
                                </div>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>line height</span>
                                    <div title="Valid range: 1-100">
                                        <input className={classes.input} name="lineHeight"
                                               value={lineHeight === 0 ? "default" : lineHeight}/>
                                    </div>
                                </div>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>letter spacing</span>
                                    <div title="Valid range: 0-100">
                                        <input className={classes.input} name="letterSpacing"
                                               value={letterSpacing === 0 ? "default" : letterSpacing}/>
                                    </div>
                                </div>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>word spacing</span>
                                    <div title="Valid range: 0-100">
                                        <input className={classes.input} name="wordSpacing"
                                               value={wordSpacing === 0 ? "default" : wordSpacing}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card} style={style.color0}>
                            <div>
                                <Typography align="left" type="subheading">style</Typography>
                                <Divider/>
                            </div>
                            <div className={classes.box}>
                                <label htmlFor="custom-style">
                                    <Tooltip placement="bottom" title="import custom stylesheet">
                                        <StyleIcon className={classes.icon}/>
                                    </Tooltip>
                                    <input type="file" accept="text/css" id="custom-style" className={classes.hide}
                                           onChange={this.changeUserStyle}/>
                                </label>
                                <Tooltip placement="bottom" title="reset">
                                    <ResetIcon className={classes.icon} onClick={this.resetUserStyle}
                                               style={{marginLeft: 60}}/>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={classes.card} style={style.color0}>
                            <div>
                                <Typography align="left" type="subheading">other</Typography>
                                <Divider/>
                            </div>
                            <div>
                                <div className={classes.controlBox}>
                                    <span className={classes.caption}>popup</span>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={disablePopup}
                                                    onChange={this.disablePopup}
                                                    classes={{
                                                        checked: classes.checked,
                                                    }}
                                                />
                                            }
                                            label="Disable popup menu"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions color="primary">
                        <Button onClick={this.handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

SettingsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SettingsDialog);
