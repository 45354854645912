import React from "react";
import {grey} from "@material-ui/core/colors";
import PageBase from "../components/PageBase";
import Login_wx from "./Ideahub/components/accounts/Login_wx";
import Login from "./Ideahub/components/accounts/Login";
import SignIn from "./Ideahub/components/accounts/Signin";
import Login_apple from "./Ideahub/components/accounts/Login_apple";
import Login_apple2 from "./Ideahub/components/accounts/Login_apple4";

const LoginPage_wx = () => {
    const styles = {
        toggleDiv: {
            marginTop: 20,
            marginBottom: 5
        },
        toggleLabel: {
            color: grey[400],
            fontWeight: 100
        },
        buttons: {
            marginTop: 30,
            float: "right"
        },
        saveButton: {
            marginLeft: 5
        }
    };

    return (


        <PageBase title="微信扫码 / 密码登录" navigation="Application / 登录">

            <div>

                <Login_wx/>
                <SignIn/>
                {/*<Login_apple/>*/}
                {/*<Login_apple2/>*/}
            </div>


        </PageBase>
    );
};

export default LoginPage_wx;
