import React from "react";
import {grey} from "@material-ui/core/colors";
import PageBase from "../components/PageBase";
import IdeaProjectsInfo from "./Ideahub/components/accounts/IdeaProjectsInfo";
// import Login from './Ideahub/components/App';
import Payment from "../components/Paymen";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import PaymentIcon from "@material-ui/icons/Payment";
import FolderOpenIcon from "./Qreader/components/Navbar";
import store from "./Ideahub/store";
import {getVersion} from "./Ideahub/actions/auth";
import {WechatPay} from "../pages/Ideahub/actions/auth";

const styles = theme => ({
  toggleDiv: {
    marginTop: 20,
    marginBottom: 5
  },
  toggleLabel: {
    color: grey[400],
    fontWeight: 100
  },
  buttons: {
    marginTop: 30,
    float: "right"
  },
  saveButton: {
    marginLeft: 5
  },

  button_pay: {
    marginRight: 5
  },

  button: {
    paddingTop: 6,
    paddingBottom: 6,
  },

});

class IdeaProjectList extends React.Component {


  state = {

    open: false

  };


  componentDidMount() {


  }


  render() {

    const classes = this.props.classes;



    return (
      <PageBase title="闪念项目管理" navigation="Application / IdeaProjectList">
        <IdeaProjectsInfo/>
      </PageBase>
    );
  }


}


IdeaProjectList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,

});


export default withStyles(styles)(connect(mapStateToProps, {

})(IdeaProjectList));
