import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {grey, orange, green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SunIcon from "@material-ui/icons/Brightness5";
import MoonIcon from "@material-ui/icons/Brightness4";
import StyleIcon from "@material-ui/icons/Stars";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import MenuBook from "@material-ui/icons/MenuBook";

import ResetIcon from "@material-ui/icons/Restore";
import Tooltip from "@material-ui/core/Tooltip";
import Config from "../service/Config";
import Util from "../service/Util";

const styles = theme => ({
    root: {},
    card: {
        minWidth: 400,
        minHeight: 60,
        padding: 10,
        margin: "15px auto",
        boxShadow: theme.shadows[1],
        transition: "box-shadow .3s",
        "&:hover": {
            boxShadow: theme.shadows[6],
        },
    },
    key: {

        border: "1px solid gray",
        borderRadius: "3px",
        display: "inline-block",
        width: "60px",
        textAlign: "center"

    },
    icon: {
        color: grey[700],
        "&:hover": {
            color: orange[700],
            cursor: "pointer",
        },
    },
    box: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20 0 10",
    },
    colorBox: {
        width: 360,
        padding: "20px 0 10px",
        margin: "auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
    },
    colorItem: {
        width: "45%",
        margin: "5px 0",
        display: "flex",
        alignItems: "center",
    },
    dot: {
        width: 14,
        height: 14,
        display: "inline-block",
        borderRadius: "50%",
        border: "1px solid black",
        marginRight: 8,
    },

    selection: {

        backgroundColor: "transparent",


    },

    input: {
        outline: "none",
        border: "none",
        width: "80%",
        borderBottom: "1px dotted black",
        backgroundColor: "transparent",
        transition: ".3s",
        "&:hover": {
            borderColor: orange[700],
        },
        "&:focus": {
            borderColor: orange[700],
        },
    },
    controlBox: {
        width: 360,
        display: "flex",
        alignItems: "center",
        padding: "5px 10px",
    },
    caption: {
        width: "15%",
        marginRight: 20,
        fontSize: "small",
        textAlign: "right",
        color: grey[700],
    },
    checked: {
        color: green[500],
    },
    hide: {
        display: "none",
    }
});

class KeyBoardDialog extends React.Component {

    constructor(props) {
        super(props);

        let {
            colors,
            background,
            gutter,
            padding,
            fontSize,
            lineHeight,
            letterSpacing,
            wordSpacing,
            column,
            disablePopup
        } = this.props;
        this.state = {
            colors: colors,
            background: background,
            gutter: gutter,
            padding: padding,
            fontSize: fontSize,
            lineHeight: lineHeight,
            letterSpacing: letterSpacing,
            wordSpacing: wordSpacing,
            column: column,
            disablePopup: disablePopup,
            lan: Config.get().lan ? Config.get().lan : "zh",
            ctp: Config.get().ctp ? Config.get().ctp : 1,
            sleep: Config.get().sleep ? Config.get().sleep : "60",
            vol: Config.get().vol ? Config.get().vol : 9,
            per: Config.get().per ? Config.get().per : 111,
            spd: Config.get().spd ? Config.get().spd : 4,
            pit: Config.get().pit ? Config.get().pit : 5,
        };

        this.handleClose = this.handleClose.bind(this);

    }


    handleClose() {
        this.props.toggleKeyBoardDialog(false);
    }


    render() {
        let {classes} = this.props;
        let {colors} = this.state;
        let style = {
            color0: {
                backgroundColor: colors[0],
            },
            color1: {
                backgroundColor: colors[1],
            },
            color2: {
                backgroundColor: colors[2],
            },
            color3: {
                backgroundColor: colors[3],
            },
        };

        return (
            <div id="settings">
                <Dialog open={this.props.open} onClose={this.handleClose} className={classes.root}>
                    <DialogTitle>快捷键</DialogTitle>
                    <DialogContent>
                        <div className={classes.card} style={style.color0}>
                            <Divider/>
                            <div><span className={classes.key}>↑</span> : 开始/暂停有声朗读 （移动设备：单击屏幕）</div>
                            <Divider/>
                            <div><span className={classes.key}>↓</span> : 标记当前朗读内容、打开笔记 （移动设备：无）</div>
                            <Divider/>
                            <div><span className={classes.key}>Space</span> : 标记当前朗读内容 （移动设备：双击屏幕）</div>
                            <Divider/>
                            <div><span className={classes.key}>Enter</span> : 标记当前朗读内容 （移动设备：双击屏幕）</div>
                            <Divider/>
                            <div><span className={classes.key}>←</span> : 上一句 （移动设备：向下滑屏）</div>
                            <Divider/>
                            <Divider/>
                            <div><span className={classes.key}>→</span> : 下一句 （移动设备：向上滑屏）</div>
                            <Divider/>
                            <div><span className={classes.key}>[</span> : 上一页 （移动设备：向右滑屏）</div>
                            <Divider/>
                            <div><span className={classes.key}>]</span> : 下一页 （移动设备：向左滑屏）</div>
                            <Divider/>
                            <div><span className={classes.key}>PageUp</span> : 上一页 （移动设备：向左滑屏）</div>
                            <Divider/>
                            <div><span className={classes.key}>PageDn</span> : 下一页 （移动设备：向左滑屏）</div>
                            <Divider/>
                            <div><span className={classes.key}>h</span> : 标记当前章节所有标题</div>
                            <Divider/>
                            <div><span className={classes.key}>l</span> : 标记当前章节所有列表</div>
                            <Divider/>
                            <div><span className={classes.key}>b</span> : 标记当前章节所有加粗内容</div>
                            <Divider/>
                            <div><span className={classes.key}>n</span> : 打开/关闭笔记工具</div>
                            <Divider/>

                        </div>

                    </DialogContent>
                    <DialogActions color="primary">
                        <Button onClick={this.handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

KeyBoardDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KeyBoardDialog);


// KeyBoardDialog
