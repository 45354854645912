import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
    getIdeas,
    update_activate_idea
} from "../../actions/ideas";
import {withStyles} from '@material-ui/core/styles';
import Config from "../../../Qreader/service/Config"
import {orange} from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import AutorenewIcon from '@material-ui/icons/Autorenew';


const styles = theme => ({

    root: {
        width: '100%',
        maxWidth: 300,
        // backgroundColor: theme.palette.background.paper,
    },

    book: {
        backgroundColor: theme.palette.background.paper,
    },

    button_group: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: 30

    },

    h1: {

        display: "flex",
        justifyContent: "center",
        fontSize: 30,
        fontWeight: "bold"


    },

    one_red: {
        backgroundColor: "#c0392b",


    },
    idea_pill: {

        color: "#ebebeb",
        // lineHeight: 2,
        textAlign: "left",
        borderRadius: 12.5,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: '100%',
        maxWidth: 280,
        paddingLeft: 10,
        paddingRight: 8,
        // paddingTop: 5,
        cursor: "pointer",
        '&:hover': {
            height: "auto",
            overflow: "visible",
            whiteSpace: "pre-wrap"
        },


    },


    text: {
        display: 'inline-block',
        color: theme.palette.text.primary,
    },
    coverBorder: {
        borderColor: Config.get().theme ? '#000' : '#fff',
        outlineColor: Config.get().theme ? '#000' : '#fff',
    },
    button: {
        fontSize: 28,
        '&:hover': {
            // color: orange[700],
            border: " solid 2px gray",
            borderRadius: 4,

        },
    },
    icon: {
        color: '#fff',
        width: 20,
        height: 20,
        transition: 'color .3s',
        '&:hover': {

            color: orange[700],
        },
    }
});


export class Left_items extends React.Component {

    state = {
        work_type: "classify",
        project: {
            name: "",
            id: "",
            keyword: ""
        }
    }

    constructor() {

        super();

        this.test = function () {
            console.log("clicked")
        }

        // this.activate_one_idea = function (id) {
        //     console.log("clicked")
        // }

        this.idea_level = {
            0: "default_color",
            1: "one_red",
            2: "two_yellow",
            4: "four_purple",
            5: "five_green"
        }

    }



    static propTypes = {
        ideas: PropTypes.array.isRequired,
        work_type: PropTypes.object.isRequired,
        max_page: PropTypes.object.isRequired,
        getIdeas: PropTypes.func.isRequired,
        update_activate_idea: PropTypes.func.isRequired,
    };

    onChange = e => this.setState({[e.target.name]: e.target.value});

    onSearch = () => {
        this.props.getIdeas(this.props.project.name, "p", "", 1, this.props.is_delete, "", this.state.keyword)
        if (this.state.keyword !== "") {
            this.setState({
                keyword: ""
            })
        }
    }


    componentDidMount() {

        this.props.getIdeas();
    }

    componentDidUpdate() {


        console.log("max_page", this.props.max_page)


        console.log(this.props.project)

        if (this.state.work_type != this.props.work_type) {

            if (this.props.work_type === "重要&紧急") {

                this.props.getIdeas("", "", 1);

            } else if (this.props.work_type === "重要&不紧急") {

                this.props.getIdeas("", "", 5);

            } else if (this.props.work_type === "紧急&不重要") {

                this.props.getIdeas("", "", 2);

            } else if (this.props.work_type === "不重要不紧急") {

                this.props.getIdeas("", "", 4);

            } else if (this.props.work_type === "项目任务") {
                this.props.getIdeas("", "pp");

            } else if (this.props.work_type === "任务待分类") {
                this.props.getIdeas("", "");

            } else {
                this.props.getIdeas();

            }


            this.setState({
                work_type: this.props.work_type
            })
        }

        if (this.state.project.name != this.props.project.name) {

            this.setState({
                project: this.props.project
            })

        }
    }


    render() {

        const {classes} = this.props;

        return (
            <div>

                <List component="nav" className={classes.root} aria-label="secondary mailbox folders">
                    <ListItem className={classes.h1}>
                        <a>{this.state.project.name !== "" ? this.state.project.name : this.state.work_type}
                        </a>
                    </ListItem>
                    <ListItem className={classes.button_group}>
                        {/*已完成*/}
                        <svg
                            onClick={this.props.getIdeas.bind(this, "已完成项目", this.props.project.id, this.props.current_color, 1, 0, 1, this.state.keyword)}
                            className={classes.button} width="1em" height="1em" viewBox="0 0 20 20"
                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M17.354 4.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3-3a.5.5 0 11.708-.708L10 11.293l6.646-6.647a.5.5 0 01.708 0z"
                                  clip-rule="evenodd"></path>
                            <path fill-rule="evenodd"
                                  d="M3.5 15A1.5 1.5 0 005 16.5h10a1.5 1.5 0 001.5-1.5v-5a.5.5 0 00-1 0v5a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h8a.5.5 0 000-1H5A1.5 1.5 0 003.5 5v10z"
                                  clip-rule="evenodd"></path>
                        </svg>
                        <svg
                            onClick={this.props.getIdeas.bind(this, this.props.project.name, this.props.project.id, 1, 1, 0, 0, this.state.keyword)}
                            className={classes.button}
                            width="1em"
                            height="1em"
                            viewBox="0 0 20 20"
                            fill="#c0392b"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" x="2" y="2" rx="2"></rect>
                        </svg>
                        <svg
                            onClick={this.props.getIdeas.bind(this, this.props.project.name, this.props.project.id, 2, 1, 0, 0, this.state.keyword)}
                            className={classes.button} width="1em" height="1em"
                            viewBox="0 0 20 20"
                            fill="#e67e22"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" x="2" y="2" rx="2"></rect>
                        </svg>
                        <svg
                            onClick={this.props.getIdeas.bind(this, this.props.project.name, this.props.project.id, 5, 1, 0, 0, this.state.keyword)}
                            className={classes.button} width="1em" height="1em"
                            viewBox="0 0 20 20"
                            fill="#27ae60"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" x="2" y="2" rx="2"></rect>
                        </svg>
                        <svg
                            onClick={this.props.getIdeas.bind(this, this.props.project.name, this.props.project.id, 4, 1, 0, 0, this.state.keyword)}
                            className={classes.button} width="1em" height="1em"
                            viewBox="0 0 20 20"
                            fill="#9b59b6"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" x="2" y="2" rx="2"></rect>
                        </svg>
                        <svg
                            onClick={this.props.getIdeas.bind(this, this.props.project.name, this.props.project.id, 0, 1, 0, 0, this.state.keyword)}
                            className={classes.button} width="1em"
                            height="1em"
                            viewBox="0 0 20 20"
                            fill="#3498db"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" x="2" y="2" rx="2"></rect>
                        </svg>
                        <svg
                            onClick={this.props.getIdeas.bind(this, "已删除项目", this.props.project.id, this.props.current_color, 1, 1, 0, this.state.keyword)}
                            className={classes.button} width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.5 7.5A.5.5 0 018 8v6a.5.5 0 01-1 0V8a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V8a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V8z"></path>
                            <path fill-rule="evenodd"
                                  d="M16.5 5a1 1 0 01-1 1H15v9a2 2 0 01-2 2H7a2 2 0 01-2-2V6h-.5a1 1 0 01-1-1V4a1 1 0 011-1H8a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM6.118 6L6 6.059V15a1 1 0 001 1h6a1 1 0 001-1V6.059L13.882 6H6.118zM4.5 5V4h11v1h-11z"
                                  clip-rule="evenodd"></path>
                        </svg>
                    </ListItem>
                    <ListItem className={classes.button_group}>
                        <input onChange={this.onChange} type="text" name="keyword" placeholder="Search ideas"
                               value={this.state.keyword}/>
                        <svg
                            onClick={this.onSearch.bind(this)}
                            className={classes.button} width="1em" height="1em" viewBox="0 0 20 20"
                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M12.442 12.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                                  clip-rule="evenodd"></path>
                            <path fill-rule="evenodd"
                                  d="M8.5 14a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM15 8.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                                  clip-rule="evenodd"></path>
                        </svg>
                    </ListItem>


                    {this.props.ideas.map((idea, index) => (


                            <ListItem key={index}>
                                <div onClick={this.props.update_activate_idea.bind(this, index)}
                                     className={`${classes.idea_pill} ${this.idea_level[`${idea.color !== null ? idea.color : 0}`]}`}>{idea.text}</div>
                            </ListItem>
                        )
                    )}


                    <ListItem className={classes.button_group}>
                        <NavigateBeforeIcon className={classes.button}
                                            onClick={this.props.getIdeas.bind(this, this.props.project.name, this.props.project.id, this.props.current_color, this.props.current_page - 1, this.props.is_delete, this.props.is_done, this.state.keyword)}/>
                        <AutorenewIcon className={classes.button}
                                       onClick={this.props.getIdeas.bind(this, this.props.project.name, this.props.project.id, this.props.current_color, 1, this.props.is_delete, this.props.is_done, this.state.keyword)}/>
                        <NavigateNextIcon className={classes.button}
                                          onClick={this.props.getIdeas.bind(this, this.props.project.name, this.props.project.id, this.props.current_color, this.props.current_page + 1, this.props.is_delete, this.props.is_done, this.state.keyword)}/>

                    </ListItem>

                </List>


            </div>
        )
    }


}

const mapStateToProps = state => ({
    ideas: state.ideas.ideas,
    work_type: state.ideas.work_type,
    project: state.ideas.project,
    max_page: state.ideas.max_page,
    current_page: state.ideas.current_page,
    current_color: state.ideas.current_color,
    is_delete: state.ideas.is_delete,
    is_done: state.ideas.is_done,
});


Left_items.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, {getIdeas, update_activate_idea})(Left_items));

// export default connect(
//     mapStateToProps,
//     {getIdeas, update_activate_idea}
// )(Left_items);
// export default withStyles(styles)(Book);
