import React from "react";
import Faker from "faker";
import Assessment from "@material-ui/icons/Assessment";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import GridOn from "@material-ui/icons/GridOn";
import PermIdentity from "@material-ui/icons/PermIdentity";
import Web from "@material-ui/icons/Web";
import BorderClear from "@material-ui/icons/BorderClear";
import BorderOuter from "@material-ui/icons/BorderOuter";
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewListIcon from '@material-ui/icons/ViewList';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import ReorderIcon from '@material-ui/icons/Reorder';
import {platform} from "./platform"


const data_all = {
    menus: [
        // {text: "DashBoard", icon: <Assessment/>, link: "/dashboard"},
        // {text: "login", icon: <PermIdentity/>, link: "/login"},
        // {text: "Form Page", icon: <Web/>, link: "/form"},
        {text: "闪电阅读", icon: <MenuBookIcon/>, link: "/apps/books"},
        {text: "闪电复习", icon: <PermMediaIcon/>, link: "/apps/Medias"},
        {text: "闪电想法", icon: <ReorderIcon/>, link: "/apps/ideas"},
        {text: "闪电思考", icon: <BubbleChartIcon style={{fontSize: 33, marginLeft: -5}}/>, link: "/apps/idea_hub"},
        {
            text: "个人中心",
            icon: <PermIdentity/>,
            // link: "/table",
            subMenus: [
                // {
                //     text: "Basic Table",
                //     icon: <BorderClear/>,
                //     link: "/table/basic"
                // },
                // {
                //     text: "Data Table",
                //     icon: <BorderOuter/>,
                //     link: "/table/data"
                // }

                {
                    text: "登录",
                    icon: <PermIdentity/>,
                    link: "/apps/login"
                },
                {
                    text: "登出",
                    icon: <BorderOuter/>,
                    link: "/apps/logout"
                },
                {
                    text: "个人中心",
                    icon: <PermIdentity/>,
                    link: "/apps/account"
                }
                ,
                {
                    text: "书单管理",
                    icon: <ListAltIcon/>,
                    link: "/apps/bookList"
                }
                ,
                {
                    text: "闪念项目",
                    icon: <ViewListIcon/>,
                    link: "/apps/ideaProjects"
                }

            ]
        },

    ],
    user: {
        userName: `${Faker.name.firstName()} ${Faker.name.lastName()}`,
        avatar: Faker.image.avatar()
    },
    tablePage: {
        items: Array.from({length: 105}, (item, index) => ({
            id: index,
            name: Faker.commerce.productName(),
            price: Faker.commerce.price(),
            category: Faker.commerce.productMaterial()
        }))
    },
    dashBoardPage: {
        recentProducts: [
            {
                id: 1,
                title: "Samsung TV",
                text: "Samsung 32 1080p 60Hz LED Smart HDTV."
            },
            {id: 2, title: "Playstation 4", text: "PlayStation 3 500 GB System"},
            {
                id: 3,
                title: "Apple iPhone 6",
                text: "Apple iPhone 6 Plus 16GB Factory Unlocked GSM 4G "
            },
            {
                id: 4,
                title: "Apple MacBook",
                text: "Apple MacBook Pro MD101LL/A 13.3-Inch Laptop"
            }
        ],
        monthlySales: [
            {name: "Jan", uv: 3700},
            {name: "Feb", uv: 3000},
            {name: "Mar", uv: 2000},
            {name: "Apr", uv: 2780},
            {name: "May", uv: 2000},
            {name: "Jun", uv: 1800},
            {name: "Jul", uv: 2600},
            {name: "Aug", uv: 2900},
            {name: "Sep", uv: 3500},
            {name: "Oct", uv: 3000},
            {name: "Nov", uv: 2400},
            {name: "Dec", uv: 2780}
        ],
        newOrders: [
            {pv: 2400},
            {pv: 1398},
            {pv: 9800},
            {pv: 3908},
            {pv: 4800},
            {pv: 3490},
            {pv: 4300}
        ],
        browserUsage: [
            {name: "Chrome", value: 800},
            {name: "Firefox", value: 300},
            {name: "Safari", value: 300}
        ]
    }
};


const data_mac = {
    menus: [
        // {text: "DashBoard", icon: <Assessment/>, link: "/dashboard"},
        // {text: "login", icon: <PermIdentity/>, link: "/login"},
        // {text: "Form Page", icon: <Web/>, link: "/form"},
        {text: "闪电阅读", icon: <MenuBookIcon/>, link: "/apps/books"},
        {
            text: "个人中心",
            icon: <PermIdentity/>,
            // link: "/table",
            subMenus: [
                // {
                //     text: "Basic Table",
                //     icon: <BorderClear/>,
                //     link: "/table/basic"
                // },
                // {
                //     text: "Data Table",
                //     icon: <BorderOuter/>,
                //     link: "/table/data"
                // }

                {
                    text: "登录",
                    icon: <PermIdentity/>,
                    link: "/apps/login"
                },
                {
                    text: "登出",
                    icon: <BorderOuter/>,
                    link: "/apps/logout"
                },
                {
                    text: "个人中心",
                    icon: <PermIdentity/>,
                    link: "/apps/account"
                }
                ,
                {
                    text: "书单管理",
                    icon: <ListAltIcon/>,
                    link: "/apps/bookList"
                }
                ,
                {
                    text: "闪念项目",
                    icon: <ViewListIcon/>,
                    link: "/apps/ideaProjects"
                }

            ]
        },

    ],
    user: {
        userName: `${Faker.name.firstName()} ${Faker.name.lastName()}`,
        avatar: Faker.image.avatar()
    },
    tablePage: {
        items: Array.from({length: 105}, (item, index) => ({
            id: index,
            name: Faker.commerce.productName(),
            price: Faker.commerce.price(),
            category: Faker.commerce.productMaterial()
        }))
    },
    dashBoardPage: {
        recentProducts: [
            {
                id: 1,
                title: "Samsung TV",
                text: "Samsung 32 1080p 60Hz LED Smart HDTV."
            },
            {id: 2, title: "Playstation 4", text: "PlayStation 3 500 GB System"},
            {
                id: 3,
                title: "Apple iPhone 6",
                text: "Apple iPhone 6 Plus 16GB Factory Unlocked GSM 4G "
            },
            {
                id: 4,
                title: "Apple MacBook",
                text: "Apple MacBook Pro MD101LL/A 13.3-Inch Laptop"
            }
        ],
        monthlySales: [
            {name: "Jan", uv: 3700},
            {name: "Feb", uv: 3000},
            {name: "Mar", uv: 2000},
            {name: "Apr", uv: 2780},
            {name: "May", uv: 2000},
            {name: "Jun", uv: 1800},
            {name: "Jul", uv: 2600},
            {name: "Aug", uv: 2900},
            {name: "Sep", uv: 3500},
            {name: "Oct", uv: 3000},
            {name: "Nov", uv: 2400},
            {name: "Dec", uv: 2780}
        ],
        newOrders: [
            {pv: 2400},
            {pv: 1398},
            {pv: 9800},
            {pv: 3908},
            {pv: 4800},
            {pv: 3490},
            {pv: 4300}
        ],
        browserUsage: [
            {name: "Chrome", value: 800},
            {name: "Firefox", value: 300},
            {name: "Safari", value: 300}
        ]
    }
};


const data = platform === "mac" ? data_mac : data_all

export default data;
