import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


class MarkDialog extends React.Component {

  constructor(props) {
    super(props);

  }


  render() {

    let open = this.props.open == undefined ? false : this.props.open;
    let progress = this.props.progress;

    //
    // alert(open)


    return (


      <Dialog open={this.props.open}


        // fullWidth={true}
        // contentStyle={{width: "800px"}}
      >
        <DialogContent


        >
          <div
            id="MarkDialog"
            style={{width: "260px", height: "100px", overflow: "none"}}

          ><p>{progress}</p></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.handleMarkDialogOpen()} color="primary">
            关闭
          </Button>
        </DialogActions>

      </Dialog>
    );
  }
}

export default MarkDialog;
