import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import "./Navigation.css";
import ChapterList from "./ChapterList";
import DBUtil from "../service/DBUtil";

const styles = {
  button: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 10,
    opacity: 0.25,
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },


  },
  list: {
    width: 280,
    flex: "initial",
  },
  title: {
    marginTop: 15,
    marginBottom: 15,
  }
};

class Navigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      chapters: [],
      medias: []
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    // this.goMedia = this.goMedia.bind(this);
  }


  // 打开章节列表
  handleOpen() {
    this.setState({open: true});
  }

  // 关闭章节列表
  handleClose() {
    this.setState({open: false});
  }




  componentDidMount() {

    window.This = this;

    console.log("this.props", this.props);

    const book_name = this.props.book.key;


    console.log("book_namebook_name", book_name);


    let dbAccess = new DBUtil("medias", "media");
    dbAccess.open(
      () => {
        dbAccess.getAll(result => {
          var bookmarksArr;
          bookmarksArr = result.filter(item => item.folder === book_name);

          let medias = bookmarksArr.filter(item => {


            if (item.name.endsWith("mp4") || item.name.endsWith("MP4")) {

              return item;

            }
          });


          this.setState({medias});
        });
      }
    );


  }


  render() {
    const {theme, classes} = this.props;
    let fontColor = {
      color: theme ? "#000000de" : "#fff",
    };

    return (
      <div id="navigation">
        <MenuIcon onClick={this.handleOpen} className={classes.button}/>
        <Drawer
          open={this.state.open}
          onClose={this.handleClose}
          onClick={this.handleClose}
        >
          <div className={classes.title}>
            <Typography type="title" align="center">
              Table Of Contents
            </Typography>
          </div>
          <div id="toc" className={classes.list} style={fontColor}>
            <ChapterList
              chapters={this.state.medias}
              collapse={false}
              goMedia={this.props.goMedia}
            />
          </div>
        </Drawer>
      </div>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigation);
