import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {orange} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {Link} from "react-router-dom";
import Palette from "./Palette";
import "./Book.css";
import Config from "../service/Config";
import DBUtil from "../service/DBUtil";
import {connect} from "react-redux";
import {updateUser} from "../../Ideahub/actions/auth";
import {
    downLoadBook,
    downloadNote,
    updateDownloadNotesStatus,
    updateLocalBook,
    updateNotetoCLoud,
    updateUploadBookStatus,
    uploadBook,
    uploadNote
} from "../../Ideahub/actions/mark";
import {getCoverURL} from "../service/bookUtil";
import newBook from "../model/newBook";
import Note from "../model/Note";
import BackupIcon from "@material-ui/icons/Backup";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PublishIcon from "@material-ui/icons/Publish";
import StatusDialog from "./StatusDialog";
import {ip} from "../../Ideahub/actions/env";


const styles = theme => ({
    book: {
        backgroundColor: theme.palette.background.paper,
    },


    text: {
        marginTop: 20,
        display: "relative",
        color: theme.palette.text.primary,
        fontSize: 10,
        bottom: 0,
        height: 20,
        paddingLeft: 0,
        overflow: "hidden",
        width: 160,
        cursor: "pointer",
        "&:hover": {
            height: "auto",
            overflow: "visible",
            whiteSpace: "pre-wrap"
        },


    },
    bookNameText: {
        marginTop: 5,
        display: "relative",
        textAlign: "center",
        color: theme.palette.text.primary,
        fontSize: 10,
        bottom: 0,
        height: 20,
        paddingLeft: 0,
        overflow: "hidden",
        width: 160,
        cursor: "pointer",
        "&:hover": {

            // content: "book_name"
            // height: "auto",
            // overflow: "visible",
            // whiteSpace: "pre-wrap"
        },


    },
    coverBorder: {
        borderColor: Config.get().theme ? "#000" : "#fff",
        outlineColor: Config.get().theme ? "#000" : "#fff",
    },
    button: {
        width: 28,
        height: 28,
    },
    icon: {
        color: "#fff",
        width: 20,
        height: 20,
        transition: "color .3s",
        "&:hover": {
            color: orange[700],
        },
    }
});


const partSize = 200 * 1024; // 每个分片大小
const parallel = 3; // 同时上传的分片数
const checkpoints = {}; // 所有分片上传文件的检查点

class Book extends React.Component {


    state = {

        uploadDialogOpen: false,
        book: null,
        bookId: 0,
        goldPoints: 0,
        showStatus: false,
        progress: 0.1,
        notes: [],
        sts: {},

    };


    static propTypes = {
        uploadBook: PropTypes.func.isRequired,
        cloudBook: PropTypes.object.isRequired,
        thisBookNotes: PropTypes.object.isRequired,
        uploadBookStatus: PropTypes.object.isRequired,
    };


    browserRedirect() {
        var sUserAgent = navigator.userAgent.toLowerCase();
        var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
        var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
        var bIsMidp = sUserAgent.match(/midp/i) == "midp";
        var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
        var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
        var bIsAndroid = sUserAgent.match(/android/i) == "android";
        var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
        var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
        // document.writeln("您的浏览设备为：");
        if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
            // document.writeln("phone");


            window.read_device = "phone"

            console.log("phonephonephonephon");
            return "phone";

        } else {


            window.read_device = "pc"

            console.log("pcpcpcpcpcpcpcpcpcpcpc");
            return "pc";
            // document.writeln("pc");
        }
    }


    constructor(props) {
        super(props);


        this.state = {
            config: {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${localStorage.getItem("token")}`
                }
            },
            books: [],
            bucket: "ideahub", // bucket名称
            region: "oss-cn-beijing", // oss服务区域名称
            initialBook: {

                key: "100000000000",
                name: "如何阅读一本书",
                content: ""

            },
            uploadDialogOpen: false,
            book: null,
            bookId: 0,
            goldPoints: 0,
            showStatus: false,
            notes: [],
            sts: {}


        };

        this.handleEditBook = this.handleEditBook.bind(this);
        this.handleDeleteBook = this.handleDeleteBook.bind(this);
        this.handleOpenBook = this.handleOpenBook.bind(this);
        this.uploadBook = this.uploadBook.bind(this);
        this.commonDownload = this.commonDownload.bind(this);
        this.ossClient = null; // oss客户端实例

    }


    handleDownload(blob) {

        window.This = this;

        let file = blob;
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = e => {


            try {
                const epub = window.ePub({bookPath: e.target.result});
                epub.getMetadata().then((metadata) => {
                    let name, author, content, description, cover;
                    [name, author, content, description] = [metadata.bookTitle, metadata.creator, metadata.description, e.target.result];

                    getCoverURL(epub, cover => {
                        cover = cover;
                        let book = new newBook(name, author, content, description, cover);


                        book.key = window.This.props.book.key;
                        book.id = window.This.props.book.id;
                        book.owner = window.This.props.auth.user.id;

                        this.handleUpdateBook(book);
                        let bookDBAccess = new DBUtil("books", "book");
                        let bookArr = [];
                        bookDBAccess.open(
                            () => {
                                bookDBAccess.getAll(
                                    (result) => {
                                        bookArr = result;

                                        this.props.updateLocalBook(bookArr);
                                        this.setState({
                                            books: bookArr,
                                            showStatus: false
                                        });
                                    }
                                );
                            }
                        );


                    });

                });
            } catch (err) {
                alert("仅支持epub3.0格式书籍哦");
            }

        };

        reader.onerror = () => {
            alert("Σ(っ °Д °;)っ Some error occurred, please try again!");
        };
    }


    getBlob = (url) => {
        return new Promise(resolve => {
            const xhr = new XMLHttpRequest();

            xhr.open("GET", url, true);
            xhr.responseType = "blob";
            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                }
            };

            xhr.send();
        });
    };

    handleAddBook(book) {
        let bookDBAccess = new DBUtil("books", "book");
        bookDBAccess.open(
            () => {
                bookDBAccess.update(book);
            }
        );
    }


    saveAs = (blob, filename) => {
        if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement("a");
            const body = document.querySelector("body");

            link.href = window.URL.createObjectURL(blob);
            link.download = filename;

            // fix Firefox
            link.style.display = "none";
            body.appendChild(link);

            link.click();
            body.removeChild(link);

            window.URL.revokeObjectURL(link.href);
        }
    };


    handleUpdateBook(book) {

        window.This = this;


        let bookDBAccess = new DBUtil("books", "book");
        bookDBAccess.open(
            () => {
                bookDBAccess.update(book);


                window.This.setState({
                    showStatus: false
                });

                // setTimeout(() => {
                //
                //     window.This.setState({
                //         showStatus: false
                //     })
                //     alert(window.This.state.showStatus)
                //
                //
                // }, 100)
                //

            }
        );
    }


    uploadNotesByBook(book) {

        if (this.props.auth.isAuthenticated) {

            let memberExpireDate = this.props.auth.user.memberExpireDate;

            let expireDate = memberExpireDate.substring(0, 10);
            expireDate = expireDate.replace(/\-/g, "/");
            expireDate = Date.parse(expireDate);
            if (expireDate >= Date.parse(new Date())) {
                this.setState({
                    showStatus: true
                });
                //<editor-fold desc="uploadNotesByBook">
                //获取本书所有Book
                window.This = this;
                new Promise(function (resolve, reject) {

                    let bookDBAccess = new DBUtil("notes", "note");
                    bookDBAccess.open(
                        () => {
                            bookDBAccess.getAll(
                                (result) => {
                                    let notesArr = result;
                                    resolve(notesArr);
                                }
                            );
                        }
                    );


                }).then(function (result) { // (**)
                        // 上传没有上传过的note，更新已上传的note


                        let notesOfThisBook = result.filter(function (item, index, array) {

                            console.log("item", item.bookKey);

                            if (item.bookKey == book.key) {


                                return true;
                            }


                        });

                        // console.log("notesOfThisBook", notesOfThisBook)

                        window.This.setState({notes: notesOfThisBook});

                        // 书主一致，或者书本未上传？
                        if (window.This.props.book.owner === window.This.props.auth.user.id || !window.This.props.book.owner) {

                            notesOfThisBook.map(item => {

                                item.book = window.This.props.book.id;


                                if (item.id === 0 && item.delete_status === 0) {


                                    window.This.props.uploadNote(item, window.This.props.auth.user.id);

                                } else {
                                    window.This.props.updateNotetoCLoud(item.id, item);
                                }
                            });


                        } else {

                            console.log("本书不属于你哦");

                        }


                        return result * 2;
                    }
                )

                    .then(
                        function (result) {
                            window.This.setState({
                                showStatus: false
                            });
                            // alert(result); // 4
                            return result * 2;
                        }
                    )
                ;
                //</editor-fold>
            } else {

                alert("高级会员功能已过期，请续期");
                window.location.href = "#/apps/account";
            }


        } else {

            alert("请先登录");

            window.location.href = "#/apps/logout";

        }


    }


// 添加note
    addNote(note) {
        let dbAccess = new DBUtil("notes", "note");
        dbAccess.open(
            () => {
                dbAccess.add(note);
            }
        );
    }

// 更新note内容
    updateNote(note) {
        let dbAccess = new DBUtil("notes", "note");
        dbAccess.open(
            () => {
                dbAccess.update(note);
            }
        );
    }


    getCloudNotesByBook() {

        if (this.props.auth.isAuthenticated) {


            let memberExpireDate = this.props.auth.user.memberExpireDate;

            let expireDate = memberExpireDate.substring(0, 10);
            expireDate = expireDate.replace(/\-/g, "/");
            expireDate = Date.parse(expireDate);
            if (expireDate >= Date.parse(new Date())) {

                this.setState({

                    showStatus: true,


                });
                this.props.updateDownloadNotesStatus("downloading");
                // 先获取服务器端本书所有Notes（未删除的）
                window.This = this;
                window.This.props.downloadNote(window.This.props.book.key);


            } else {

                alert("高级会员功能已过期，请续期");
                window.location.href = "#/apps/account";
            }


        } else {
            alert("请先登录");
            window.location.href = "#/apps/logout";

        }


    }


    printBook = async function () {

        console.log("book.key", this.props.book.key);
        this.props.book.id = "12132123";
        // this.handleUpdateBook(this.props.book)
        let autoBookmark = localStorage.getItem("autoBookmark");
        autoBookmark = JSON.parse(autoBookmark); //可以将json字符串转换成json对象
        let BookMark = autoBookmark[this.props.book.key];

        console.log("BookMark", BookMark);


    };

// 初始化Book到服务器和OSS
    uploadBook() {


        if (this.props.auth.isAuthenticated) {


            let memberExpireDate = this.props.auth.user.memberExpireDate;

            let expireDate = memberExpireDate.substring(0, 10);
            expireDate = expireDate.replace(/\-/g, "/");
            expireDate = Date.parse(expireDate);
            if (expireDate >= Date.parse(new Date())) {

                //<editor-fold desc="uploadBook">
                console.log("upload book to oss");
                console.log("book.key", this.props.book.name);
                let book = {};
                book.name = this.props.book.name;
                book.bookKey = this.props.book.key;
                console.log("book", book);
                // this.props.uploadBook(book)
                this.commonUpload(this.props.book, book);
                //</editor-fold>


            } else {

                alert("高级会员功能已过期，请续期");
                window.location.href = "#/apps/account";
            }


        } else {

            alert("请先登录");

            window.location.href = "#/apps/logout";

        }


    }


    getAllBooks() {


        window.This = this;


        new Promise(function (resolve, reject) {
            window.This.props.downLoadBook("");
            setTimeout(() => {
                resolve(1);
            }, 1500); // (*)


        }).then(function (result) { // (**)


            try {
                window.This.props.book.sync_status = window.This.props.cloudBooks[0].sync_status;
                window.This.props.book.id = window.This.props.cloudBooks[0].id;
                window.This.handleUpdateBook(window.This.props.book);
            } catch (e) {


            }
            return result * 2;

        });


    }


    updateBookId(book) {

        window.This = this;


        new Promise(function (resolve, reject) {

            window.This.props.downLoadBook(book.key);


            setTimeout(() => {
                resolve(1);
            }, 5000); // (*)


        });


    }


    getCredential(book) {

        window.This = this;

        axios
            .get(ip + "/api/auth/credential", this.state.config)
            .then(res => {
                const {AccessKeyId, AccessKeySecret, SecurityToken} = res.data.cred;
                window.This.ossClient = new window.OSS({
                    accessKeyId: AccessKeyId,
                    accessKeySecret: AccessKeySecret,
                    stsToken: SecurityToken,
                    bucket: window.This.state.bucket,
                    region: window.This.state.region
                });
                const url = window.This.ossClient.signatureUrl(`books/public/${book.key}_=_${book.name}.epub`, {
                    expires: 3600,
                    method: "GET"
                });
                window.This.getBlob(url).then(blob => {
                    window.This.handleDownload(blob, book);
                });


            });
    }


    initOSSClient = async function () {
        window.This = this;
        const {AccessKeyId, AccessKeySecret, SecurityToken} = window.This.state.sts;
        console.log("window.This.state.sts", window.This.state.sts);
        window.This.ossClient = new window.OSS({
            accessKeyId: AccessKeyId,
            accessKeySecret: AccessKeySecret,
            stsToken: SecurityToken,
            bucket: window.This.state.bucket,
            region: window.This.state.region
        });

    };


    // 普通下载 ossClient
    commonDownload = async function (book) {


        if (this.props.auth.isAuthenticated) {

            // alert(book)

            this.setState({

                showStatus: true

            });

            await this.getCredential(book);


        } else {

            alert("您还未登录，请在个人中心中扫码登录");
            window.location.replace("#/apps/login/");

        }


    };

    handleStatusDialogOpen() {

        this.setState({

            showStatus: !this.state.showStatus

        });

    }


// 普通上传
    commonUpload2 = async function (file, book) {


        this.props.updateUploadBookStatus("uploading");


        this.setState({


            showStatus: true

        });

        window.This = this;

        console.log("类型", typeof file.content);

        axios
            .get(ip + "/api/auth/credential", window.This.state.config)
            .then(res => {
                const {AccessKeyId, AccessKeySecret, SecurityToken} = res.data.cred;
                console.log("window.This.state.sts", window.This.state.sts);
                window.This.ossClient = new window.OSS({
                    accessKeyId: AccessKeyId,
                    accessKeySecret: AccessKeySecret,
                    stsToken: SecurityToken,
                    bucket: window.This.state.bucket,
                    region: window.This.state.region
                });
                const fileName = `books/${window.This.props.auth.user.username}/${file.key}_=_${file.name}.epub`;
                let blob = new Blob([file.content]);
                return window.This.ossClient.put(fileName, blob).then(result => {

                    console.log(`Common upload ${file.name} succeeded, result === `, result.res.status);


                    if (result.res.status == 200) {


                        window.This.props.uploadBook(book);
                        window.This.props.updateUploadBookStatus("sucess");


                    }

                }).catch(err => {

                    window.This.props.updateUploadBookStatus("fail");
                    console.log(`Common upload ${file.name} failed === `, err);
                });


            });

    };


    // 分片上传 multipartUpload
    commonUpload = async function (file, book) {
        window.This = this;
        this.props.updateUploadBookStatus("uploading");
        window.This.setState({


            showStatus: true

        });

        console.log("类型", typeof file.content);

        axios
            .get(ip + "/api/auth/credential", window.This.state.config)
            .then(res => {
                const {AccessKeyId, AccessKeySecret, SecurityToken, Expiration} = res.data.cred;
                console.log("window.This.state.sts", window.This.state.sts);
                window.This.ossClient = new window.OSS({
                    accessKeyId: AccessKeyId,
                    accessKeySecret: AccessKeySecret,
                    stsToken: SecurityToken,
                    bucket: window.This.state.bucket,
                    region: window.This.state.region
                });
                const fileName = `books/${window.This.props.auth.user.username}/${file.key}_=_${file.name}.epub`;

                console.log("fileeeeeeee", file);

                let blob = new Blob([file.content]);

                return window.This.ossClient.multipartUpload(fileName, blob, {
                    parallel,
                    partSize,
                    progress: window.This.onMultipartUploadProgress

                }).then(result => {
                    console.log(`Common upload ${file.name} succeeded, result === `, result.res.status);

                    if (result.res.status == 200) {
                        window.This.props.uploadBook(book);
                        window.This.props.updateUploadBookStatus("sucess");
                    }

                }).catch(err => {
                    window.This.props.updateUploadBookStatus("fail");
                    console.log(`Common upload ${file.name} failed === `, err);
                });


            });


    };


    // 分片上传进度改变回调
    onMultipartUploadProgress = async function (progress, checkpoint) {


        // console.log(`上传进度 ${progress}`);
        window.This.setState({
            progress: "已完成" + parseFloat(progress * 100).toFixed(2) + "%"

        });


        checkpoints[checkpoint.uploadId] = checkpoint;
    };


    handleEditBook() {
        console.log("clicked edit button");
        this.props.handleEdit(this.props.book);
    }


    handleDeleteBook() {
        console.log("clicked delete button");
        this.props.handleDelete(this.props.book);
    }


    handleOpenBook() {
        this.props.handleReadingBook(this.props.book);
        this.props.handleReading(true);
    }


    componentDidMount() {
        this.initNotesOfThisBook();
        if (this.props.auth.isAuthenticated) {
            if (this.state.goldPoints !== this.props.auth.goldPoints) {
                this.setState({
                    goldPoints: this.props.auth.user.goldPoints

                });

            }


        }

    }


    initNotesOfThisBook() {

        window.This = this;


        let bookDBAccess = new DBUtil("notes", "note");
        bookDBAccess.open(
            () => {
                bookDBAccess.getAll(
                    (result) => {
                        this.setState({
                            notes: result.filter(function (item) {
                                    if (window.This.props.book && item.bookKey === window.This.props.book.key) {

                                        return true;
                                    }

                                }
                            )


                        });
                    }
                );
            }
        );


    }


    componentWillUnmount() {


    }


    componentDidUpdate(prevProps, prevState, snapshot) {


        window.This = this;


        if (this.state.showStatus) {


            if (this.props.uploadBookStatus === "sucess") {


                if (window.This.props.book.id === 0) {

                    console.log("oss uploaded");
                    // 更新本地书籍的ID和owner
                    if (window.This.props.cloudBook && window.This.props.cloudBook.bookKey === window.This.props.book.key) {
                        window.This.props.book.sync_status = window.This.props.cloudBook.sync_status;
                        window.This.props.book.id = window.This.props.cloudBook.id;
                        window.This.props.book.owner = window.This.props.cloudBook.owner;
                        window.This.handleUpdateBook(window.This.props.book);
                        window.This.setState({

                            showStatus: false


                        });

                    }

                }


            } else if (this.props.uploadBookStatus === "fail") {


                this.setState({

                    showStatus: false


                });
                alert("备份失败，请检查网络");


            }


            if (this.props.downloadNotesStatus === "sucess") {
                this.setState({

                    showStatus: false


                });
                let localNoteKeys = this.state.notes.map(item => {

                    return item.key;

                });
                this.props.thisBookNotes.map(item => {


                    if (localNoteKeys.indexOf(item.key === -1)) {


                        let [bookKey, chapter, text, cfi, range, color, note1] = [item.bookKey, parseInt(item.chapter), item.text, item.cfi, item.rangeHighlight, item.color, item.note];
                        let note = new Note(bookKey, chapter, text, cfi, range, color, note1);
                        note.key = item.key;
                        note.id = item.id;
                        note.owner = item.owner;
                        window.This.addNote(note);
                        console.log("云端notes写入本地", item);


                    } else {

                        let [bookKey, chapter, text, cfi, range, color, note1] = [item.bookKey, parseInt(item.chapter), item.text, item.cfi, item.rangeHighlight, item.color, item.note];
                        let note = new Note(bookKey, chapter, text, cfi, range, color, note1);
                        note.key = item.key;
                        note.id = item.id;
                        note.owner = item.owner;
                        window.This.updateNote(note);
                        console.log("更新本地notes", item);

                    }


                });
                this.initNotesOfThisBook();


            } else if (this.props.downloadNotesStatus === "fail") {
                this.setState({

                    showStatus: false


                });
                alert("云端笔记下载失败");
            }


        }


    }


    render() {


        const {classes} = this.props;

        const defaultCover = require("../../../images/bookCover.jpg");

        let uploadBookIcon = (
            <IconButton title="云端备份本书" className={classes.button} onClick={this.uploadBook} size="small">
                <PublishIcon className={classes.icon} fontSize="inherit"/>
            </IconButton>
        );


        uploadBookIcon = this.props.book.id === 0 ? uploadBookIcon : "";


        let downloadBookIcon = (


            <Button onClick={this.commonDownload.bind(this, this.state.initialBook)}
                    variant="contained"
                    color="primary">下载</Button>


        );


        let device = this.browserRedirect();


        let openBook_phone = (<Link to={`/book/` + this.props.book.key} className="nav-link" target="_self">
            <Button variant="contained" color="primary">阅读</Button>

        </Link>);


        let openBook_pc = (<Link to={`/book/` + this.props.book.key} className="nav-link" target="_blank">
            <Button variant="contained" color="primary">阅读</Button>

        </Link>);


        let openBook = device === "pc" ? openBook_pc : openBook_phone;

        downloadBookIcon = this.props.book.content === "" ? downloadBookIcon : openBook;


        let downloadNoteIcon = (
            <IconButton title="同步笔记到本地" className={classes.button} onClick={this.getCloudNotesByBook.bind(this)}
                        size="small">
                <CloudDownloadIcon className={classes.icon} fontSize="inherit"/>
            </IconButton>
        );

        downloadNoteIcon = (this.props.book.id !== 0 && this.props.book.content !== "") ? downloadNoteIcon : "";


        let uploadNoteIcon = (
            <IconButton title="同步笔记到云端" className={classes.button}
                        onClick={this.uploadNotesByBook.bind(this, this.props.book)}
                        size="small">
                <BackupIcon className={classes.icon} fontSize="inherit"/>
            </IconButton>
        );

        uploadNoteIcon = (this.props.book.id !== 0 && this.props.book.content !== "") ? uploadNoteIcon : "";


        return (

            <div className="bookFrame">


                <div className={`book ${classes.book}`} data-key={this.props.book.key}>
                    <div className="book-head">
                        <img className="bookCover"
                             src={this.props.book.cover ? this.props.book.cover : defaultCover}/>
                        <div className="mask-content">
                            <div className="mask-top">
                                <IconButton title="本地删除本书" className={classes.button} onClick={this.handleDeleteBook}
                                            size="small">
                                    <DeleteIcon className={classes.icon} fontSize="inherit"/>
                                </IconButton>
                                <IconButton title="编辑本书信息" className={classes.button} onClick={this.handleEditBook}
                                            size="small">
                                    <EditIcon className={classes.icon} fontSize="inherit"/>
                                </IconButton>
                                {uploadBookIcon}
                                {downloadNoteIcon}
                                {uploadNoteIcon}

                            </div>
                            <div className="mask-bottom">
                                <Palette>

                                    {downloadBookIcon}
                                </Palette>
                            </div>
                        </div>
                        <div className="mask"/>
                    </div>


                </div>

                <div className={`${classes.bookNameText}  bookName`}>
                    {this.props.book.name}</div>

                <StatusDialog open={this.state.showStatus}
                              progress={this.state.progress}
                              handleStatusDialogOpen={this.handleStatusDialogOpen.bind(this)}
                />

            </div>


        );
    }
}


const mapStateToProps = state => ({
    cloudBook: state.books.cloudBook,
    cloudBooks: state.books.cloudBooks,
    thisBookNotes: state.books.thisBookNotes,
    auth: state.auth,
    uploadBookStatus: state.books.uploadBookStatus,
    downloadNotesStatus: state.books.downloadNotesStatus

});

Book.propTypes = {
    classes: PropTypes.object.isRequired,
};


// export default withStyles(styles)(Book);

export default withStyles(styles)(connect(mapStateToProps, {
    uploadBook,
    downLoadBook,
    updateLocalBook,
    uploadNote,
    downloadNote,
    updateNotetoCLoud,
    updateUploadBookStatus,
    updateDownloadNotesStatus,
    updateUser
})(Book));
