import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import React, {Component} from "react";
import {logout} from "../pages/Ideahub/actions/auth";
import {Redirect} from "react-router-dom";

const drawStyles = theme => {
    return {
        drawerPaper: {
            width: theme.drawer.width,
            backgroundColor: "rgb(33, 33, 33)",
            color: "white",
            borderRight: "0px",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px"
        },
        drawerPaperClose: {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            width: theme.drawer.miniWidth
        },
        logo: {
            cursor: "pointer",
            fontSize: 22,
            color: "white",
            lineHeight: "64px",
            fontWeight: 300,
            backgroundColor: theme.palette.primary[500],
            paddingLeft: 40,
            height: 64
        },
        logomini: {
            fontSize: 0,
        },
        avatarRoot: {
            padding: "16px 0 10px 15px",
            backgroundImage: "url(" + require("../images/material_bg.png") + ")",
            height: 70,
            display: "flex",
            margin: 0
        },
        avatarRootMini: {
            padding: "15px 0 10px 10px"
        },
        avatarIcon: {
            float: "left",
            display: "block",
            boxShadow: "0px 0px 0px 8px rgba(0,0,0,0.2)"
        },
        avatarSpan: {
            paddingTop: 8,
            paddingLeft: 24,
            display: "block",
            color: "white",
            fontWeight: 300,
            textShadow: "1px 1px #444"
        },
        menuItem: {
            color: "white",
            fontSize: 14
        }
    };
};


export class Logout extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired,

    };


    componentDidMount() {

        this.props.logout()
    }

    render() {


        const {isAuthenticated, user} = this.props.auth;

        if (!isAuthenticated) {
            return <Redirect to="/apps/login"/>;
        }

        if (isAuthenticated) {
            return <Redirect to="/"/>;
        }


        return (
            <div>

            </div>
        );
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    logout: state.logout
});


// export default withStyles(drawStyles, {withTheme: true})(LeftDrawer);
export default withStyles(drawStyles, {withTheme: true})(connect(mapStateToProps, {logout})(Logout));
