import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {deleteIdea, updateIdea, doneIdea, addIdea, getIdeas, update_activate_idea} from "../../actions/ideas"
import {withStyles} from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors';
import WaveSurfer from 'wavesurfer.js';
import Config from "../../../Qreader/service/Config"
import CheckIcon from '@material-ui/icons/Check';


const styles = theme => ({

    root: {
        width: '100%',
        maxWidth: 300,
        // backgroundColor: theme.palette.background.paper,
    },


    form: {

        display: "flex",
        justifyContent: "space-evenly",
        // backgroundColor: "#bdc3c7",
        // borderRadius: 20,

    },
    textarea: {

        width: "80%",
        marginLeft: 0,
        borderRadius: 5,


    },
    confirmButton: {

        width: "10%",
        marginRight: 0,
        // borderRadius: 5,

    },
    book: {
        backgroundColor: theme.palette.background.paper,
    },
    button_group: {
        display: "flex",
        justifyContent: "space-evenly",
        fontSize: 50,


    },
    button: {
        '&:hover': {
            // color: orange[700],
            border: " solid 2px gray",
            borderRadius: 4
        },
    },
    h1: {

        display: "flex",
        justifyContent: "center",
        fontSize: 30,
        fontWeight: "bold"


    },
    one_red: {
        backgroundColor: "#c0392b",


    },
    idea_pill: {

        color: "#ebebeb",
        // lineHeight: 2,
        textAlign: "left",
        borderRadius: 12.5,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: '100%',
        maxWidth: 280,
        paddingLeft: 10,
        paddingRight: 8,
        // paddingTop: 5,
        cursor: "pointer",
        '&:hover': {
            height: "auto",
            overflow: "visible",
            whiteSpace: "pre-wrap"
        },


    },


    text: {
        display: 'inline-block',
        color: theme.palette.text.primary,
    },
    coverBorder: {
        borderColor: Config.get().theme ? '#000' : '#fff',
        outlineColor: Config.get().theme ? '#000' : '#fff',
    },
    work_area: {
        width: "100%",
        marginTop: 20,
        marginBottom: 20,
        minHeight: 188,
        backgroundColor: "#bdc3c7",
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        paddingTop: 16
    },
    show_idea: {

        width: "93%",
        minHeight: 100,
        borderRadius: 12,
        margin: "0 auto",
        textAlign: "left",
        padding: 10,
        // paddingTop: 10,
        // paddingLeft: 10,
        // paddingRight: 10,


    },
    icon: {
        color: '#fff',
        width: 20,
        height: 20,
        transition: 'color .3s',
        '&:hover': {
            color: orange[700],
        },
    }
});


export class Work_area extends React.Component {


    state = {
        activate_idea: 0,
        new_idea: "",
        audio_file: null,
        wavesurfer: null,
        AccessKeyId: "LTAI4FkQqWZLLM8qsit1y3wx",
        AccessKeySecret: "D9L78d70CfrbPLofSqW2M3BZbwV0DQ",

        // AccessKeyId: "STS.NUkwNuX7nUpzbYHzBwXXnJtda",
        // AccessKeySecret: "EFh62SFZG9JJvnVeom6esjTht4kjLFiczx3EVZp6TJ2u",
        SecurityToken: "CAIS8wF1q6Ft5B2yfSjIr5bePPTBtehP4rKRYH/5nkIiVNdCpbHPgzz2IH9PfnJuBuEYtf0+n2lT5/oelqNsRpUATkjFYM1stimIUYNEJdivgde8yJBZor/HcDHhJnyW9cvWZPqDP7G5U/yxalfCuzZuyL/hD1uLVECkNpv74vwOLK5gPG+CYCFBGc1dKyZ7tcYeLgGxD/u2NQPwiWeiZygB+CgE0Dggsfrln53AtUuA1gWmktV4/dqhfsKWCOB3J4p6XtuP2+h7S7HMyiY46WIRq/ws1/IbqGmc54DGWgIAv0mcUffM9tpmIBJia7IqyxWKUitGSpcagAEkQ4do1bCP55F7UCmJz7KcA0xdUhPlyjQ6+QoXDr4hjVrLpYHCTnM8m8wUdChv3ZDvgxfsg6FSvRf2iUp5HD3HNn+C3bk4hBqmUMhFdZvrtV6uwTiUBFbHfOgWV8vYllzFITktfT9RuGwm/snH3YjVBOnA5uacxdNEKPz8Uh+d5g==",
        bucket: 'ideahub', // bucket名称
        region: 'oss-cn-beijing', // oss服务区域名称,
        audioUrl: ""
    }

    constructor() {

        super();
        this.test = function () {
            console.log("clicked")
        }
        this.idea_level = {
            0: "default_color",
            1: "one_red",
            2: "two_yellow",
            3: "three_blue",
            4: "four_purple",
            5: "five_green"
        }

    }


    static propTypes = {
        ideas: PropTypes.object.isRequired,
        activate_idea: PropTypes.object.isRequired,
    };

    onChange = e => this.setState({[e.target.name]: e.target.value});
    onFileChange = e => {
        this.setState({[e.target.name]: e.target.files[0]})
        console.log(e.target.files[0])
    };

    onSubmit = e => {

        window.This = this
        e.preventDefault();
        const {new_idea, audio_file} = this.state;
        const idea = {

            text: new_idea,
            // audio: audio_file

        };
        console.log("add idea....................")
        console.log(idea)
        this.props.addIdea(idea);
        this.setState({
            new_idea: "",
        });

        setTimeout(() => {

            window.This.props.getIdeas("", "")

        }, 200)

    };


    clock() {

        let ideas = JSON.parse(JSON.stringify(this.props.ideas))

        let idea = ideas[this.state.activate_idea]

        alert(idea.url)

    }


    initOSSClient = async function () {
        // const { AccessKeyId, AccessKeySecret, SecurityToken } = credentials;


        this.ossClient = new window.OSS({
            accessKeyId: this.state.AccessKeyId,
            accessKeySecret: this.state.AccessKeySecret,
            // stsToken: this.state.SecurityToken,
            bucket: this.state.bucket,
            region: this.state.region
        });


    }


    // 普通下载 ossClient
    getAudioUrl = async function () {


        let ideas = JSON.parse(JSON.stringify(this.props.ideas))

        let idea = ideas[this.state.activate_idea]

        if (!this.ossClient) {
            await this.initOSSClient();
        }
        const url = this.ossClient.signatureUrl(`audios/${this.props.user.username}/${idea.name}`, {
            expires: 3600,
            method: 'GET'
        });
        // alert(url);
        console.log("url。。。。。。。。。。", url)

        this.setState({

            audioUrl: url

        })

        // return url

        // this.getBlob(url).then(blob => {
        //     this.handleDownload(blob);
        // });

    }


    componentDidUpdate() {


        if (this.state.activate_idea !== this.props.activate_idea) {
            this.setState({
                activate_idea: this.props.activate_idea
            })
        }

    }


    render() {

        const {classes} = this.props;


        var playaudio = () => {


            window.This = this


            document.getElementById('waveform').innerHTML = "";
            var wavesurfer = WaveSurfer.create({


                container: '#waveform',
                // waveColor: '#9b59b6',
                // progressColor: 'green',
                hideScrollbar: true,
                height: 8,
                cursorWidth: 3.6,
                audioRate: 1


            });

            console.log("播放")
            // let audio_url = document.getElementsByTagName("audio")[0].getAttribute("src")

            // let audio_url = String(audios[this.state.activate_idea]).replace("api/ideas/frontend/", "")


            new Promise(function (resolve, reject) {

                window.This.getAudioUrl()
                resolve(1)


            }).then(function (result) { // (**)

                let audio_url = window.This.state.audioUrl

                wavesurfer.load(audio_url);
                wavesurfer.on('ready', function () {
                    wavesurfer.play();

                });
                return result * 2;

            })


        }


        const {new_idea} = this.state;

        let ideas = JSON.parse(JSON.stringify(this.props.ideas))
        let texts = []
        let colors = []
        let ids = []
        let audios = []

        ideas.map(
            item => {
                texts.push(item.text)
                colors.push(item.color)
                ids.push(item.id)
                audios.push(item.audio)


            }
        )


        let delete_idea = {}
        delete_idea.id = ids[this.state.activate_idea]
        delete_idea.delete_status = 1

        let current_idea = {}
        current_idea.id = ids[this.state.activate_idea]
        current_idea.is_done = 1

        let idea_zero = {}
        idea_zero.id = ids[this.state.activate_idea]
        idea_zero.color = 0

        let idea_one = {}
        idea_one.id = ids[this.state.activate_idea]
        idea_one.color = 1


        let idea_two = {}
        idea_two.id = ids[this.state.activate_idea]
        idea_two.color = 2


        let idea_three = {}
        idea_three.id = ids[this.state.activate_idea]
        idea_three.color = 3

        let idea_five = {}
        idea_five.id = ids[this.state.activate_idea]
        idea_five.color = 5

        let idea_four = {}
        idea_four.id = ids[this.state.activate_idea]
        idea_four.color = 4


        return (


            <div className="middle_main">


                <div className={classes.button_group}>

                    <svg onClick={this.props.updateIdea.bind(this, idea_one, ids[this.state.activate_idea], 1)}
                         className={classes.button} width="1em"
                         height="1em"
                         viewBox="0 0 20 20"
                         fill="#c0392b"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="16" x="2" y="2" rx="2"></rect>
                    </svg>
                    <svg onClick={this.props.updateIdea.bind(this, idea_two, ids[this.state.activate_idea], 2)}
                         className={classes.button} width="1em" height="1em"
                         viewBox="0 0 20 20"
                         fill="#e67e22"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="16" x="2" y="2" rx="2"></rect>
                    </svg>
                    <svg onClick={this.props.updateIdea.bind(this, idea_five, ids[this.state.activate_idea], 5)}
                         className={classes.button} width="1em" height="1em"
                         viewBox="0 0 20 20"
                         fill="#27ae60"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="16" x="2" y="2" rx="2"></rect>
                    </svg>
                    <svg onClick={this.props.updateIdea.bind(this, idea_four, ids[this.state.activate_idea], 4)}
                         className={classes.button} width="1em" height="1em" viewBox="0 0 20 20"
                         fill="#9b59b6"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="16" x="2" y="2" rx="2"></rect>
                    </svg>
                    <svg onClick={this.props.updateIdea.bind(this, idea_zero, ids[this.state.activate_idea], 0)}
                         className={classes.button} width="1em" height="1em"
                         viewBox="0 0 20 20"
                         fill="#3498db"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="16" x="2" y="2" rx="2"></rect>
                    </svg>
                </div>


                <div className={classes.work_area}>

                    <div
                        className={`${classes.show_idea} ${this.idea_level[`${colors[this.state.activate_idea] !== null ? colors[this.state.activate_idea] : 0}`]}`}>
                        <span>{texts[this.state.activate_idea]}</span>

                    </div>
                    <div id="waveform"></div>

                    <span className={classes.button_group}>
                        <svg onClick={this.getAudioUrl.bind(this)} className={classes.button} width="1em"
                             height="1em" viewBox="0 0 20 20"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M10 17a6 6 0 100-12 6 6 0 000 12zm0 1a7 7 0 100-14 7 7 0 000 14z"
                                  clip-rule="evenodd"></path>
                            <path fill-rule="evenodd"
                                  d="M10 6.5a.5.5 0 01.5.5v4a.5.5 0 01-.053.224l-1.5 3a.5.5 0 11-.894-.448L9.5 10.882V7a.5.5 0 01.5-.5z"
                                  clip-rule="evenodd"></path>
                            <path
                                d="M2.86 7.387A2.5 2.5 0 116.387 3.86 8.035 8.035 0 002.86 7.387zM13.613 3.86a2.5 2.5 0 113.527 3.527 8.035 8.035 0 00-3.527-3.527z"></path>
                            <path fill-rule="evenodd"
                                  d="M13.646 16.146a.5.5 0 01.708 0l1 1a.5.5 0 01-.708.708l-1-1a.5.5 0 010-.708zm-7.292 0a.5.5 0 00-.708 0l-1 1a.5.5 0 00.708.708l1-1a.5.5 0 000-.708zM7.5 2.5A.5.5 0 018 2h4a.5.5 0 010 1H8a.5.5 0 01-.5-.5z"
                                  clip-rule="evenodd"></path>
                            <path d="M9 3h2v2H9V3z"></path>
                        </svg>
                        <svg onClick={this.props.doneIdea.bind(this, current_idea, ids[this.state.activate_idea])}
                             className={classes.button}
                             width="1em"
                             height="1em" viewBox="0 0 20 20"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M15.854 5.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L8.5 12.293l6.646-6.647a.5.5 0 01.708 0z"
                                  clip-rule="evenodd"></path>
                        </svg>
                        <svg onClick={playaudio} className={classes.button} width="1em"
                             height="1em"
                             viewBox="0 0 20 20"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.596 10.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V6.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 010 1.393z"></path>
                        </svg>
                        <svg
                            onClick={this.props.deleteIdea.bind(this, delete_idea, ids[this.state.activate_idea])}
                            className={classes.button}
                            width="1em"
                            height="1em" viewBox="0 0 20 20"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.5 7.5A.5.5 0 018 8v6a.5.5 0 01-1 0V8a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V8a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V8z"></path>
                            <path fill-rule="evenodd"
                                  d="M16.5 5a1 1 0 01-1 1H15v9a2 2 0 01-2 2H7a2 2 0 01-2-2V6h-.5a1 1 0 01-1-1V4a1 1 0 011-1H8a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM6.118 6L6 6.059V15a1 1 0 001 1h6a1 1 0 001-1V6.059L13.882 6H6.118zM4.5 5V4h11v1h-11z"
                                  clip-rule="evenodd"></path>
                        </svg>
                        <svg data-title="退出项目"
                             onClick={this.props.updateIdea.bind(this, {project: null}, ids[this.state.activate_idea], "c")}
                             className={classes.button} width="1em" height="1em"
                             viewBox="0 0 20 20" fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M11.502 7.513a.144.144 0 00-.202.134V8.65a.5.5 0 01-.5.5H4.5v2.9h6.3a.5.5 0 01.5.5v1.003c0 .108.11.176.202.134l3.984-2.933a.522.522 0 01.042-.028.147.147 0 000-.252.523.523 0 01-.042-.028l-3.984-2.933zm-1.202.134a1.144 1.144 0 011.767-.96l3.994 2.94a1.147 1.147 0 010 1.946l-3.994 2.94a1.144 1.144 0 01-1.767-.96v-.503H4a.5.5 0 01-.5-.5v-3.9a.5.5 0 01.5-.5h6.3v-.503z"
                                      clip-rule="evenodd"></path>
                                </svg>
                    </span>


                </div>


                <form onSubmit={this.onSubmit} className={classes.form}>
                   <textarea
                       className={classes.textarea}
                       placeholder="记录点滴新想法..."
                       type="text"
                       name="new_idea"
                       onChange={this.onChange}
                       value={new_idea}
                   />

                    <button type="submit" className={classes.confirmButton}>
                        OK
                    </button>
                </form>

            </div>
        )
    }


}


// export default Work_area;


const mapStateToProps = state => ({
    ideas: state.ideas.ideas,
    activate_idea: state.ideas.activate_idea,
    work_type: state.ideas.work_type,
    user: state.auth.user
});

// export default connect(
//     mapStateToProps,
//     {deleteIdea, updateIdea, doneIdea, addIdea}
// )(Work_area);


Work_area.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, {
    deleteIdea,
    updateIdea,
    doneIdea,
    addIdea,
    getIdeas
})(Work_area));
