import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {getbooks} from "../pages/Ideahub/actions/books";
import {updateUser} from "../pages/Ideahub/actions/auth";
import DBUtil from "../pages/Qreader/service/DBUtil";
import {getCoverURL} from "../pages/Qreader/service/bookUtil";
import Book from "../pages/Qreader/model/Book";
import Dialog from "@material-ui/core/Dialog";
import {updateLocalBook} from "../pages/Ideahub/actions/mark";
import StatusDialog from "../pages/Qreader/components/StatusDialog";
import {Button} from "@material-ui/core";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Config from "../pages/Qreader/service/Config"


const styles = theme => ({
    result: {


        maxWidth: 500,
        maxHeight: 800,
        backgroundColor: "#ffffff",
        zIndex: 1000,
        overflow: "scroll",
        borderRadius: 8,


    },
    button3: {
        paddingTop: 6,
        paddingBottom: 6,
    },
    button2: {
        marginRight: 5
    },
    closeButton: {

        "&:hover": {
            // color: orange[700],
            border: " solid 2px gray",
            borderRadius: 4,
            // color: "red"
        }

    },
    button_group: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: 30

    },

    pageIndex: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#F5C28A"
        },
    },

    button: {
        fontSize: 28,
        "&:hover": {
            // color: orange[700],
            border: " solid 2px gray",
            borderRadius: 4,

        },
    },
    emphasis: {
        fontWeight: "bolder",
        // color: "blue"
        // fontSize: 200
    },
    page: {

        fontSize: 16

    }
});

function Base64() {

    // private method for UTF-8 encoding
    let _utf8_encode = function (string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";
        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);
            if (c < 128) {
                utftext += String.fromCharCode(c);
            } else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }
        return utftext;
    }

    // private method for UTF-8 decoding
    let _utf8_decode = function (utftext) {
        var string = "";
        var i = 0;
        let c = 0;
        let c1 = 0;
        let c2 = 0;
        let c3 = 0;
        while (i < utftext.length) {
            c = utftext.charCodeAt(i);
            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            } else if ((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            } else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }
        return string;
    }

    // private property
    let _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    // public method for encoding
    this.encode = function (input) {
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
        input = _utf8_encode(input);
        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output +
                _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
                _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
        }
        return output;
    }

    // public method for decoding
    this.decode = function (input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < input.length) {
            enc1 = _keyStr.indexOf(input.charAt(i++));
            enc2 = _keyStr.indexOf(input.charAt(i++));
            enc3 = _keyStr.indexOf(input.charAt(i++));
            enc4 = _keyStr.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output = output + String.fromCharCode(chr1);
            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }
        }
        output = _utf8_decode(output);
        return output;
    }


}

function decode_book_url(encoded_url) {

    let result = encoded_url.slice(0, -5) + "="
    let base = new Base64();
    let result2 = base.decode(result);
    // console.log("decode_book_url", result2)
    return result2

}


class SearchResult extends React.Component {


    // constructor(props) {
    //     super(props);
    //     this.state = {};
    //     this.closeResult = this.closeResult.bind(this);
    //
    // }


    state = {

        books: [],
        progress: 0,
        searchTime: "",
        showResults: false,
        goldPoints: 0,
        showStatus: false,
        initialBook: {

            key: "100000000000",
            name: "如何阅读一本书",
            content: ""


        }

    };


    static propTypes = {
        books: PropTypes.array.isRequired,
        searchTime: PropTypes.object.isRequired,
        book_name: PropTypes.object.isRequired,
        pn: PropTypes.object.isRequired,
        total: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
    };

    handleDownload(blob) {
        let file = blob;
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = e => {


            try {
                const epub = window.ePub({bookPath: e.target.result});
                epub.getMetadata().then((metadata) => {
                    let name, author, content, description, book, cover;
                    [name, author, content, description] = [metadata.bookTitle, metadata.creator, metadata.description, e.target.result];
                    getCoverURL(epub, cover => {
                        cover = cover;
                        book = new Book(name, author, content, description, cover);
                        this.handleAddBook(book);
                        let bookDBAccess = new DBUtil("books", "book");
                        let bookArr = [];
                        bookDBAccess.open(
                            () => {
                                bookDBAccess.getAll(
                                    (result) => {
                                        bookArr = result;
                                        this.props.updateLocalBook(bookArr);
                                        this.setState({
                                            books: bookArr,
                                            showStatus: false
                                        });
                                    }
                                );
                            }
                        );


                    });

                });
            } catch (err) {


                this.setState({
                    showStatus: false


                });

                alert("仅支持epub3.0格式书籍哦");
            }

        };

        reader.onerror = () => {
            alert("Σ(っ °Д °;)っ Some error occurred, please try again!");
        };
    }


    handleAddBook(book) {
        let bookDBAccess = new DBUtil("books", "book");
        bookDBAccess.open(
            () => {
                bookDBAccess.add(book);
                let bookArr = this.state.books;
                try {
                    bookArr.push(book);
                } catch (e) {

                    console.log("we4dcwww", e)

                }
                let keyList = bookArr.map(item => item.key);
                if (keyList && keyList.indexOf("100000000000") === -1) {

                    this.setState({
                        books: [...bookArr, this.state.initialBook],
                        showStatus: false


                    },);
                    this.props.updateLocalBook([...bookArr, this.state.initialBook]);


                } else {

                    this.setState({
                        books: bookArr,
                        showStatus: false
                    });
                    this.props.updateLocalBook(bookArr);

                }


                // this.setState({
                //
                //     books: bookArr,
                //     showStatus: false
                //
                //
                // });
            }
        );
    }


    handleStatusDialogOpen() {

        this.setState({

            showStatus: !this.state.showStatus

        });

    }


    getBlob = (url) => {

        if (url.endsWith("epu")) {
            url = url + "b"
        }


        // url = url + "b"
        console.log("get blob url ", url)
        window.This = this;
        return new Promise(resolve => {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "blob";
            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                }
            };
            //监听进度事件
            xhr.addEventListener("progress", function (evt) {
                if (evt.lengthComputable) {
                    var percentComplete = evt.loaded / evt.total;
                    window.This.setState({
                        progress: "已完成" + parseFloat(percentComplete * 100).toFixed(2) + "%"

                    });

                }
            }, false);

            xhr.send();
        });
    };


    saveAs = (blob, filename) => {
        if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement("a");
            const body = document.querySelector("body");

            link.href = window.URL.createObjectURL(blob);
            link.download = filename;

            // fix Firefox
            link.style.display = "none";
            body.appendChild(link);

            link.click();
            body.removeChild(link);


            window.URL.revokeObjectURL(link.href);

        }
    };


    downloadPDF = (url, filename, isAuthenticated) => {


        if (isAuthenticated) {


            if (this.state.goldPoints <= 0) {

                alert("金豆数量不足，请充值。");
                // window.location.href = "#/apps/account";
                this.setState({

                    showResults: false

                });
                window.location.replace("#/apps/account");

            } else {


                console.log("下载文件");

                window.open(url, "_blank")

                // this.getBlob(url).then(blob => {
                //     this.saveAs(blob, filename);
                // });

                //<editor-fold desc="扣点数">
                let goldPoints = "- 1";
                this.props.updateUser(goldPoints);
                this.setState({

                    goldPoints: this.state.goldPoints - 1

                });
                //</editor-fold>


            }


        } else {

            alert("您还未登录，请在个人中心中扫码登录");

            this.setState({

                showResults: false

            });


            window.location.replace("#/apps/login/");

        }


    };

    downloadBook = (url, filename, isAuthenticated) => {


        if (isAuthenticated) {


            if (this.state.goldPoints <= 0) {

                alert("金豆数量不足，请充值。");
                // window.location.href = "#/apps/account";
                this.setState({

                    showResults: false

                });
                window.location.replace("#/apps/account");

            } else {
                console.log("下载文件");
                this.setState({

                    showStatus: true

                });
                console.log("下载文件");
                this.getBlob(url).then(blob => {
                    this.handleDownload(blob);
                });

                //<editor-fold desc="扣点数">
                let goldPoints = "- 1";
                this.props.updateUser(goldPoints);
                this.setState({

                    goldPoints: this.state.goldPoints - 1

                });
                //</editor-fold>


            }


        } else {

            alert("您还未登录，请在个人中心中扫码登录");

            this.setState({

                showResults: false

            });


            window.location.replace("#/apps/login/");

        }


    };


    onChange = e => {
        console.log(e.target.name, e.target.value);

        if (parseInt(this.props.pn) < this.props.total / 10) {
            this.props.getbooks(this.props.book_name, parseInt(e.target.value));
        }

    };


    loadBook = (url, filename, isAuthenticated) => {
        if (isAuthenticated) {
            let memberExpireDate = this.props.auth.user.memberExpireDate;
            let expireDate = memberExpireDate.substring(0, 10);
            expireDate = expireDate.replace(/\-/g, "/");
            expireDate = Date.parse(expireDate);
            if (expireDate >= Date.parse(new Date())) {
                this.setState({

                    showStatus: true

                });
                console.log("下载文件");
                this.getBlob(url).then(blob => {
                    this.handleDownload(blob);
                });
            } else {
                alert("高级会员功能已过期，请续期");
                window.location.href = "#/apps/account";
            }


        } else {

            alert("您还未登录，请在个人中心中扫码登录");

            this.setState({

                showResults: false

            });
            window.location.replace("#/apps/login/");

        }


    };


    onSearchForward = () => {

        console.log("搜索下一页");
        console.log(this.props.total);

        if (parseInt(this.props.pn) < this.props.total / 10) {
            this.props.getbooks(this.props.book_name, parseInt(this.props.pn) + 1);

        }


    };


    onSearchBackward = () => {

        if (parseInt(this.props.pn) - 1 > 0)


            this.props.getbooks(this.props.book_name, parseInt(this.props.pn) - 1);
    };


    closeResult = () => {

        localStorage.setItem("searchTime", 0);


        this.setState({
            showResults: false
        });


    };


    componentDidMount() {

        window.This = this


        if (this.props.auth.isAuthenticated) {
            if (this.state.goldPoints !== this.props.auth.goldPoints) {
                this.setState({
                    goldPoints: this.props.auth.user.goldPoints

                });

            }


        }

    }


    componentDidUpdate(prevProps, prevState, snapshot) {


        if ((localStorage.getItem("searchTime") != this.props.searchTime) && (localStorage.getItem("searchTime") != 0)) {

            localStorage.setItem("searchTime", this.props.searchTime);

            this.setState({

                searchTime: this.props.searchTime,
                showResults: true,


            });

            if (this.props.auth.isAuthenticated) {

                this.setState({
                    goldPoints: this.props.auth.user.goldPoints

                });

            }

        }


    }


    render() {
        const {classes} = this.props;
        const {isAuthenticated} = this.props.auth;

        let range = (start, end) => new Array(end - start).fill(start).map((el, i) => start + i);


        // const {isAuthenticated} = true;

        let Result = (

            //onBlur={this.closeResult.bind(this)}
            <div className={classes.result}>

                <List component="nav" aria-label="main mailbox folders">
                    <ListItem>
                        <ListItemText className={classes.emphasis}>
                            {this.props.books.length}个搜索结果
                        </ListItemText>

                        {/*<ListItemIcon>*/}
                        {/*<CloseIcon className={classes.button}*/}
                        {/*onClick={this.closeResult.bind(this)}/>*/}
                        {/*</ListItemIcon>*/}


                        <Button
                            title="点击关闭"
                            onClick={this.closeResult.bind(this)}
                            component="span" aria-label="sync"
                            className={classes.button3}>
                            <CloseIcon className={classes.button2}/>
                        </Button>


                    </ListItem>


                    {this.props.books.map((book, index) => (
                            <ListItem button
                                      key={index}>
                                <ListItemText
                                    primary={book.name}/>
                                {book.name.endsWith("epub") ?
                                    <Button
                                        title="下载"
                                        onClick={this.downloadBook.bind(this, decode_book_url(book.info), book.name, isAuthenticated)}
                                        component="span" aria-label="sync"
                                        color="primary"
                                        className={classes.button3}>
                                        <GetAppIcon className={classes.button2}/>
                                    </Button> :
                                    <Button
                                        title="点击下载"
                                        onClick={this.downloadBook.bind(this, book.url, book.title, isAuthenticated)}
                                        component="span" aria-label="sync"
                                        // color="primary"
                                        className={classes.button3}>
                                        <GetAppIcon className={classes.button2}/>
                                    </Button>}
                            </ListItem>


                        )
                    )}

                    {/*<ListItem className={classes.button_group}>*/}
                    {/*    <NavigateBeforeIcon onClick={this.onSearchBackward.bind(this)} className={classes.button}/>*/}
                    {/*    <span className={classes.page}>{`第`}</span>*/}
                    {/*    <FormControl*/}
                    {/*        margin="dense"*/}
                    {/*    >*/}
                    {/*        /!*<InputLabel htmlFor="Father">指定页数</InputLabel>*!/*/}
                    {/*        <Select value={this.props.pn}*/}
                    {/*                name="Parent"*/}
                    {/*                onChange={this.onChange}*/}
                    {/*        >*/}

                    {/*            {range(1, Math.ceil(this.props.total / 10) + 1 ? Math.ceil(this.props.total / 10) + 1 : 1).map((name, index) => {*/}
                    {/*                    if (name) {*/}
                    {/*                        return <p key={index}*/}
                    {/*                                  className={classes.pageIndex}*/}
                    {/*                                  value={index + 1}>{index + 1}</p>;*/}
                    {/*                    }*/}
                    {/*                }*/}
                    {/*            )}*/}
                    {/*        </Select>*/}
                    {/*    </FormControl>*/}
                    {/*    /!*<span className={classes.page}>{`页/共${Math.ceil(this.props.total / 10)}页`}</span>*!/*/}

                    {/*    <NavigateNextIcon onClick={this.onSearchForward.bind(this)} className={classes.button}/>*/}

                    {/*</ListItem>*/}


                </List>

            </div>


        );

        let show = this.state.showResults ? Result : "";


        return (
            <Dialog open={this.state.showResults} onClose={this.closeResult.bind(this)}>

                <StatusDialog open={this.state.showStatus}
                              handleStatusDialogOpen={this.handleStatusDialogOpen.bind(this)}

                              progress={this.state.progress}
                />


                {show}


                {/*<Divider/>*/}


            </Dialog>
        );
    }


}


SearchResult.propTypes = {
    styles: PropTypes.object,
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    books: state.books.books,
    searchTime: state.books.searchTime,
    book_name: state.books.book_name,
    pn: state.books.pn,
    total: state.books.total,
    auth: state.auth,

});


export default withStyles(styles)(connect(mapStateToProps, {getbooks, updateUser, updateLocalBook})(SearchResult));
