var I64BIT_TABLE =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-".split("");


function hash(input) {
  var hash = 5381;
  var i = input.length - 1;

  if (typeof input == "string") {
    for (; i > -1; i--)
      hash += (hash << 5) + input.charCodeAt(i);
  } else {
    for (; i > -1; i--)
      hash += (hash << 5) + input[i];
  }
  var value = hash & 0x7FFFFFFF;

  var retValue = "";
  do {
    retValue += I64BIT_TABLE[value & 0x3F];
  }
  while (value >>= 6);

  return retValue;
}


class Note {

  constructor(bookKey, chapter, text, cfi, range, color, note) {

    this.id = 0;
    // this.key = new Date().getTime() + "";
    this.key = hash(bookKey + range);
    this.bookKey = bookKey;
    this.chapter = chapter;
    this.text = text;
    this.cfi = cfi;
    this.range = range; // Rangy.js产生的将Range对象序列化后的字符串
    this.color = color; // 高亮的颜色类型：0, 1, 2, 3
    this.note = note || "";
    this.delete_status = 0;
  }
}

export default Note;
