import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors';
import {connect} from "react-redux";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {update_work_type} from "../../actions/ideas"

const styles = theme => ({
    book: {
        backgroundColor: theme.palette.background.paper,
    },
    classification: {
        width: 180,
        height: 50,
        fontSize: 20,
        lineHeight: 2.4,
        fontWeight: "bold",
        cursor: "pointer",
        textAlign: "center",
        color: "#ebebeb",
        '&:hover': {
            color: "#2c3e50",
            fontSize: 26,
            lineHeight: 2,
        },
    },

    icon: {
        color: '#fff',
        width: 20,
        height: 20,
        transition: 'color .3s',
        '&:hover': {
            color: orange[700],
        },
    }
});


export class Right_classification extends React.Component {


    state = {
        work_type: "classify"
    }

    constructor() {

        super();

        this.test = function () {
            console.log("clicked")
        }

        // this.activate_one_idea = function (id) {
        //     console.log("clicked")
        // }

        this.idea_level = {
            0: "default_color",
            1: "one_red",
            2: "two_yellow",
            3: "three_blue",
            4: "four_purple",
            5: "five_green"
        }

    }


    static propTypes = {
        work_type: PropTypes.object.isRequired,
    };


    componentDidUpdate() {

        console.log("work_type")

        console.log(this.state.work_type)

        if (this.state.work_type != this.props.work_type) {
            this.setState({
                work_type: this.props.work_type
            })
        }
    }


    render() {
        const {classes} = this.props;
        return (
            <List component="nav" aria-label="secondary mailbox folders">

                <ListItem>
                    <div onClick={this.props.update_work_type.bind(this, "重要&紧急")} style={{background: "#c0392b"}}
                         className={classes.classification}>重要&紧急
                    </div>
                </ListItem>
                <ListItem>
                    <div onClick={this.props.update_work_type.bind(this, "紧急&不重要")} style={{background: "#e67e22"}}
                         className={classes.classification}>紧急&不重要
                    </div>
                </ListItem>
                <ListItem>
                    <div onClick={this.props.update_work_type.bind(this, "重要&不紧急")} style={{background: "#27ae60"}}
                         className={classes.classification}>重要&不紧急
                    </div>
                </ListItem>
                <ListItem>
                    <div onClick={this.props.update_work_type.bind(this, "不重要不紧急")} style={{background: "#9b59b6"}}
                         className={classes.classification}>不重要&不紧急
                    </div>
                </ListItem>

                <ListItem>
                    <div onClick={this.props.update_work_type.bind(this, "任务待分类")} style={{background: "#3498db"}}
                         className={classes.classification}>任务待分类
                    </div>
                </ListItem>
                <ListItem>
                    <div onClick={this.props.update_work_type.bind(this, "项目任务")} style={{background: "#e1b12c"}}
                         className={classes.classification}>项目任务
                    </div>
                </ListItem>


            </List>
        )
    }


}


// export default Right_classification;


const mapStateToProps = state => ({
    work_type: state.ideas.work_type
});

// export default Left_items;

// export default connect(
//     mapStateToProps,
//     {update_work_type}
// )(Right_classification);


Right_classification.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, {update_work_type})(Right_classification));
