import axios from "axios";
import {createMessage, returnErrors} from "./messages";
import {tokenConfig, tokenConfig_file} from "./auth";
import {ip} from "./env"
import {
    GET_IDEAS,
    DELETE_IDEA,
    ADD_IDEA,
    UPDATE_IDEA,
    DONE_IDEA,
    ACTIVATE_IDEA,
    UPDATE_WORK_TYPE, LOGOUT_SUCCESS
} from "./types";




// GET IDEAS
export const getIdeas = (project_name = "", p_id = "", color = "", page = 1, is_delete = 0, is_done = 0, keyword = "") => (dispatch, getState) => {


    axios
        .get(ip + `/api/ideas/?project=${p_id}&color=${color}&page=${page}&delete_status=${is_delete}&is_done=${is_done}&keyword=${keyword}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_IDEAS,
                payload: [res.data.results, project_name, p_id, color, page, is_delete, is_done]
            });
        })
        .catch(err => {
                dispatch(returnErrors(err, err));
                // dispatch({
                //     type: LOGOUT_SUCCESS
                // });


            }
        );
};


// export const getIdeas = () => (dispatch, getState) => {
//     axios
//         .get(ip + "/api/ideas/", tokenConfig(getState))
//         .then(res => {
//             dispatch({
//                 type: GET_IDEAS,
//                 payload: res.data.results
//             });
//         })
//         .catch(err =>
//             dispatch(returnErrors(err.response.data, err.response.status))
//         );
// };


// DELETE IDEA
export const deleteIdea = (idea, id) => (dispatch, getState) => {
    axios
        .put(ip + `/api/ideas/${id}/?project=p`, idea, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({deleteIdea: "Idea Deleted"}));
            dispatch({
                type: DELETE_IDEA,
                payload: id
            });
        })
        .catch(err => console.log(err));
};

// ADD IDEA
export const addIdea = idea => (dispatch, getState) => {

    let data = new FormData()

    data.append('text', idea.text)
    // data.append('audio', idea.audio)

    axios
        .post(ip + "/api/ideas/", data, tokenConfig_file(getState))
        .then(res => {
            dispatch(createMessage({addIdea: "Idea Added"}));
            dispatch({
                type: ADD_IDEA,
                payload: res.data.results
            });
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};


// update IDEA
export const updateIdea = (idea, id, color) => (dispatch, getState) => {
    axios
        .put(ip + `/api/ideas/${id}/?project=p`, idea, tokenConfig(getState))
        .then(res => {

            if (color === 'c') {

                dispatch({
                    type: DONE_IDEA,
                    payload: id
                });

            } else {

                dispatch({
                    type: UPDATE_IDEA,
                    payload: [id, color]
                });

            }

            dispatch(createMessage({updateIdea: "Idea Uppdated"}));


        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const doneIdea = (idea, id) => (dispatch, getState) => {
    axios
        .put(ip + `/api/ideas/${id}/?project=p`, idea, tokenConfig(getState))
        .then(res => {
            console.log(idea, id)
            dispatch(createMessage({doneIdea: "Idea Done"}));
            dispatch({
                type: DONE_IDEA,
                payload: id
            });
        })
        .catch(err => {

                console.log("err", err, idea, id)

                // dispatch({
                //     type: DONE_IDEA,
                //     payload: id
                // });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        );
};


export const update_work_type = (type) => (dispatch, getState) => {


    dispatch(createMessage({update_work_type: "UPDATE_WORK_TYPE"}));
    dispatch({
        type: UPDATE_WORK_TYPE,
        payload: type
    });

}

export const update_activate_idea = (id) => (dispatch, getState) => {


    dispatch(createMessage({update_activate_idea: "Idea Activated"}));
    dispatch({
        type: ACTIVATE_IDEA,
        payload: id
    });

}
