import React from "react";
import Navbar from "./Navbar";
import BookList from "./BookList";
import EditBookDialog from "./EditBookDialog";
import DeleteBookDialog from "./DeleteBookDialog";
import UpdateMessage from "./UpdateMessage";
import DBUtil from "../service/DBUtil";
import {connect} from "react-redux";
import {downLoadBook, updateLocalBook} from "../../Ideahub/actions/mark";
import Book from "../model/Book";


class Manager extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      books: [], // 书籍列表
      openEditDialog: false, // 是否打开编辑书籍信息的对话框
      openDeleteDialog: false,
      openUpdate: true, // 是否打开更新消息
      targetBook: {}, // 正在被编辑的书籍对象
      uploadBookStatus: {},
      cloudBook: this.props.cloudBook,
      initialBook: {

        key: "100000000000",
        name: "如何阅读一本书",
        content: ""


      }

    };
    this.handleAddBook = this.handleAddBook.bind(this);
    this.handleDeleteBook = this.handleDeleteBook.bind(this);
    this.handleEditBook = this.handleEditBook.bind(this);
    this.handleUpdateBook = this.handleUpdateBook.bind(this);
    this.handleCloseEditDialog = this.handleCloseEditDialog.bind(this);
    this.handleCloseDeleteDialog = this.handleCloseDeleteDialog.bind(this);
    this.toggleUpdate = this.toggleUpdate.bind(this);
  }

  componentWillMount() {
    let bookDBAccess = new DBUtil("books", "book");
    let bookArr = [];
    bookDBAccess.open(
      () => {
        bookDBAccess.getAll(
          (result) => {
            bookArr = result;


            let keyList = bookArr.map(item => item.key);

            if (keyList.length !== 0) {

              this.setState({books: bookArr});


            } else {


              this.setState({books: [...bookArr, this.state.initialBook]});


            }


          }
        );
      }
    );
  }


  getCloudBooks() {

    console.log("getCloudBooks");
    this.props.downLoadBook("", "");

  }


  addABook(book, index) {

    let bookDBAccess = new DBUtil("books", "book");
    bookDBAccess.open(
      () => {
        bookDBAccess.add(book);
      }
    );

  };


  handleAddBook(book) {


    let bookDBAccess = new DBUtil("books", "book");
    bookDBAccess.open(
      () => {
        bookDBAccess.add(book);
        let bookArr = this.state.books;
        bookArr.push(book);
        this.setState({books: bookArr});
      }
    );


  }


  handleEditBook(book) {
    this.setState({openEditDialog: true, targetBook: book});
  }


  handleDeleteBook(book) {
    this.setState({openDeleteDialog: true, targetBook: book});
  }


  handleUpdateBook(book) {
    let bookDBAccess = new DBUtil("books", "book");
    bookDBAccess.open(
      () => {
        bookDBAccess.update(book);
        let bookArr = this.state.books;
        bookArr.forEach((item, i, arr) => {
          if (item.key === book.key)
            arr[i] = book;
        });
        let keyList = bookArr.map(item => item.key);
        if (keyList && keyList.indexOf("100000000000") === -1) {

          this.setState({books: [...bookArr, this.state.initialBook]});


        } else {

          this.setState({books: bookArr});

        }
        // this.setState({books: bookArr});
      }
    );
  }


  handleDeleteBookConfirm(book) {

    window.This = this;

    let key = book.key;
    // 从数据库中删除此book
    let bookDBAccess = new DBUtil("books", "book");
    bookDBAccess.open(
      () => {
        bookDBAccess.remove(key);
        let bookArr = window.This.state.books;
        bookArr = bookArr.filter((item) => {
          return item.key !== key;
        });
        window.This.setState({books: bookArr});
      }
    );

    // 从数据库中删除本书的note
    let noteDBAccess = new DBUtil("notes", "note");
    noteDBAccess.open(
      () => {
        noteDBAccess.getAll(result => {
          let noteArr;
          noteArr = result.filter(item => item.bookKey === key);
          noteArr.forEach((note) => {
            noteDBAccess.remove(note.key);
          });
        });
      }
    );

    // 从数据库中删除本书的bookmark
    let bmDBAccess = new DBUtil("bookmarks", "bookmark");
    bmDBAccess.open(
      () => {
        bmDBAccess.getAll(result => {
          let bookmarksArr;
          bookmarksArr = result.filter(item => item.bookKey === key);
          bookmarksArr.forEach((bookmark) => {
            bmDBAccess.remove(bookmark.key);
          });
        });
      }
    );
  }


  handleCloseEditDialog() {
    this.setState({openEditDialog: false});
  }


  handleCloseDeleteDialog() {
    this.setState({openDeleteDialog: false});
  }


  toggleUpdate(open) {
    this.setState({openUpdate: open});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    window.This = this;


    // // <editor-fold desc="更新云端书籍显示到本地">
    // if (this.state.books.length !== this.props.cloudBooks.length && this.props.cloudBooks.length !== 0) {
    //
    //     //<editor-fold desc="添加云端同步的书籍到本地">
    //     console.log("ALL", this.props.cloudBooks)
    //     console.log("local index DB", this.state.books)
    //     let localBookKeys = this.state.books.map(item => item.key)
    //     console.log("localBookKeys", localBookKeys)
    //     try {
    //         let needLoadBook = this.props.cloudBooks.filter(function (item, index, array) {
    //             console.log("item need load", item.bookKey)
    //             if (localBookKeys.indexOf(item.bookKey) === -1) {
    //                 //筛选出本地没有的书本
    //                 return true
    //             }
    //         });
    //
    //
    //         needLoadBook.map((item, index) => {
    //
    //             console.log("item loading", item.bookKey)
    //             const [name, author, content, description, cover] = [item.name, "", "", "", ""]
    //             let book = new Book(name, author, content, description, cover)
    //
    //             book.key = item.bookKey
    //             book.id = item.id
    //             book.owner = window.This.props.auth.user.id
    //             window.This.addABook(book, index)
    //             // window.This.handleAddBook(book)
    //
    //         })
    //
    //
    //         setTimeout(() => {
    //
    //             this.props.cloudBooks.length = 0
    //
    //
    //             let bookDBAccess = new DBUtil('books', 'book');
    //             let bookArr = [];
    //             bookDBAccess.open(
    //                 () => {
    //                     bookDBAccess.getAll(
    //                         (result) => {
    //                             bookArr = result;
    //                             this.setState({books: bookArr});
    //                         }
    //                     );
    //                 }
    //             );
    //
    //
    //         }, 2000)
    //
    //
    //     } catch (e) {
    //
    //         console.log("错误详情", e)
    //
    //     }
    //     //</editor-fold>
    //
    //
    // }
    // // </editor-fold>


    //1. 更新云端下载所有书本显示到本地  2.更新单个云端同步书籍内容显示
    if (this.props.localBooks.length >= this.state.books.length && this.props.localBooks.length !== 0) {
      this.setState({books: this.props.localBooks});
      this.props.updateLocalBook([]);  //避免重复更新


    }


  }


  componentDidMount() {

    window.This = this;


  }


  render() {


    let {handleReading, handleReadingBook, theme} = this.props;
    let message = localStorage.getItem("update-message");
    let open = this.state.openUpdate && !!message;
    let classList = document.body.classList;
    let books = this.state.books;

    books.length ? classList.remove("show") : classList.add("show");


    console.log("books........", books);

    return (

      <div id="manager">
        <Navbar handleAddBook={this.handleAddBook} getCloudBooks={this.getCloudBooks.bind(this)}
                auth={this.props.auth}/>
        <BookList books={books}
                  handleDelete={this.handleDeleteBook}
                  handleEdit={this.handleEditBook}
                  handleReading={handleReading}
                  handleReadingBook={handleReadingBook}
                  theme={theme}
        />
        <EditBookDialog open={this.state.openEditDialog}
                        book={this.state.targetBook}
                        handleUpdateBook={this.handleUpdateBook}
                        handleCloseEditDialog={this.handleCloseEditDialog}
        />
        <DeleteBookDialog open={this.state.openDeleteDialog}
                          book={this.state.targetBook}
                          handleDeleteBookConfirm={this.handleDeleteBookConfirm}
                          handleCloseDeleteDialog={this.handleCloseDeleteDialog}
        />
        <UpdateMessage open={open}
                       message={message}
                       toggleUpdate={this.toggleUpdate}
        />

      </div>


    );
  }
}

// export default Manager;

const mapStateToProps = state => ({
  cloudBook: state.books.cloudBook,
  cloudBooks: state.books.cloudBooks,
  localBooks: state.books.localBooks,
  auth: state.auth,
  uploadBookStatus: state.books.uploadBookStatus

});


export default connect(
  mapStateToProps,
  {downLoadBook, updateLocalBook}
)(Manager);

