import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import {GithubCircle} from "mdi-material-ui";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import Badge from "@material-ui/core/Badge";
import {Toolbar} from "@material-ui/core";
import {fade} from "@material-ui/core/styles/colorManipulator";
import {withStyles} from "@material-ui/core/styles";
import Search from "./Search";
import SeachHiden from "./SearchHiden";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import blue from "@material-ui/core/colors/blue";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import EmailIcon from "@material-ui/icons/Email";
import "./Header.css";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import {platform} from "../platform"


const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        // marginLeft: theme.drawer.width,
        width: `calc(100% - ${theme.drawer.width}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(30),
        marginLeft: 0,
        width: "100%",
        minWidth: 50,
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        width: theme.spacing(9),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit",
        width: "100%"
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(10),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 200
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    }
});

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showConnection: false,
            anchorEl: null,
            mobileMoreAnchorEl: null,
            version: "0",
            version2: "0",
            win_link: "",
            mac_link: "",
            upgrade_link: "666"
        };
    }


    popUpConnection = () => {


        this.setState({

            showConnection: true

        });

    };

    handleProfileMenuOpen = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleMenuClose = () => {
        this.setState({anchorEl: null});
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({mobileMoreAnchorEl: event.currentTarget});
    };

    handleMobileMenuClose = () => {
        this.setState({mobileMoreAnchorEl: null});
    };


    componentWillUnmount() {


    }


    componentDidMount() {


        /*** 判断mac系统(含iphone手机) ***/
        window.isMac = /macintosh|mac os x/i.test(navigator.userAgent);

        /*** 判断windows系统 ***/
        window.isWindows = /windows|win32/i.test(navigator.userAgent);


        let version = localStorage.getItem("version");
        let version2 = localStorage.getItem("version2");

        this.setState({

            version: version,
            version2: version2,

        });


    }


    goHome() {

        if (platform === "mac") {

        } else {
            try {
                const shell = window.electron.shell;
                shell.openExternal("http://newonet.com/");
            } catch (e) {
                // window.location.href = "http://newonet.com/";
                window.open("http://newonet.com/")
            }
        }


    }

    pandaSearch() {

        try {
            const shell = window.electron.shell;
            shell.openExternal("https://xmsoushu.com/");
        } catch (e) {
            // window.location.href = "http://newonet.com/";
            window.open("https://xmsoushu.com/")
        }


    }

    open(e) {


        alert("请至闪电读书官网newonet.com或者应用商店下载最新版本")

        // window.This = this;
        // let promise = new Promise(function (resolve, reject) {
        //
        //     if (window.isMac) {
        //
        //
        //         window.This.setState({
        //
        //             upgrade_link: localStorage.getItem("mac_link")
        //
        //         });
        //
        //
        //     } else if (window.isWindows) {
        //
        //         window.This.setState({
        //
        //             upgrade_link: localStorage.getItem("win_link")
        //
        //         });
        //     } else {
        //         window.This.setState({
        //
        //             upgrade_link: localStorage.getItem("mac_link")
        //
        //         })
        //         ;
        //     }
        //
        //     console.log("AAA");
        //     resolve();
        // });
        // promise.then(() => {
        //     console.log("upgrade.............");
        //     e.preventDefault();
        //
        //     try {
        //         console.log("upgrade_link", window.This.state.upgrade_link);
        //
        //         const shell = window.electron.shell;
        //         shell.openExternal(window.This.state.upgrade_link);
        //     } catch (e) {
        //     }
        //
        //
        // });


    }

    render() {

        const {
            handleChangeNavDrawer,
            classes,
            navDrawerOpen,
            handleChangeRightDrawer,
            handleChangeRightDrawerContact,
            rightDrawerOpen
        } = this.props;

        const {anchorEl} = this.state;
        const isMenuOpen = Boolean(anchorEl);

        const searchBar = (

            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon style={{color: "white", opacity: .6}}/>
                </div>
                <Search/>
            </div>);


        return (
            <div>
                <AppBar
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: navDrawerOpen
                    })}
                >
                    <Toolbar>
                        <IconButton title="切换侧边栏模式"
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="Open drawer"
                                    onClick={handleChangeNavDrawer}
                        >
                            <MenuIcon style={{color: "white", opacity: .6}}/>
                        </IconButton>

                        <IconButton title="访问首页"
                                    aria-owns={isMenuOpen ? "material-appbar" : null}
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={this.goHome.bind(this)}
                        >
                            <HomeIcon style={{color: "white", opacity: .6}}/>
                        </IconButton>

                        {/*<IconButton title="熊猫搜书"*/}
                        {/*            aria-owns={isMenuOpen ? "material-appbar" : null}*/}
                        {/*            aria-haspopup="true"*/}
                        {/*            color="inherit"*/}
                        {/*            onClick={this.pandaSearch.bind(this)}*/}
                        {/*>*/}
                        {/*  <SearchIcon style={{color: "white", opacity: .6}}/>*/}
                        {/*</IconButton>*/}


                        <h1 className={"header_title"}>闪电读书</h1>


                        <div className={classes.grow}/>
                        {/*{searchBar}*/}
                        {/*<p>搜索结果开关</p>*/}
                        <SeachHiden/>


                        <Link to="/apps/books">

                            <IconButton title="打开闪电阅读"
                                        aria-owns={isMenuOpen ? "material-appbar" : null}
                                        aria-haspopup="true"
                                        color="inherit"
                            >
                                <MenuBookIcon style={{color: "white", opacity: .6}}/>
                            </IconButton>


                        </Link>
                        {platform === "mac" ? null : <Link to="/apps/ideas">

                            <IconButton title="打开闪电思考"
                                        aria-owns={isMenuOpen ? "material-appbar" : null}
                                        aria-haspopup="true"
                                        color="inherit"
                            >
                                <BubbleChartIcon style={{color: "white", opacity: .6}}/>
                            </IconButton>


                        </Link>}


                        {parseFloat(this.state.version2) > parseFloat(this.state.version) ? <IconButton title="版本升级"
                                                                                                        aria-owns={isMenuOpen ? "material-appbar" : null}
                                                                                                        aria-haspopup="true"
                                                                                                        color="inherit"
                                                                                                        onClick={this.open.bind(this)}
                        >

                            <Badge
                                className={classes.margin}
                                badgeContent={1}
                                color="secondary"
                            >
                                <SystemUpdateAltIcon style={{color: "white", opacity: .6}}/>

                            </Badge>
                        </IconButton> : ""}


                        <div className={classes.sectionDesktop}>

                            <Link to="/apps/account">
                                <IconButton title="打开个人中心"
                                            aria-owns={isMenuOpen ? "material-appbar" : null}
                                            aria-haspopup="true"
                                            onClick={this.handleProfileMenuOpen}
                                            color="inherit"
                                >
                                    <AccountCircle style={{color: "white", opacity: .6}}/>
                                </IconButton>

                            </Link>
                            <IconButton title="联系方式"
                                        aria-owns={isMenuOpen ? "material-appbar" : null}
                                        aria-haspopup="true"
                                        onClick={handleChangeRightDrawerContact}
                                        color="inherit"
                            >
                                <EmailIcon style={{color: "white", opacity: .6}}/>
                            </IconButton>
                            <IconButton title="设置主题"
                                        aria-owns={isMenuOpen ? "material-appbar" : null}
                                        aria-haspopup="true"
                                        onClick={handleChangeRightDrawer}
                                        color="inherit"
                            >
                                <SettingsIcon style={{color: "white", opacity: .6}}/>
                            </IconButton>

                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-haspopup="true"
                                onClick={this.handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

Header.propTypes = {
    styles: PropTypes.object,
    handleChangeNavDrawer: PropTypes.func,
    classes: PropTypes.object,
    navDrawerOpen: PropTypes.bool,
    handleChangeRightDrawer: PropTypes.func,
    rightDrawerOpen: PropTypes.bool,
};

export default withStyles(styles)(Header);
