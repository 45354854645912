import axios from "axios";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import React, {Component} from "react";
import {getBookTypes} from "../pages/Ideahub/actions/projects";
import {downLoadBook, updateBook, updateLocalBook} from "../pages/Ideahub/actions/mark";
import {getCredential} from "../pages/Ideahub/actions/auth";
import {updateUser} from "../pages/Ideahub/actions/auth";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {grey} from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import AddBookType from "./AddBookType";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import GetAppIcon from "@material-ui/icons/GetApp";
import {getCoverURL} from "../pages/Qreader/service/bookUtil";
import newBook from "../pages/Qreader/model/newBook";
import DBUtil from "../pages/Qreader/service/DBUtil";
import {ip} from "../pages/Ideahub/actions/env";
import StatusDialog from "../pages/Qreader/components/StatusDialog";


const styles = theme => {
    return {

        cloudBook_dialog: {
            margin: "0 auto",
            width: "90%",
            height: "70%",
            backgroundColor: "#567890"

        },

        large: {
            width: 60,
            height: 60,
        },
        toggleDiv: {
            marginTop: 20,
            marginBottom: 5
        },
        toggleLabel: {
            color: grey[400],
            fontWeight: 100
        },
        button: {},
        buttons: {
            marginTop: 30,
            float: "right"
        },
        saveButton: {
            marginLeft: 15
        },
        typeOptions: {

            width: 180

        },


        InputLabel: {

            textAlign: "center"

        },

        bookName: {

            width: 500

        }

    };
};


export class EditCloudBook extends Component {


    state = {


        config: {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${localStorage.getItem("token")}`
            }
        },
        name: "",
        description: "",
        goldPoints: 0,
        isParent: false,
        Parent: "",
        addBookOpen: false,
        bookType: "",
        onType: "",
        reClassifiedBook: {
            id: -1
        },
        sts: {},
        progress: 0,
        showStatus: false,
        bucket: "ideahub", // bucket名称
        region: "oss-cn-beijing", // oss服务区域名称


    };

    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired
    };


    getCredential(book) {

        window.This = this;

        axios
            .get(ip + "/api/auth/credential", this.state.config)
            .then(res => {
                const {AccessKeyId, AccessKeySecret, SecurityToken} = res.data.cred;
                window.This.ossClient = new window.OSS({
                    accessKeyId: AccessKeyId,
                    accessKeySecret: AccessKeySecret,
                    stsToken: SecurityToken,
                    bucket: window.This.state.bucket,
                    region: window.This.state.region
                });
                const url = window.This.ossClient.signatureUrl(`books/${window.This.props.auth.user.username}/${book.bookKey}_=_${book.name}.epub`, {
                    expires: 3600,
                    method: "GET"
                });
                window.This.getBlob(url).then(blob => {
                    window.This.handleDownload(blob, book);
                });


            });
    }


    initOSSClient = async function () {
        window.This = this;
        const {AccessKeyId, AccessKeySecret, SecurityToken} = window.This.state.sts;
        console.log("window.This.state.sts", window.This.state.sts);
        window.This.ossClient = new window.OSS({
            accessKeyId: AccessKeyId,
            accessKeySecret: AccessKeySecret,
            stsToken: SecurityToken,
            bucket: window.This.state.bucket,
            region: window.This.state.region
        });

    };

    handleUpdateBook(book) {


        let bookDBAccess = new DBUtil("books", "book");
        bookDBAccess.open(
            () => {
                bookDBAccess.update(book);

                setTimeout(() => {

                    window.This.setState({
                        uploadDialogOpen: false
                    });


                }, 1);


            }
        );
    }


    handleStatusDialogOpen() {

        this.setState({

            showStatus: !this.state.showStatus

        });

    }


    handleDownload(blob, downLoadBook) {
        window.This = this;
        let file = blob;
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = e => {


            try {
                const epub = window.ePub({bookPath: e.target.result});
                epub.getMetadata().then((metadata) => {
                    let name, author, content, description, cover;
                    [name, author, content, description] = [metadata.bookTitle, metadata.creator, metadata.description, e.target.result];

                    getCoverURL(epub, cover => {


                        cover = cover;
                        let book = new newBook(name, author, content, description, cover);


                        book.key = downLoadBook.bookKey;
                        book.id = downLoadBook.id;
                        book.owner = window.This.props.auth.user.id;

                        let updateBook = book;

                        // alert("123456")
                        // alert(book.key)

                        this.handleUpdateBook(updateBook);
                        let bookDBAccess = new DBUtil("books", "book");
                        let bookArr = [];
                        bookDBAccess.open(
                            () => {
                                bookDBAccess.getAll(
                                    (result) => {
                                        bookArr = result;
                                        this.props.updateLocalBook(bookArr);
                                        this.setState({
                                            books: bookArr,
                                            showStatus: false
                                        });
                                    }
                                );
                            }
                        );


                    });

                });
            } catch (err) {
                alert("仅支持epub3.0格式书籍哦");
            }

            this.setState({
                showStatus: false
            });

        };
        reader.onerror = () => {
            alert("Σ(っ °Д °;)っ Some error occurred, please try again!");
        };
    }


    getBlob = (url) => {
        return new Promise(resolve => {
            const xhr = new XMLHttpRequest();

            xhr.open("GET", url, true);
            xhr.responseType = "blob";
            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                }
            };

            //监听进度事件
            xhr.addEventListener("progress", function (evt) {
                if (evt.lengthComputable) {
                    var percentComplete = evt.loaded / evt.total;
                    window.This.setState({
                        progress: "已完成" + parseFloat(percentComplete * 100).toFixed(2) + "%"

                    });

                }
            }, false);


            xhr.send();
        });
    };


    // 普通下载 ossClient
    commonDownload2 = function (book) {

        window.This = this;
        if (this.props.auth.user.goldPoints > 0) {
            new Promise(function (resolve, reject) {

                setTimeout(() => {
                    window.This.commonDownload(book);
                    resolve(1);

                }, 100); // (*)


            }).then(function (result) { // (**)

                let goldPoints = "- 1";
                window.This.props.updateUser(goldPoints);

                // alert(result); // 1
                // return result * 2;

            });


            // this.commonDownload(book)
            //
            //
            // //<editor-fold desc="扣点数">
            // let goldPoints = "- 1"
            //
            // // this.props.updateUser(goldPoints)
            //
            // this.setState({
            //
            //     goldPoints: this.state.goldPoints - 1
            //
            // })
            // //</editor-fold>


        } else {

            alert("点数不足，请在微信公众号：Lightning小助手中使用分享计划。");
        }


    };


    // 普通下载 ossClient
    commonDownload = async function (book) {


        if (this.props.auth.isAuthenticated) {


            let memberExpireDate = this.props.auth.user.memberExpireDate;

            let expireDate = memberExpireDate.substring(0, 10);
            expireDate = expireDate.replace(/\-/g, "/");
            expireDate = Date.parse(expireDate);
            if (expireDate >= Date.parse(new Date())) {


                //<editor-fold desc="commonDownload">
                this.setState({

                    showStatus: true

                });

                await this.getCredential(book);
                //</editor-fold>


            } else {

                alert("高级会员功能已过期，请续期");
                window.location.href = "#/apps/account";
            }


        } else {
            alert("请先登录");
            window.location.href = "#/apps/logout";

        }


    };


    handleClose() {
        this.props.toggleOpen();
    }


    toggleAddTypeOpen() {


        this.setState({

            addBookOpen: !this.state.addBookOpen


        });

    }

    onChange = e => {


        console.log(e.target.name, e.target.value);
        this.setState({
            [e.target.name]: e.target.value,
            onType: e.target.value,


        });
        this.props.downLoadBook("", e.target.value);

    };


    onSubmit() {
        const {name, description, isParent, Parent} = this.state;
        const project = {name, description, isParent, Parent};

        console.log("add project....................");
        console.log(project);

        this.props.addProject(project);
        this.setState({
            name: "",
            description: "",
            isParent: false,
            Parent: ""
        });
        this.props.toggleAddProject();

    }
    ;

    selectType = (e) => {

        let needUpdateBook = e.target.value[1];
        needUpdateBook.bookType = e.target.value[0];

        this.setState({

            reClassifiedBook: needUpdateBook

        });


        this.props.updateBook(needUpdateBook.id, needUpdateBook);

    };


    componentDidMount() {


        window.This = this;

        if (this.props.auth.isAuthenticated) {
            if (this.state.goldPoints !== this.props.auth.goldPoints) {
                this.setState({
                    goldPoints: this.props.auth.user.goldPoints

                });

            }


        }


        // this.props.downLoadBook("")
        this.props.getBookTypes();

    }

    render() {
        let {classes} = this.props;

        let types = this.props.bookTypes.length > 0 ? this.props.bookTypes.map(item => {

            if (item.delete_status) {

            } else {
                return [item.name, item.id];

            }


        }) : [];


        let books = this.props.cloudBooks.length > 0 ? this.props.cloudBooks.map(item => {


            return item;


        }) : [];


        return (
            <div className={classes.cloudBook_dialog}>
                <Dialog open={this.props.open} onClose={this.handleClose.bind(this)}>
                    <DialogTitle>云端图书 </DialogTitle>
                    <DialogContent>
                        <form>

                            <FormControl fullWidth={true}>
                                <InputLabel

                                    htmlFor="Father">选择图书类别</InputLabel>
                                <Select
                                    name="bookType"
                                    fullWidth={true}
                                    onChange={this.onChange.bind(this)}
                                    defaultValue={"0"}
                                >

                                    <MenuItem onClick={this.onChange.bind(this)} value={"0"}>默认分类</MenuItem>
                                    {

                                        types.map((item, index) => {
                                            if (item) {
                                                return (
                                                    <MenuItem key={index}
                                                              value={item ? item[1] : ""}>{item ? item[0] : ""}</MenuItem>
                                                );
                                            }
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Divider/>


                        </form>


                        {books.map((book, index) => (

                                <ListItem key={book.id}>


                                    <ListItemText className={classes.bookName} primary={book.name.substr(0, 36)}/>


                                    <FormControl className={classes.typeOptions}>
                                        <InputLabel className={classes.InputLabel}> 移动 </InputLabel>
                                        <Select
                                            name="bookType"
                                            fullWidth={true}
                                            onChange={this.selectType.bind(this)}
                                        >
                                            {

                                                types.map((item, index) => {
                                                    if (item && item[1] !== this.state.onType) {
                                                        return (
                                                            <MenuItem key={index}
                                                                      value={item ? [item[1], book] : ["", book]}>{item ? item[0] : ""}</MenuItem>
                                                        );
                                                    }
                                                })
                                            }
                                        </Select>
                                    </FormControl>


                                    <Button component="span" aria-label="add" color="primary">
                                        <GetAppIcon onClick={this.commonDownload.bind(this, book)}/>
                                    </Button>


                                </ListItem>


                            )
                        )}

                    </DialogContent>
                    <DialogActions color="primary">
                        <div className={classes.buttons}>
                            <Button onClick={this.toggleAddTypeOpen.bind(this)} variant="contained">新建书单</Button>
                            <Button
                                className={classes.saveButton}
                                variant="contained"
                                type="submit"
                                color="primary"
                                onClick={this.handleClose.bind(this)}


                            >
                                关闭
                            </Button>

                        </div>


                    </DialogActions>
                </Dialog>
                <AddBookType open={this.state.addBookOpen}
                             toggleAddTypeOpen={this.toggleAddTypeOpen.bind(this)}/>
                <StatusDialog open={this.state.showStatus}
                              handleStatusDialogOpen={this.handleStatusDialogOpen.bind(this)}
                              progress={this.state.progress}
                />
            </div>
        );
    }
}


EditCloudBook.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    auth: state.auth,
    bookTypes: state.projects.bookTypes,
    cloudBooks: state.books.cloudBooks,
    sts: state.auth.sts
});

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, {
    getBookTypes,
    downLoadBook,
    updateBook,
    updateLocalBook,
    getCredential,
    updateUser
})(EditCloudBook));



