import {
    GET_BOOKS,
    DOWNLOAD_BOOK,
    UPDATE_LOCAL_BOOK,
    UPLOAD_BOOK,
    UPLOAD_BOOK_STATUS,
    DOWNLOAD_NOTE_FAIL,
    RECLASSIFY_BOOK
} from "../actions/types.js";
import {DOWNLOAD_NOTE, UPDATE_NOTES_STATUS} from "../actions/types"

const initialState = {
    books: [],
    localBooks: [],
    searchTime: "",
    book_name: "",
    pn: "",
    total: "",
    cloudBook: null,
    cloudBooks: [],
    thisBookNotes: [],
    downloadNotesStatus: "",
    uploadBookStatus: ""
};


export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BOOKS:
            let searchTimeStamp = new Date().getTime()
            return {
                ...state,
                books: action.payload[0],
                book_name: action.payload[1],
                pn: action.payload[2],
                total: action.payload[3],
                searchTime: searchTimeStamp.toString()
            };
        case DOWNLOAD_BOOK:


            return {
                ...state,
                cloudBooks: action.payload,
            };
        case UPLOAD_BOOK:
            return {
                ...state,
                cloudBook: action.payload,
                uploadBookStatus: "sucess"

            };
        case RECLASSIFY_BOOK:
            return {
                ...state,
                cloudBooks: state.cloudBooks.filter(book => book.id !== action.payload.id)

            };
        case UPDATE_LOCAL_BOOK:

            return {
                ...state,
                localBooks: action.payload,

            };
        case DOWNLOAD_NOTE:


            return {
                ...state,
                thisBookNotes: action.payload,
                downloadNotesStatus: "sucess"

            };
        case DOWNLOAD_NOTE_FAIL:

            return {
                ...state,
                downloadNotesStatus: "fail"

            };

        case UPDATE_NOTES_STATUS:

            return {

                ...state,
                downloadNotesStatus: action.payload
            }

        case UPLOAD_BOOK_STATUS:


            return {
                ...state,
                uploadBookStatus: action.payload,

            };
        default:
            return state;
    }
}
