import axios from "axios";
import {createMessage, returnErrors} from "./messages";
import {tokenConfig, tokenConfig_file} from "./auth";
import {ip} from "./env"
import {

    UPLOAD_BOOK,
    DOWNLOAD_BOOK,
    UPDATE_LOCAL_BOOK,
    UPDATE_BOOK,
    UPLOAD_NOTE,
    DOWNLOAD_NOTE,
    UPLOAD_BOOK_STATUS,
    DOWNLOAD_NOTE_FAIL,
    UPDATE_NOTES_STATUS,
    RECLASSIFY_BOOK
} from "./types";


// UPLOAD_BOOK
export const uploadBook = book => (dispatch, getState) => {

    let data = new FormData()

    data.append('name', book.name)
    data.append('bookKey', book.bookKey)

    axios
        .post(ip + "/api/books/", data, tokenConfig_file(getState))
        .then(res => {
            dispatch(createMessage({uploadBook: "uploadBook"}));
            dispatch({
                type: UPLOAD_BOOK,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};


// UPDATE_BOOK
export const updateBook = (id, book) => (dispatch, getState) => {

    let data = new FormData()

    data.append('name', book.name)
    data.append('bookKey', book.bookKey)
    data.append('bookType', book.bookType)

    axios
        .put(ip + `/api/books/${id}/`, data, tokenConfig_file(getState))
        .then(res => {
            dispatch(createMessage({updateBook: "updateBook"}));
            dispatch({
                type: RECLASSIFY_BOOK,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};


// DOWNLOAD_NOTE
export const downloadNote = (bookKey = "") => (dispatch, getState) => {


    axios
        .get(ip + `/api/booknotes/?bookKey=${bookKey}`, tokenConfig_file(getState))
        .then(res => {
            dispatch(createMessage({UPLOAD_Note: "UPLOAD_Note"}));
            dispatch({
                type: DOWNLOAD_NOTE,
                payload: res.data
            });
        })
        .catch(err => {

                dispatch({
                    type: DOWNLOAD_NOTE_FAIL,
                    payload: err
                });
                // dispatch(returnErrors(err, err))

            }
        );
};


// UPLOAD_Note
export const uploadNote = (note, owner) => (dispatch, getState) => {


    let data = new FormData()

    data.append('key', note.key)
    data.append('bookKey', note.bookKey)
    data.append('text', note.text)
    data.append('Book', parseInt(note.book))
    data.append('chapter', note.chapter)
    data.append('cfi', note.cfi)
    data.append('rangeHighlight', note.range)
    data.append('color', note.color)
    data.append('note', note.note)   //
    data.append('delete_status', note.delete_status)
    data.append('owner', parseInt(owner))

    axios
        .post(ip + "/api/booknotes/", data, tokenConfig_file(getState))
        .then(res => {
            dispatch(createMessage({UPLOAD_Note: "UPLOAD_Note"}));
            dispatch({
                type: UPLOAD_NOTE,
                payload: res.data.results
            });
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};


// UPDATE_Note
export const updateNotetoCLoud = (id, note) => (dispatch, getState) => {


    let data = new FormData()

    data.append('key', note.key)
    data.append('bookKey', note.bookKey)
    data.append('text', note.text)
    data.append('Book', parseInt(note.book))
    data.append('chapter', note.chapter)
    data.append('cfi', note.cfi)
    data.append('rangeHighlight', note.range)
    data.append('color', note.color)
    data.append('note', note.note)   //
    data.append('delete_status', note.delete_status)

    axios
        .put(ip + `/api/booknotes/${id}/`, data, tokenConfig_file(getState))
        .then(res => {
            dispatch(createMessage({UPLOAD_Note: "UPLOAD_Note"}));
            dispatch({
                type: UPLOAD_NOTE,
                payload: res.data.results
            });
        })
        .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};


export const downLoadBook = (bookKey = "", bookType = "") => (dispatch, getState) => {
    axios
        .get(ip + `/api/books/?bookKey=${bookKey}&bookType=${bookType}`, tokenConfig(getState))
        .then(res => {

            dispatch(createMessage({downLoadBook: "downLoadBook"}));
            dispatch({
                type: DOWNLOAD_BOOK,
                payload: res.data
            });
        })
        .catch(err => console.log(err));
};


//临时储存本地所有书籍信息
export const updateLocalBook = (books) => (dispatch, getState) => {
    dispatch(createMessage({updateLocalBook: "updateLocalBook"}));
    dispatch({
        type: UPDATE_LOCAL_BOOK,
        payload: books
    });
};


export const updateUploadBookStatus = (status) => (dispatch, getState) => {
    dispatch(createMessage({updateUploadBookStatus: "updateUploadBookStatus"}));
    dispatch({
        type: UPLOAD_BOOK_STATUS,
        payload: status
    });
};


export const updateDownloadNotesStatus = (status = "downloading") => (dispatch, getState) => {
    dispatch(createMessage({updateUploadBookStatus: "updateUploadBookStatus"}));
    dispatch({
        type: UPDATE_NOTES_STATUS,
        payload: status
    });
};
