import React from 'react';
import Book from './Book';

let style = {
    marginTop: 65,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0
};

class BookList extends React.Component {


    getChildrenMsg = (result, msg) => {
        // console.log(result, msg)
        // 很奇怪这里的result就是子组件那bind的第一个参数this，msg是第二个参数
        this.setState({
            childrenMsg: msg
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {



    }

    render() {
        const bookItems = this.props.books.map(
            (item) => <Book key={item.key}
                            book={item}
                            handleEdit={this.props.handleEdit}
                            handleDelete={this.props.handleDelete}
                            handleReading={this.props.handleReading}
                            handleReadingBook={this.props.handleReadingBook}
                            theme={this.props.theme}/>
        );

        return (
            <div id="book-list" style={style}>
                {bookItems}
            </div>
        );
    }
}

export default BookList;
